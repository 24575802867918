import { Select, SelectProps } from 'antd'
import { AxiosResponse } from 'axios'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { normalizeString } from 'src/Util/string'
import EmptyData from '../EmptyData'
import useDebounce from 'src/hooks/helpers/useDebounce'

type OptionType = {
  value: string
  label: ReactNode | string
  textLabel?: string // văn bản để search dùng trong trường hợp label là ReactNode
}

export interface SelectCommonProps extends Omit<SelectProps, 'options'> {
  fetchApi?: (query: string, filter?: Object | undefined) => Promise<AxiosResponse>
  filter?: { [key in string]: string | null }
  options?: OptionType[]
  mapLabel?: (item: any) => string | ReactNode
  onOptionChanged?: (data: any[]) => void
}

let lastValue = ''

export const SelectCommon: FC<SelectCommonProps> = ({
  options,
  fetchApi,
  filter,
  mapLabel,
  onOptionChanged,
  ...props
}) => {
  const { fieldNames } = props
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleSearch({ queryKey: [query] }: { queryKey: string[] }) {
    if (!fetchApi || query.trim().length < 3) {
      setLoading(false)
      return []
    }

    lastValue = query.trim()
    const response = await fetchApi(query.trim(), filter)
    setLoading(false)
    if (lastValue !== query.trim()) {
      return []
    }

    if (mapLabel) {
      return response.data?.map((item: any) => ({
        ...item,
        [fieldNames?.label as string]: mapLabel(item)
      }))
    }

    return response.data
  }

  const debouncedValue = useDebounce(searchValue, 500)

  const { data, isLoading, refetch } = useQuery([debouncedValue], handleSearch, {
    refetchOnWindowFocus: false,
    keepPreviousData: false
  })

  useEffect(() => {
    if (props.value) {
      setSearchValue(props.value)
    }
  }, [props.value])

  useEffect(() => {
    onOptionChanged?.(options ?? data)
  }, [data, onOptionChanged, options])

  const filterOptions = (input: string, option: OptionType) => {
    if (!option) return false
    let label = ''
    if (option.textLabel) {
      label = option?.textLabel
    } else {
      label = fieldNames?.label ? (option as any)[fieldNames.label] : option.label
    }
    return normalizeString(label).includes(normalizeString(input))
  }

  const getEmptyText = () => {
    if (loading || isLoading) return 'Đang tìm kiếm'

    if (fetchApi && searchValue.length > 3 && data.length < 1) {
      return 'Không có dữ liệu'
    }

    return 'Nhập tối thiểu 3 ký tự để tìm kiếm'
  }

  return (
    <Select
      size="large"
      allowClear
      onSearch={(value) => {
        setLoading(true)
        setSearchValue(value)
      }}
      options={options ?? data}
      loading={isLoading}
      notFoundContent={fetchApi && <EmptyData>{getEmptyText()}</EmptyData>}
      suffixIcon={<i className="fa-solid fa-angle-down" />}
      filterOption={!fetchApi && (filterOptions as any)}
      onBlur={() => refetch()}
      {...props}
    />
  )
}
