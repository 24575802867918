import { PlusOutlined } from '@ant-design/icons'
import { UploadProps, UploadFile, Upload } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import { useState, FC } from 'react'

import './style.scss'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'

export interface UploadInputProps extends UploadProps {
  onUploadError?: () => void
  onUploadSuccess?: () => void
}

export const UploadInput: FC<UploadInputProps> = ({ fileList, onChange, onUploadError, onUploadSuccess, ...props }) => {
  const [fileListCustom, setFileListCustom] = useState<UploadFile[]>([])

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 10

    if (!isJpgOrPng) {
      NotificationCommon.error({ message: 'Định dạng file không hỗ trợ, vui lòng thử lại!' })
      onUploadError?.()
    }

    if (!isLt2M) {
      onUploadError?.()
      NotificationCommon.error({ message: 'Dung lượng file không được phép vượt quá 10MB!' })
    }
    return isJpgOrPng && isLt2M
  }

  const handleChange: UploadProps['onChange'] = ({ file, fileList: newFileList }: UploadChangeParam<UploadFile>) => {
    if (file.status === 'uploading' || file.status === 'done') {
      setFileListCustom(newFileList)
      onChange?.({ file, fileList: newFileList })
    } else {
      console.log('chạy vào đây')
      setFileListCustom([])
      onChange?.({ file, fileList: [] })
    }

    if (file.status === 'done') onUploadSuccess?.()
  }

  return (
    <>
      <Upload
        fileList={fileList && fileList?.length > 0 ? fileList : fileListCustom}
        name="file"
        listType="picture-card"
        accept={'image/png, image/gif, image/jpeg, image/jpg'}
        className="avatar-uploader"
        action={`${process.env.REACT_APP_API_CLIENT_URL}/storages/upload`}
        maxCount={1}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        progress={{ strokeWidth: 3, strokeColor: { '0%': '#1890ff', '100%': '#1890ff' } }}
        {...props}>
        {(fileList ?? fileListCustom)?.length < 1 && (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Tải lên</div>
          </div>
        )}
      </Upload>
    </>
  )
}
