import { Input, InputRef, type InputProps } from 'antd'
import { FC, RefObject, useEffect, useRef, useState } from 'react'

export interface InputNumberCommonProps extends InputProps {
  decimalLength?: number // truyền 0 tức là ko cho nhập số thập phân, ko truyền gì là ko giới hạn số thập phân
  isAllowNagative?: boolean
  isAllowFormat?: boolean
}

export const InputNumberCommon: FC<InputNumberCommonProps> = ({
  decimalLength = 999,
  isAllowFormat = true,
  value,
  onChange,
  isAllowNagative = false,
  autoFocus,
  ...props
}) => {
  const [number, setNumber] = useState('')
  const inputRef: RefObject<InputRef> | undefined = useRef() as RefObject<InputRef>

  const parseNumber = (value: string) => {
    let valuePassed = value
      .replace(/\./g, '')
      .replace(/,/g, '.')
      .replace(/(\..*)\./g, '$1')

    if (!isAllowFormat) return valuePassed.replaceAll('.', '.')

    if (decimalLength && decimalLength > 0) {
      const parts = valuePassed.split('.')

      if (parts[1]?.length > decimalLength) {
        parts[1] = parts[1].substring(0, decimalLength)
      }

      return parts.join('.')
    }

    return valuePassed.split('.')[0]
  }

  function formatNumber(value: string) {
    if (!isAllowFormat) return value
    const regex = isAllowNagative ? /[^-?\d.]+/g : /[^\d.-]+/g

    const parssedValue = value.replace(regex, '')
    if (value === '.') {
      return '0'
    }

    const parts: string[] = decimalLength ? parssedValue.toString().split('.') : [parssedValue.replaceAll('.', '')]

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    if (decimalLength && parts[1]?.length) {
      parts[1] = parts[1]?.length > decimalLength ? parts[1].substring(0, decimalLength) : parts[1]
    }
    return parts.join(',')
  }

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = e.target.value
    if (newNumber.length < 1) {
      setNumber(newNumber)
      onChange?.(e)
      return
    }

    if (isAllowNagative && newNumber.length === 1 && newNumber.at(0) === '-') {
      setNumber(newNumber)
      onChange?.(e)
      return
    }

    var regex = isAllowNagative ? /^-?\d+$/ : /^\d+$/

    let numberPart = String(newNumber)
    const isNumberOnly = regex.test(numberPart.replaceAll(',', '').replaceAll('.', ''))
    if (!isNumberOnly) {
      return
    }

    e.target.value = parseNumber(newNumber)
    setNumber(newNumber)
    onChange?.(e)
  }

  useEffect(() => {
    if (!autoFocus) return

    setTimeout(() => {
      inputRef?.current?.focus()
    }, 200)
  }, [autoFocus])

  const v = value || number

  return (
    <Input
      ref={inputRef}
      placeholder=""
      size="large"
      value={v ? formatNumber(String(v)) : ''}
      onChange={onNumberChange}
      {...props}
    />
  )
}
