import { FC, ReactNode } from 'react'
import { ITransaction } from '../type'
import {
  renderAmount,
  renderBalanceAfter,
  renderCode,
  renderMemo,
  renderNote,
  renderTimestamp,
  renderTypeName,
  renderUsername
} from '../helpers/data-render'
import { TransactionMappingType } from 'src/hooks/fetch/useTransactionType'

interface MobileRowProps {
  transaction: ITransaction
  transactionTypeMappingToName: TransactionMappingType
}

export const MobileRow: FC<MobileRowProps> = ({ transaction, transactionTypeMappingToName }) => {
  return (
    <div>
      <div className="flex gap-2 justify-between">
        {renderCode(transaction.ledgeTx)}
        <span className="whitespace-nowrap">({renderTypeName(transaction.type, transactionTypeMappingToName)})</span>
      </div>

      <RowItem label="Người thực hiện">{transaction.teller}</RowItem>

      <RowItem label="Khách hàng">
        {transaction.ownerName} ({renderUsername(transaction.owner)})
      </RowItem>

      <RowItem label="Số tiền">{renderAmount(transaction.amount, transaction)}</RowItem>

      <RowItem label="Khách hàng">{renderBalanceAfter(transaction.balanceAfter, transaction)}</RowItem>

      <RowItem label="Nội dung">{renderMemo(transaction.memo)}</RowItem>

      <RowItem label="Ghi chú">{renderNote(transaction.note)}</RowItem>

      <RowItem label="Nguồn tạo">{transaction.source}</RowItem>

      <RowItem label="Ngày tạo">{renderTimestamp(transaction.actualTimestamp)}</RowItem>
    </div>
  )
}

const RowItem = ({ children, label }: { children: ReactNode; label?: string }) => (
  <div className="flex gap-12 justify-between mt-2">
    {label && <span className="text-secondary2 whitespace-nowrap">{label}:</span>}
    <div className="text-right">{children}</div>
  </div>
)
