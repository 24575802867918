import { List, Skeleton } from 'antd'
import { FC } from 'react'
import { LogItem } from './LogItem'
import { LogInterface, LogViewProps } from './type'
import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { ModalCommon } from 'src/Component/basic/ModalCommon'

const limit = 24

const LogView: FC<LogViewProps> = ({
  code,
  onCancel,
  fetchLogs,
  modalView = true,
  modalWidth = 700,
  title,
  ListItemContent,
  isShowResourceCodeOnTitle = true
}: LogViewProps) => {
  const getData = async (code: string, offset: number) => {
    if (!code || !fetchLogs) {
      return []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: AxiosResponse<any> = await fetchLogs(code, { offset, limit })
    // setIsGotAll(res.data?.length === 0)
    return res.data
  }

  const { data, isLoading } = useQuery<LogInterface[]>(
    [code],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      return getData(data.queryKey[0], data.queryKey[1])
    },
    {
      onError: (error) => {
        NotificationCommon.notifyHttpError(error as AxiosError)
      },
      retry: false
    }
  )
  const dataSource = data ?? []

  const renderListLog = () => (
    <Skeleton loading={isLoading} title={true} paragraph={{ rows: 13 }} active>
      <List
        dataSource={dataSource}
        // locale={{ emptyText: <EmptyDataFilter description={trans('shipment.logEmpty')} /> }}
        renderItem={(item: LogInterface, index: number) => (
          <LogItem item={item} index={index} ListItemContent={ListItemContent} code={code} />
        )}
      />
    </Skeleton>
  )

  if (!modalView) {
    return <div className="log-modal">{renderListLog()}</div>
  }

  return (
    <ModalCommon
      destroyOnClose
      open={!!code}
      title={
        isShowResourceCodeOnTitle ? (
          <>
            {title} <span className="normal-case">{code}</span>
          </>
        ) : (
          title
        )
      }
      onCancel={onCancel}
      footer={false}
      width={modalWidth}>
      {renderListLog()}
    </ModalCommon>
  )
}

export { LogView }
