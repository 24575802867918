import { GetComponentProps } from 'rc-table/lib/interface'
import React from 'react'
import { ServiceInterface } from '../../../Interface/ShipmentInterface'
import { trans } from '../../../resources/localization/Localization'
import { useNavigate } from 'react-router-dom'
// import Services from '../../../components/Services'

interface RowExtendedProps extends GetComponentProps<any> {
  services: any
  index: number
  className: string
  rowClassesByIdx: string
  children: any
  code: string
}

const RowExtended: React.FC<RowExtendedProps> = ({ services = [], ...record }) => {
  const navigate = useNavigate()
  return (
    <>
      <tr
        onClick={() => navigate(`/shipments/${record.code}`)}
        className={`${record.rowClassesByIdx}  data-row cursor-pointer ant-table-row row-items`}>
        {record.children}
      </tr>

      {services?.length > 0 && (
        <tr
          onClick={() => navigate(`/shipments/${record.code}`)}
          className={`${record.rowClassesByIdx}  data-row cursor-pointer ant-table-row data-row_extended row-services`}>
          <td colSpan={6} className="row-extended">
            <div className="flex items-center">
              <span className={'font-size-14 txt-color-view mg-r-4 whitespace text-500'}>
                {trans('shipments.services')}:
              </span>

              {services.map((item: ServiceInterface, idx: number) => {
                let colorService = ''
                if (item.needApprove) {
                  if (item.approved === null || item.approved === undefined) {
                    colorService = 'services-pending'
                  } else if (item.approved === false) {
                    colorService = 'services-reject'
                  }
                }
                return (
                  <span className="items-center font-size-14 text-500" key={idx}>
                    <span className={`mg-r-4 ${colorService}`}>{`${item.name}`}</span>
                    {idx !== services?.length - 1 && <span className="mg-r-4">|</span>}
                  </span>
                )
              })}
            </div>
          </td>
        </tr>
      )}
      <tr className="h-16"></tr>
    </>
  )
}

export default RowExtended
