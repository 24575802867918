import { Divider } from 'antd'
import { trans } from '../../../resources/localization/Localization'
import lodash from 'lodash'
import { ServiceInterface } from '../../../Interface/OrderDetailInterface'
import Skeleton from 'react-loading-skeleton'
import { FC } from 'react'
import ShipmentDetailInterface from 'src/Interface/ShipmentDetailInterface'
import CustomerInterface from 'src/Interface/CustomerInterface'
import { formatNumber } from 'src/Util/string'
interface IProps {
  expand: boolean
  loading: boolean
  details: ShipmentDetailInterface | undefined
  displayAddress: string
  listServices: any
  originalReceipts: any
  refShipmentCode: string
  refCustomerCode: string
  receivingWarehouseAddressReplace: string
  industryGroupNameMapping: { [key in string]: string }
  customer: CustomerInterface | undefined
}

export const MobileView: FC<IProps> = ({
  expand,
  loading,
  details,
  displayAddress,
  listServices,
  originalReceipts,
  refShipmentCode,
  refCustomerCode,
  receivingWarehouseAddressReplace,
  customer
}) => {
  return (
    <>
      <>
        <div className={`shipment-content__text-info pd-12 ${!expand ? 'py-0' : ''}`}>
          {!loading ? <h3>{trans('shipments.information')}</h3> : <Skeleton width={120} height={22} />}
          {expand && (
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-8">
                <div>
                  <span className="txt-color-gray1 font-size-14">Khách hàng:</span>
                  <a
                    href={`/customers/${details?.username}`}
                    className="text-400 font-size-14 flr"
                    target="_blank"
                    rel="noreferrer">
                    {details?.username ? details?.username : '---'} {customer?.name && `(${customer?.name})`}
                  </a>
                </div>
                <div>
                  <span className="txt-color-gray1 font-size-14">{trans('shipments.receiver')}:</span>
                  <span className="text-400 font-size-14 flr">{lodash.get(details, 'address.receiver', '---')}</span>
                </div>

                <div>
                  <span className="txt-color-gray1 font-size-14">{trans('shipments.phoneNumber')}:</span>
                  <span className="text-400 font-size-14 flr">{lodash.get(details, 'address.phoneNumber', '---')}</span>
                </div>

                <div className="flex">
                  <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.address')}:</div>
                  <div className="text-400 font-size-14 text-al-r">
                    {lodash.get(details, 'address.receiver', '---')},{displayAddress}
                  </div>
                </div>
              </div>
              <Divider type="horizontal" className="mg-0" />
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.services')}:</div>
                <div className="text-400 font-size-14 text-al-r">
                  {listServices.length > 0
                    ? listServices.map((item: ServiceInterface, idx: number) => {
                        let colorService = ''
                        if (item.needApprove) {
                          if (item.approved === null || item.approved === undefined) {
                            colorService = 'services-pending'
                          } else if (item.approved === false) {
                            colorService = 'services-reject'
                          }
                        }
                        return (
                          <span key={idx} className={`mg-r-4 ${colorService} font-size-14`}>
                            {`${item.name} ${idx !== listServices.length - 1 ? '|' : ''}`}{' '}
                          </span>
                        )
                      })
                    : '---'}
                </div>{' '}
              </div>
              <Divider type="horizontal" className="mg-0" />
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.originalInvoiceCode')}:</div>
                <div className="text-400 font-size-14 text-al-r">{originalReceipts}</div>
              </div>

              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">Mã đơn hàng của bạn:</div>
                <div className="text-400 font-size-14 text-al-r">{refShipmentCode}</div>
              </div>

              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">Mã khách hàng của bạn:</div>
                <div className="text-400 font-size-14 text-al-r">{refCustomerCode}</div>
              </div>

              <Divider type="horizontal" className="mg-0" />

              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">Cân nặng dự kiến:</div>
                <div className="text-400 font-size-14 text-al-r whitespace-nowrap">
                  {details?.expectedWeight ? formatNumber(details.expectedWeight) + ' (kg)' : '---'}
                </div>
              </div>

              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">Thể tích dự kiến:</div>
                <div className="text-400 font-size-14 text-al-r whitespace-nowrap">
                  {details?.expectedVolume ? `${formatNumber(details?.expectedVolume)} (m3)` : '---'}
                </div>
              </div>

              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">Cân nặng tính phí:</div>
                <div className="text-400 font-size-14 text-al-r whitespace-nowrap">
                  {details?.actualWeight ? details.actualWeight + ' (kg)' : '---'}
                </div>
              </div>

              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">Thể tích thực tế:</div>
                <div className="text-400 font-size-14 text-al-r whitespace-nowrap">
                  {details?.actualVolume ? `${formatNumber(details.actualVolume)} (cm3)` : '---'}
                </div>
              </div>

              <Divider type="horizontal" className="mg-0" />

              <p className="whitespace-pre-wrap">
                Địa chỉ nhận tại Trung Quốc:{' '}
                <span dangerouslySetInnerHTML={{ __html: receivingWarehouseAddressReplace }}></span>
              </p>
            </div>
          )}
        </div>
      </>
    </>
  )
}
