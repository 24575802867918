import { notification } from 'antd'
import axios, { AxiosError } from 'axios'
import lodash from 'lodash'
import appConfig from '../Config/App'
import localStore from './LocalStore'

const instance = axios.create({
  baseURL: appConfig.apiUrl
})

instance.defaults.headers.common['Authorization'] =
  'Bearer ' + lodash.get(localStore.getJson('loginSession'), 'accessToken')

//call api 2m
instance.defaults.timeout = 12000000

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('error', { ...error })
    if (error.isAxiosError && !error.response) {
      notification.error({
        message: 'Mất kết nối đến máy chủ. Vui lòng thử lại sau',
        key: 'network_error'
      })
      return Promise.reject(error)
    }

    if (lodash.get(error, 'response.status') === 401) {
      notification.error({
        message: 'Phiên làm việc hết hạn. Vui lòng làm mới trình duyệt',
        key: 'TOKEN_EXPIRED'
      })

      if (
        !lodash.startsWith(window.location.pathname, '/login') ||
        !lodash.startsWith(window.location.pathname, '/authentication')
      ) {
        setTimeout(() => {
          const redirectBackUrl = window.location.href
          window.location.href = '/login?redirectBackUrl=' + btoa(redirectBackUrl)
        }, 1000)
      }
      return Promise.reject(error)
    }

    if (error.response && error.response?.status >= 500) {
      notification.error({
        message: 'Máy chủ gặp sự cố. Vui lòng thử lại sau',
        key: 'server_error'
      })
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

const apiClient = instance

export default apiClient
