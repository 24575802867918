import { Avatar, Table, Tooltip } from 'antd'
import lodash from 'lodash'
import React from 'react'
import EmptyData from '../../../Component/EmptyData'
import SkeletonTable, { SkeletonTableColumnsType } from '../../../Component/SkeletonTable'
import TruncateText from '../../../Component/TruncateText'
import OrderItemInterface, { VariantPropertiesInterface } from '../../../Interface/OrderItemInterface'
import { trans } from '../../../resources/localization/Localization'
import { formatMoneyByUnit } from '../../../Util/string'

interface OrderItemsProps {
	items: OrderItemInterface[]
	exchangeRate: number
	currencyOfProvider: string
	loading: boolean
}

const OrderItems: React.FC<OrderItemsProps> = (props) => {
	const { items, currencyOfProvider, loading } = props

	const columns = [
		{
			title: trans('orderItem.nameOfItem'),
			key: 'name',
			width: '30%',
			render: (record: OrderItemInterface) => {
				const properties = lodash.get(record, 'variantProperties', [])
				return (
					<span className='flex gap-6'>
						<a href={lodash.get(record, 'skuUrl')} className='cursor-pointer' target='_blank' rel='noreferrer'>
							<Avatar
								shape={'square'}
								size={40}
								src={lodash.get(record, 'skuImage', '')}
								className={'flex-shrink-0 border-radius-none'}
								style={{ border: 'none' }}
							/>
						</a>
						<span className='flex flex-col'>
							<a
								href={lodash.get(record, 'skuUrl')}
								className='cursor-pointer text-reg-500 item-link'
								target='_blank'
								rel='noreferrer'>
								<span>{lodash.get(record, 'skuName', '--')}</span>
							</a>
							<span className='flex flex-col'>
								{properties.length > 0
									? properties.map((pro: VariantPropertiesInterface, idx: number) => (
											<span key={pro.id} className='text-gray text-reg' >{`${pro.name} : ${pro.value}`}</span>
									  ))
									: '--'}
							</span>
							<span className='flex items-center'>
								<span className='text-gray mg-r-2'>ID:</span>
								<span className='text text-reg'>
									{lodash.identity(lodash.get(record, 'providerProductCode'))
										? lodash.get(record, 'providerProductCode')
										: '---'}
								</span>
							</span>
						</span>
					</span>
				)
			},
		},
		{
			title: trans('orderItems.information'),
			key: 'info',
			width: '18%',
			render: (record: OrderItemInterface) => {
				const currency = lodash.get(record, 'currency')
				const orderedQuantity = lodash.identity(lodash.get(record, 'orderedQuantity'))
					? lodash.get(record, 'orderedQuantity')
					: '--'
				const purchasedQuantity = lodash.identity(lodash.get(record, 'purchasedQuantity'))
					? lodash.get(record, 'purchasedQuantity')
					: '--'
				const receivedQuantity = lodash.identity(lodash.get(record, 'receivedQuantity'))
					? lodash.get(record, 'receivedQuantity', '--')
					: '--'
				const totalAmount = lodash.identity(lodash.get(record, 'totalAmount'))
					? lodash.get(record, 'totalAmount', '---')
					: '---'
				const exchangedTotalAmount = lodash.identity(lodash.get(record, 'exchangedTotalAmount'))
					? lodash.get(record, 'exchangedTotalAmount', '---')
					: '---'
				return (
					<div className='flex flex-col'>
						<div className='flex items-center'>
							<span className='mg-r-6 text text-reg'>{`${orderedQuantity}/${purchasedQuantity}/${receivedQuantity}/`}</span>
							<Tooltip
								title={<span className='text text-sm white-text'>{trans('order.qtyTooltip')}</span>}
								color='#CD5E77'
								placement='right'>
								<i className='far fa-info-circle text-sm text-gray mg-r-8 cursor-pointer'></i>
							</Tooltip>
						</div>
						<div className='flex items-center'>
							<span className='mg-r-6'>
								<span className='mg-r-3 text text-reg text-reg-500'>
									{formatMoneyByUnit(lodash.get(record, 'exchangedActualPrice'), currencyOfProvider)}
								</span>
								<span className='text text-gray'>{`(${formatMoneyByUnit(
									lodash.get(record, 'actualPrice'),
									currency
								)})`}</span>
							</span>
							<Tooltip
								title={<span className='text text-sm white-text'>{trans('orderItem.priceOfItem')}</span>}
								color='#CD5E77'
								placement='right'>
								<i className='far fa-info-circle text-gray text-sm mg-r-8 cursor-pointer'></i>
							</Tooltip>
						</div>
						<div className='flex items-center'>
							<span className='mg-r-6'>
								<span className='mg-r-3 text text-reg text-reg-500'>
									{formatMoneyByUnit(exchangedTotalAmount, currencyOfProvider)}
								</span>
								<span className='text text-gray'>{`(${formatMoneyByUnit(totalAmount, currency)})`}</span>
							</span>
							<Tooltip
								title={<span className='text text-sm white-text'>{trans('orderItem.toCash')}</span>}
								color='#CD5E77'
								placement='right'>
								<i className='far fa-info-circle text-sm text-gray mg-r-8 cursor-pointer'></i>
							</Tooltip>
						</div>
					</div>
				)
			},
		},
		{
			title: trans('orderItem.note'),
			key: 'notice',
			width: 400,
			render: (record: OrderItemInterface) => {
				const noteForProduct: string = lodash.identity(lodash.get(record, 'employeeNote'))
          ? (lodash.get(record, 'employeeNote', '---') as string)
          : '---' 

				return (
					<div className='flex items-center'>
						<span className='text-gray mg-r-3'>{`${trans('order.noteForProduct')}:`}</span>
						<span className='text-reg'>
							<TruncateText defaultValue={noteForProduct} />
						</span>
					</div>
				)
			},
		},
	]

	return (
		<SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]} className={`main-table`}>
			<Table
			rowKey={(record: OrderItemInterface) => record.id}
			columns={columns}
			dataSource={items}
			locale={{ emptyText: <EmptyData /> }}
			className='main-table'
			pagination={false}
			scroll={{x: 572}}
		/>
		</SkeletonTable>
	)
}
export default OrderItems

