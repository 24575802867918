import { FC } from 'react'
import { PackageChangedKeys, ProductsChangedKeys, ShipmentLogEvent } from './type'
import { LogItemInterface, LogInterface, ChangedValuesInterface } from 'src/Component/views/LogView/type'
import { formatMoneyByUnit, formatNumber } from 'src/Util/string'
import { trans } from 'src/resources/localization/Localization'
import { IWaybill } from '../type'
import { MidMileMappingType } from 'src/hooks/fetch/useMidmile'
import ShipmentFeeInterface from 'src/Interface/FeeInterface'
import OrderItemInterface from 'src/Interface/OrderItemInterface'

interface ListItemProps extends LogItemInterface<LogInterface<ShipmentLogEvent, IWaybill>> {
  midmileNameMapping: MidMileMappingType
  packageStatusesNameMapping: { [key in string]: string }
  shipmentStatusNameMapping: { [key in string]: string }
}

const LogListItem: FC<ListItemProps> = ({
  item,
  newObject,
  removedObject,
  changedValues,
  packageStatusesNameMapping,
  shipmentStatusNameMapping,
  midmileNameMapping
}: ListItemProps) => {
  const parsePackageChangedValue = ({ left, right, key }: ChangedValuesInterface<PackageChangedKeys>) => {
    switch (key) {
      case 'netWeight':
      case 'actualWeight':
      case 'dimensionalWeight':
      case 'packagingWeight':
        left = formatNumber(left) + ' (kg)'
        right = formatNumber(right) + ' (kg)'
        break
      case 'width':
      case 'length':
      case 'height':
        left = formatNumber(left) + ' (cm)'
        right = formatNumber(right) + ' (cm)'
        break
      case 'volume':
        left = formatNumber(left) + ' (cm3)'
        right = formatNumber(right) + ' (cm3)'
        break
      case 'status':
        left = packageStatusesNameMapping[left]
        right = packageStatusesNameMapping[right]
        break
      case 'midmile':
        left = midmileNameMapping[left]
        right = midmileNameMapping[right]
        break
      default:
        break
    }

    return { key, left, right }
  }

  const renderItem = () => {
    if (item.activity === ShipmentLogEvent.WAYBILL_ADDED) {
      return (
        <>
          Thêm mã vận đơn <span className="font-medium">{newObject?.code}</span>
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.WAYBILL_REMOVED) {
      return (
        <>
          Xóa mã vận đơn <span className="font-medium">{removedObject?.code}</span>
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.PACKAGES_ADDED) {
      return (
        <>
          Thêm kiện hàng <span className="font-medium">{newObject?.code}</span>
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.PACKAGES_REMOVED) {
      return (
        <>
          Xóa kiện hàng <span className="font-medium">{removedObject?.code}</span>
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.PACKAGES_CHANGED) {
      return (
        <>
          Cập nhật thông tin kiện <span className="font-medium">{newObject?.code}</span>:
          <p className="m-0">
            {changedValues.map((changedValue: ChangedValuesInterface<PackageChangedKeys>, index) => {
              const { left, key, right } = parsePackageChangedValue(changedValue)

              return (
                <span key={key}>
                  {trans('common.' + key)}: <span className="font-medium">{String(left)}</span> →{' '}
                  <span className="font-medium"> {String(right)}</span>
                  {index < changedValues.length - 1 && <br />}
                </span>
              )
            })}
          </p>
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.SHIPMENT_UPDATE_STATUS) {
      return (
        <>
          Cập nhật trạng thái đơn:{' '}
          {changedValues.map((changedValue: ChangedValuesInterface, index) => {
            let { left, right } = changedValue
            const { key } = changedValue
            if (key === 'status') {
              left = shipmentStatusNameMapping[left]
              right = shipmentStatusNameMapping[right]
            }

            return (
              <span key={key}>
                <span className="font-medium">{String(left)}</span> →{' '}
                <span className="font-medium"> {String(right)}</span>
                {index < changedValues.length - 1 && <br />}
              </span>
            )
          })}
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.SHIPMENT_UPDATE_FEE) {
      return (
        <>
          {changedValues.map(({ left, right, key }: ChangedValuesInterface<'fee', ShipmentFeeInterface>, index) => (
            <span key={key}>
              <span>Thay đổi {left.name.toLowerCase()}: </span>
              {left.fee !== right.fee && (
                <>
                  <span className="font-medium">{formatMoneyByUnit(left.fee)}</span> →{' '}
                  <span className="font-medium"> {formatMoneyByUnit(right.fee)}</span>
                </>
              )}
              {left.reason !== right.reason && (
                <>
                  <br />
                  <span>
                    Lý do thay đổi: <span className="font-medium">{left.reason ?? '---'}</span> →{' '}
                    <span className="font-medium">{right.reason ?? '---'}</span>
                  </span>
                </>
              )}

              {index < changedValues.length - 1 && <br />}
            </span>
          ))}
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.SHIPMENT_PRODUCT_CREATE) {
      return (
        <>
          Thêm sản phẩm <span className="font-medium">{(newObject as OrderItemInterface).name}</span>{' '}
          <span className="font-medium">({(newObject as OrderItemInterface).code})</span>
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.SHIPMENT_PRODUCT_UPDATE) {
      return (
        <>
          Cập nhật sản phẩm <span className="font-medium">{(newObject as OrderItemInterface).name}</span>{' '}
          <span className="font-medium">({(newObject as OrderItemInterface).code})</span>:
          <p className="m-0">
            {changedValues.map((changedValue: ChangedValuesInterface<ProductsChangedKeys>, index) => {
              const { left, key, right } = parseProductChangedValue(changedValue)
              if (!left && !right) return <></>

              return (
                <span key={key}>
                  {trans('product.' + key)}: <span className="font-medium">{left || '---'}</span> →{' '}
                  <span className="font-medium"> {right || '---'}</span>
                  {index < changedValues.length - 1 && <br />}
                </span>
              )
            })}
          </p>
        </>
      )
    }

    if (item.activity === ShipmentLogEvent.SHIPMENT_PRODUCT_DELETE) {
      return (
        <>
          Xóa sản phẩm <span className="font-medium">{(removedObject as OrderItemInterface).name}</span>{' '}
          <span className="font-medium">({(removedObject as OrderItemInterface).code})</span>
        </>
      )
    }

    return <></>
  }

  return renderItem()
}

export { LogListItem }

const parseProductChangedValue = ({ left, right, key }: ChangedValuesInterface<ProductsChangedKeys>) => {
  switch (key) {
    case 'orderedQuantity':
      left = formatNumber(left)
      right = formatNumber(right)
      break

    case 'price':
      left = formatMoneyByUnit(left, 'CNY')
      right = formatMoneyByUnit(right, 'CNY')
      break
    default:
      break
  }

  return { key, left, right }
}
