import { Form } from 'antd'
import { useState, useEffect, useCallback, FC, useContext } from 'react'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { FloatLabel } from 'src/Component/basic/FloatLabel'
import { ModalCommon, confirm } from 'src/Component/basic/ModalCommon'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { ShipmentDetailContext } from 'src/Screen/ShipmentDetail/ShipmentDetailContext'
import { trans } from 'src/resources/localization/Localization'

interface FieldType {
  waybill: string
}

interface IProps {
  open: boolean
  onCancel: () => void
  onSussess?: () => void
}

export const WaybillCreating: FC<IProps> = ({ open, onCancel, onSussess }) => {
  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [form] = Form.useForm()

  const { shipmentStatusObject, details, refreshDetails } = useContext(ShipmentDetailContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setError('')
    setDisabled(true)
  }, [open])

  const createWayBillHandler = useCallback(
    async (isForce: boolean, values: FieldType) => {
      if (!details) return

      setLoading(true)
      try {
        await ShipmentsApi.createWaybill(details.code, { waybillCode: values.waybill, isForce })
        NotificationCommon.success({ message: 'Tạo mã vận đơn thành công' })
        setError('')
        onCancel()
        form.resetFields()
        onSussess?.()
        refreshDetails(true, false)
      } catch (err: any) {
        if (err?.response?.data?.title === 'waybill_existed') {
          confirm({
            title: 'Thông báo',
            content: 'Mã vận đơn đã được thêm trên đơn khác, bạn có muốn tiếp tục thêm trên đơn này?',
            onOk: () => {
              createWayBillHandler(true, values)
            }
          })
        } else setError(err?.response.data?.title)
      } finally {
        setLoading(false)
        if (isForce) {
          onCancel()
        }
      }
    },
    [details, onCancel, form, onSussess, refreshDetails]
  )

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (!error) {
        createWayBillHandler(false, values)
      }
      if (error === 'waybill_existed_in_other_user') {
        createWayBillHandler(true, values)
      }
    })
  }

  return (
    <ModalCommon
      open={open}
      title={'Thêm mã vận đơn'}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      confirmLoading={loading}
      okButtonProps={{
        disabled
      }}
      destroyOnClose
      width={500}
      onOk={onSubmit}
      className="shipment-modal-create-waybill">
      <Form
        form={form}
        name="create-waybill"
        initialValues={{ waybill: '' }}
        onFinish={onSubmit}
        onFieldsChange={() => {
          setDisabled(form.getFieldsError().some((field) => field.errors.length > 0) || !!error)
          setError('')
        }}
        onValuesChange={(changedValue) => {
          form.setFieldValue('waybill', changedValue['waybill']?.trim())
        }}>
        <FloatLabel
          form={form}
          label="Mã vận đơn"
          name="waybill"
          rules={[
            { required: true, message: 'Không được bỏ trống!' },
            {
              pattern: new RegExp(/^[a-zA-Z0-9:._-]+$/gm),
              message: 'Chứa kí tự không hợp lệ!'
            }
          ]}
          className="!mb-0"
          inputProps={{ autoFocus: true }}
        />

        {error && (
          <div className="text-danger pt-1 cursor-auto">
            {error === 'waybill_existed' && <i className="fa-solid fa-circle-exclamation mr-1 "></i>}
            {error === 'waybill_existed_in_other_user' && (
              <i
                aria-hidden
                className="cursor-pointer fa-solid fa-triangle-exclamation mr-1"
                onClick={() => setError('')}></i>
            )}
            <span className="text-[14px] line-h-20">
              {trans(`shipments.${error}`, { status: shipmentStatusObject?.name })}
            </span>
          </div>
        )}

        <Form.Item hidden>
          <button type="submit" />
        </Form.Item>
      </Form>
    </ModalCommon>
  )
}
