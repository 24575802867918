import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthenticateRoute } from './Component/AuthenticateRoute'
import { Authentication } from './Screen/Authentication'
import { Home } from './Screen/Home'
import { Login } from './Screen/Login'
import { Logout } from './Screen/Logout'
import { Notfound } from './Component/Notfound'
import Orders from './Screen/Orders'
import {
  PERMISSION_ORDER_DETAIL_VIEW,
  PERMISSION_ORDERS_VIEW,
  PERMISSION_CUSTOMER_LIST_VIEW,
  PERMISSION_CUSTOMER_DETAIL_VIEW,
  PERMISSION_PACKAGE_PREFERENCE,
  PERMISSION_SHIPMENTS_VIEW,
  PERMISSION_SHIPMENTS_DETAIL_VIEW,
  PERMISSION_PROVIDER_VIEW,
  PERMISSION_DELIVERY_REQUEST_VIEW,
  PERMISSION_TRANSACTION_VIEW,
  PERMISSION_TRANSACTION_CREATION
} from './Config/permission'
import OrderDetail from './Screen/OrderDetail'
import Customers from './Screen/Customers'
import CustomerDetail from './Screen/Customers/Details'
import Preferences from './Screen/Preferences/Preferences'
import Shipments from './Screen/Shipments/index'
import ShipmentDetail from './Screen/ShipmentDetail/index'
import ProviderManagement from './Screen/Provider'
import { DeliveryRequestList } from './Screen/DeliveryRequest'
import TransactionHistory from './Screen/TransactionHistory'
import { TransactionCreation } from './Screen/TransactionCreation'

export default class AppRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route
            path="/"
            element={
              <AuthenticateRoute>
                <Home />
              </AuthenticateRoute>
            }
          />

          <Route path={'/login'} element={<Login />} />
          <Route path={'/logout'} element={<Logout />} />
          <Route path={'/authentication'} element={<Authentication />} />
          <Route
            path="/orders"
            element={
              <AuthenticateRoute permission={PERMISSION_ORDERS_VIEW}>
                <Orders />
              </AuthenticateRoute>
            }
          />
          <Route
            path={'/orders/:code'}
            element={
              <AuthenticateRoute permission={PERMISSION_ORDER_DETAIL_VIEW}>
                <OrderDetail />
              </AuthenticateRoute>
            }
          />
          <Route
            path="/shipments"
            element={
              <AuthenticateRoute permission={PERMISSION_SHIPMENTS_VIEW}>
                <Shipments />
              </AuthenticateRoute>
            }
          />
          <Route
            path={'/shipments/:code'}
            element={
              <AuthenticateRoute permission={PERMISSION_SHIPMENTS_DETAIL_VIEW}>
                <ShipmentDetail />
              </AuthenticateRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <AuthenticateRoute permission={PERMISSION_CUSTOMER_LIST_VIEW}>
                <Customers />
              </AuthenticateRoute>
            }
          />
          <Route
            path="/customers/:username"
            element={
              <AuthenticateRoute permission={PERMISSION_CUSTOMER_DETAIL_VIEW}>
                <CustomerDetail />
              </AuthenticateRoute>
            }
          />

          <Route
            path="/provider-management"
            element={
              <AuthenticateRoute permission={PERMISSION_PROVIDER_VIEW}>
                <ProviderManagement />
              </AuthenticateRoute>
            }
          />
          <Route
            path={'/preferences'}
            element={
              <AuthenticateRoute permission={PERMISSION_PACKAGE_PREFERENCE}>
                <Preferences />
              </AuthenticateRoute>
            }
          />

          <Route
            path={'/delivery-request'}
            element={
              <AuthenticateRoute permission={PERMISSION_DELIVERY_REQUEST_VIEW}>
                <DeliveryRequestList />
              </AuthenticateRoute>
            }
          />

          <Route
            path={'/transactions'}
            element={
              <AuthenticateRoute permission={PERMISSION_TRANSACTION_VIEW}>
                <TransactionHistory />
              </AuthenticateRoute>
            }
          />

          <Route
            path={'/transaction/create'}
            element={
              <AuthenticateRoute permission={PERMISSION_TRANSACTION_CREATION}>
                <TransactionCreation />
              </AuthenticateRoute>
            }
          />

          <Route path={'*'} element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    )
  }
}
