import { Button, List, Modal, Skeleton } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { parseOrderLog } from "../../Util/FormatLog";
import { trans } from "../../resources/localization/Localization";
import EmptyData from "../../Component/EmptyData";
import { formatDateTime } from "../../Util/string";
import { OrdersApi } from "../../Api/OrdersApi";

interface LogModalProps {
  visibleHandler: (val: boolean) => void;
  visible: boolean;
  currency: any;
  metadata: any;
  handleMetadata: (obj: any) => void;
}

function Logs(props: LogModalProps) {
  const { visibleHandler, visible, currency, metadata, handleMetadata } = props;

  const [dataSource, setDataSource] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [initLoading, setInitLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [list, setList] = useState<any[]>([]);
  const refLog = useRef<HTMLDivElement | null>(null);
  let { code } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      getData(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (
      dataSource &&
      dataSource.concat(
        list.slice(dataSource.length + 1, dataSource.length + 10)
      ).length === list.length
    ) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const getData = (page: number) => {
    setInitLoading(true);
    if (page > 0) {
      setLoading(true);
      setInitLoading(false);
      setList(
        dataSource.concat(
          [...new Array(metadata.page)].map(() => ({ loading: true }))
        )
      );
    }
    OrdersApi.getLogs(code!, { page })
      .then((res: any) => {
        let items = parseOrderLog(trans, res.data, currency, true);
        setList(items);
        setDataSource(items.slice(0, 10));
        if (page > 0) {
          window.dispatchEvent(new Event("resize"));
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
        setInitLoading(false);
        refLog.current?.scrollTo({ top: 0 });
      });
  };
  const loadMoreData = () => {
    if (loading) return;
    // getData(metadata.page + 1)
    setDataSource(
      dataSource.concat(
        list.slice(dataSource.length + 0, dataSource.length + 10)
      )
    );
  };

  const loadMore =
    !initLoading && hasMore ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button
          className="btn-default-rounded py-5 px-15"
          loading={loading}
          onClick={loadMoreData}
        >
          {trans("button.loadmore")}
        </Button>
      </div>
    ) : null;
  return (
    <Modal
      open={visible}
      title={
        <span className="robotomedium line-h-22 fsz-14 txt-color-primary pd-0">
          {/* {t("logOrder.title")} #{code} */}
          {t("Log đơn hàng")} #{code}

        </span>
      }
      onCancel={() => {
        visibleHandler(false);
        handleMetadata(null);
      }}
      closeIcon={
        <i className="far fa-times w-9 h-16 fsz-14 line-h-16 txt-color-black"></i>
      }
      footer={false}
      width={750}
      bodyStyle={{ padding: 0 }}
      centered
      className="shipment-logs"
    >
      <div
        ref={refLog}
        style={{
          maxHeight: "75vh",
          overflow: "auto",
          paddingBottom: 20,
        }}
      >
        <List
          loadMore={loadMore}
          dataSource={dataSource}
          loading={initLoading}
          className="px-16 "
          locale={{
            emptyText: <EmptyData text={`${trans("orders.empty")}`} />,
          }}
          renderItem={(item: any, index: number) => {
            return (
              <Skeleton title={false} loading={item.loading} active paragraph>
                <List.Item
                  key={index}
                  style={{ paddingTop: 12, paddingBottom: 12 }}
                >
                  <div>
                    <span className={"font-size-14"}>
                      <span className={"txt-color-gray2 "}>
                        {trans("log_order.timestamp")}
                      </span>
                      <span className={"txt-color-black"}>
                        {" "}
                        {formatDateTime(item.timestamp)}
                      </span>
                      <span>
                        <span className={"pd-l-12 txt-color-gray2 "}>
                          {item.role === "STAFF"
                            ? `${trans("log_order.staff")}`
                            : `${trans("log_order.customer")}`}
                        </span>
                        <span
                          className={
                            "txt-color-black text-500 font-size-14 pd-l-5"
                          }
                        >
                          <strong>{item.fullname}</strong>
                        </span>
                      </span>
                    </span>
                    <span
                      className="font-size-14 txt-color-black d-block mg-t-4"
                      dangerouslySetInnerHTML={{
                        __html: `${t(`log_order.${item.property}`, {
                          ...item,
                        })}`,
                      }}
                    ></span>
                  </div>
                </List.Item>
              </Skeleton>
            );
          }}
        />
      </div>
    </Modal>
  );
}
export default Logs;
