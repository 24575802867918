import { Col, Row } from "antd";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { formatMoneyByUnit } from "../../../Util/string";

interface ItemProps {
  label: string
  labelClass?: string
  valueClass?: string
  number?: number | any
  value: any
  subValue?: any
  currencyValue?: string
  currencySubValue?: string
  loading: boolean
}
function Item(props: ItemProps) {
  const {
    label,
    labelClass,
    number,
    value,
    subValue,
    currencyValue,
    currencySubValue,
    valueClass,
    loading,
  } = props;
  const renderNumber = (number: any) => {
    if (typeof number === "object") {
      let indexArr: any = [];
      number.forEach((element: any, index: number) => {
        if (element.actualAmount) {
          indexArr.push(index + 1);
        }
      });
      let text = indexArr.map((item: any) => {
        return `(2.${item})`;
      });
      return `(2) ${text.length !== 0 ? " =" : ""} ${text.join(" + ")}`;
    } else {
      return number;
    }
  };

  return (
    <Row className={"flex justify-between items-center mg-bt-6"}>
      {!loading ? (
        <Col className={"items-center dpl-flex"}>
          <span className={`txt-size-h7 txt-color-black mg-r-6 ${labelClass}`}>
            {label}
          </span>
          <span className={"txt-size-h8 txt-color-gray2"}>
            {renderNumber(number)}
          </span>
        </Col>
      ) : (
        <Skeleton width={80} height={20} />
      )}
      {!loading ? (
        <Col>
          {value || value === 0 ? (
            <Row className={"justify-end"}>
              <span className={`txt-size-h7 txt-color-black ${valueClass}`}>
                {formatMoneyByUnit(value, currencyValue)}
              </span>
              <span className={"txt-size-h7 txt-color-secondary mgl3"}>
                {subValue
                  ? `(${formatMoneyByUnit(subValue, currencySubValue)})`
                  : ""}
              </span>
            </Row>
          ) : (
            <span className={"txt-size-h8"}>---</span>
          )}
        </Col>
      ) : (
        <Skeleton width={120} height={20} />
      )}
    </Row>
  );
}
export default Item;
