export const PERMISSION_ORDERS_VIEW = 'order:view:list'
export const PERMISSION_ORDER_DETAIL_VIEW = 'order:view:detail'
export const PERMISSION_ORDERS_ITEMS_VIEW = 'order:item:view'
export const PERMISSION_ORDER_UPDATE_STATUS = 'order:update:status'
export const PERMISSION_PACKAGE_ORDERS_VIEW_DETAIL = 'package-order:view:list'
export const PERMISSION_PACKAGE_ORDERS_UPDATE_STATUS = 'package-order:update:status'
export const PERMISSION_PACKAGE_ORDERS_VIEW_LOG = 'package-order:view:log'
export const PERMISSION_ORDER_PAYMENTREQUEST_VIEW = 'order:payment_request:view'
export const PERMISSION_ORDER_TRANSACTION_VIEW = 'order:transaction:view'
export const PERMISSION_CUSTOMER_LIST_VIEW = 'customer:view:list'
export const PERMISSION_PACKAGE_ORDERS_VIEW = 'order:package:view'
export const PERMISSION_CUSTOMER_DETAIL_VIEW = 'customer:view:detail'
export const PERMISSION_ORDER_HISTORY_VIEW = 'order:history:view'
export const PERMISSION_PACKAGE_ORDER_UPDATE_PROMOTE = 'package-order:update:promotion-status'
export const PERMISSION_PACKAGE_PREFERENCE = 'preference:general'
export const PERMISSION_PACKAGE_ORDER_VIEW_INVOICE = 'package-order:view:invoice'
export const PERMISSION_PACKAGE_ORDER_SYNC_INVOICE = 'package-order:update:status'
export const PERMISSION_SHIPMENTS_VIEW = 'shipment:view:list'
export const PERMISSION_DELIVERY_REQUEST_VIEW = 'delivery_request:view:list'
export const PERMISSION_TRANSACTION_VIEW = 'transaction:view:list'
export const PERMISSION_TRANSACTION_CREATION = 'transaction:create:adjust'
export const PERMISSION_TRANSACTION_DEPOSIT = 'transaction:create:deposit'
export const PERMISSION_SHIPMENTS_DETAIL_VIEW = 'shipment:view:detail'
export const PERMISSION_SHIPMENTS_PRODUCT_VIEW = 'shipment:product:view'
export const PERMISSION_SHIPMENTS_PRODUCT_UPDATE = 'shipment:product:update'
export const PERMISSION_SHIPMENTS_WAYBILL_UPDATE = 'shipment:update:waybill'
export const PERMISSION_SHIPMENTS_PACKAGE_VIEW = 'shipment:package:view'
export const PERMISSION_SHIPMENTS_UPDATE_STATUS = 'shipment:update:status'
export const PERMISSION_SHIPMENTS_UPDATE_FEE = 'shipment:update:fee'
export const PERMISSION_SHIPMENTS_TRANSACTION_VIEW = 'shipment:transaction:view'
export const PERMISSION_SHIPMENT_HISTORY_VIEW = 'shipment:history:view'
export const PERMISSION_SHIPMENT_LOG_VIEW = 'shipment:log:view'
export const PERMISSION_ORDER_LOG_VIEW = 'order:log:view'
export const PERMISSION_PROVIDER_VIEW = 'provider:list'
export const PERMISSION_PROVIDER_UPDATE = 'provider:list:update'
export const PERMISSION_TRANSACTION_HISTORY_VIEW = 'customer:transaction:view:list'
export const CUSTOMER_DEPOSIT_CREATE = 'customer:deposit:create'
export const PERMISSION_LASTMILE_BAG_VIEW_LIST = 'lastmile:bag:view:list'
export const PERMISSION_LASTMILE_BAG_VIEW_DETAIL = 'lastmile:bag:view:detail'
export const SHIPMENT_VIEW_PROVIDER_DETAIL = 'shipment:view:provider_detail'
export const ORDER_VIEW_PROVIDER_DETAIL = 'order:view:provider_detail'
export const SHIPMENT_VIEW_MIDMILE_PARTNER = 'shipment:view:midmile_partner'
export const SHIPMENT_UPDATE_MIDMILE_PARTNER = 'shipment:update:midmile_partner'
export const TRANSACTION_CREATE_ADJUST = 'transaction:create:adjust'
