import { Avatar, Spin } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'
import { formatMoneyByUnit } from 'src/Util/string'

interface CustomerInformationProps {
  customer: any
  isLoadingBalance?: boolean
}

export const CustomerInformation: FC<CustomerInformationProps> = ({ customer, isLoadingBalance }) => {
  return (
    <div className="flex-1 p-6 h-fit min-h-[240px] bg-[#FFF] rounded-md shadow-[0px_6px_15px_0px_rgba(0,0,0,0.1)] flex justify-center flex-col">
      {customer ? (
        <div className="flex gap-40 items-center">
          <div className="flex flex-col gap-10 items-center">
            <Avatar src={customer.avatar} size={160} shape="circle" />
            {customer.username} | {customer.code}
          </div>

          <div className="flex flex-col items-start gap-12">
            <span className="font-medium text-xl">{customer.name}</span>

            {isLoadingBalance ? (
              <Spin />
            ) : (
              <span
                className={clsx(
                  customer.balance && customer.balance?.balance >= 0 ? 'text-success' : 'text-danger',
                  'font-medium cursor-default text-lg'
                )}>
                {formatMoneyByUnit(customer.balance?.balance, customer?.balance?.currency)}
              </span>
            )}

            <span>
              <span className="text-[#5A5A5A]">Số điện thoại:</span> <br />
              <span className="font-medium">{customer.mobile || '---'}</span>
            </span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center gap-12 h-full">
          <img src={'/images/search_empty.svg'} alt="empty" />

          <span className="text-[#7D7E7E] text-base">Nhập thông tin tìm kiếm</span>
        </div>
      )}
    </div>
  )
}
