import { Steps } from 'antd'
import lodash from 'lodash'
import { GetComponentProps } from 'rc-table/lib/interface'
import { MilestoneInterfaceInOrderDetail } from '../../../../Interface/MilestoneInterface'
import PackageStatusInterface from '../../../../Interface/PackageStatusesInterface'
import { trans } from '../../../../resources/localization/Localization'
import { formatDateTime } from '../../../../Util/string'

const { Step } = Steps

interface TrackPackagesProps extends GetComponentProps<any> {
	statuses: PackageStatusInterface[]
}

const TracksPackage: React.FC<TrackPackagesProps> = (record) => {
	const customDot = (dot: any, { status, index }: any) => {
		return <i className='far fa-dot-circle text-sm' />
	}
	const historyPackage = lodash.get(record, 'milestones', [])

	const CustomDescription = ({ code }: { code: string }) => {
		const historyTrackByPosition = historyPackage.filter(({ status }: any) => status === code)
		const classes = 'flex flex-col items-start justify-start'
		return (
			<span className={`${classes} gap-4`}>
				{historyTrackByPosition.length > 0 ? (
					historyTrackByPosition.map(({ timestamp, handlingTime }: any, idx: number) => {
						const timestampRendered = lodash.isNull(timestamp)
							? trans('orderDetail.undefined')
							: formatDateTime(timestamp)
						const handlingTimeRendered = lodash.isNull(handlingTime)
							? trans('orderDetail.undefined')
							: `${handlingTime} ngày`
						return (
							<span className={classes} key={idx}>
								<span className='text-gray'>{timestampRendered}</span>
								<span className='text-gray'>{`(${handlingTimeRendered})`}</span>
							</span>
						)
					})
				) : (
					<span className='text-gray-1'>{`${trans('orderDetail.undefined')}`}</span>
				)}
			</span>
		)
	}
	const codes = lodash.uniq(lodash.map(historyPackage, 'status'))
	let positions: Number[] = []
	for (let index = 0; index < codes.length; index++) {
		const status = record.statuses.find(({ code }: any) => code === codes[index])
		positions.push(lodash.get(status, 'position')!)
	}
	const positionMax = Number(lodash.max(positions))
  const packageCode = lodash.get(record, 'code')
  const lastIndex = lodash.get(record, 'total')
  const curIndex = lodash.get(record, 'index')
  return (
    <>
      <tr className={`package-data rad-3 ${!packageCode ? 'bg-white' : 'bg-pink'}`}>{record.children}</tr>
      {lastIndex && (
        <tr className="bd-bt-none package-timeline">
          <td colSpan={7} className="td-table-cell">
            <div className={`package-timeline ${curIndex === lastIndex - 1 ? 'pd-bt-0' : 'pd-bt-11'}`}>
              <Steps progressDot={customDot}>
                {historyPackage &&
                  historyPackage.length > 0 &&
                  record.statuses.map(({ name, code, id, position }: PackageStatusInterface) => {
                    const indexOfStatus = historyPackage.findIndex(
                      ({ status }: MilestoneInterfaceInOrderDetail) => code === status
                    )
                    const status = indexOfStatus > -1 ? 'finish' : 'wait'
                    const txtClasses = status === 'finish' ? '' : 'text-gray-1'
                    return (
                      <Step
                        status={status}
                        key={id}
                        className={
                          position > positionMax!
                            ? 'tail-none'
                            : position === positionMax
                            ? 'tail-none last-tail'
                            : 'tail'
                        }
                        title={<span className={`text-13 text-al-l text-500 ${txtClasses}`}>{name}</span>}
                        description={<CustomDescription code={code} />}
                      />
                    )
                  })}
              </Steps>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export default TracksPackage
