import { UploadFile, Image, Form } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { useState, FC } from 'react'
import { ModalCommon } from 'src/Component/basic/ModalCommon'

import './style.scss'
import { UploadInput, UploadInputProps } from './UploadInput'

interface UploadImageProps extends UploadInputProps {}

export const UploadImage: FC<UploadImageProps> = () => {
  const [previewImage, setPreviewImage] = useState<{ src: string; title: string }>()

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Preview(file.originFileObj as RcFile)
    }

    setPreviewImage({
      src: file.url || (file.preview as string),
      title: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    })
  }

  return (
    <>
      <Form.Item
        name="image"
        label="Ảnh sản phẩm"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        labelAlign="left"
        className="upload-image m-0">
        <UploadInput onPreview={handlePreview}></UploadInput>
      </Form.Item>

      <ModalCommon
        open={!!previewImage?.src}
        title={previewImage?.title}
        footer={null}
        bodyStyle={{ padding: 16 }}
        onCancel={() => setPreviewImage(undefined)}
        centered>
        <Image alt="example" style={{ width: '100%' }} src={previewImage?.src} preview={false} />
      </ModalCommon>
    </>
  )
}

const getBase64Preview = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
