import React, { FC, useMemo } from 'react'
import { get } from 'lodash'
import { List } from 'antd'
import moment from 'moment/moment'
import { ChangedValuesInterface, LogInterface, LogItemInterface } from './type'

interface LogItemProps {
  item: LogInterface<any>
  index: number
  code?: string
  ListItemContent: FC<LogItemInterface>
}

const hiddenEmptyChangedValuesLogType: string[] = []

export const LogItem: FC<LogItemProps> = ({ item = {} as LogInterface<any>, index, code, ListItemContent }) => {
  const { actor } = item
  const changedValues = useMemo(() => {
    let changedValues: ChangedValuesInterface[] = get(item, 'change.valueChange.changedValues', []) || []

    changedValues = changedValues.filter((changedValue) => !(changedValue.left === '' && changedValue.right === ''))

    return changedValues
  }, [item])
  const newObject: any = get(item, 'change.newObject[0]')
  const removedObject: any = get(item, 'change.removedObject[0]')

  const isShowItem = useMemo(() => {
    if (hiddenEmptyChangedValuesLogType.includes(item.activity) && changedValues.length < 1) return false

    return true
  }, [item.activity, changedValues])

  if (!isShowItem) return null

  return (
    <List.Item key={index} className={index === 0 ? '!pt-0' : undefined}>
      <div className="width-100pc">
        <span>
          <span className={'text-muted'}>Thời gian:</span>{' '}
          <span className={'font-medium'}>{moment(item.timestamp).format('DD/MM/YYYY HH:mm:ss')}</span>
          <span>
            <span className={'pl-3 text-muted'}>
              {actor?.role === 'STAFF' && 'Nhân viên'}
              {actor?.role === 'CUSTOMER' && 'Khách hàng'}:
            </span>{' '}
            <span className={'font-medium'}>{actor?.username === 'system' ? 'Hệ thống' : actor?.username}</span>
          </span>
        </span>

        <div>
          {code && (
            <ListItemContent
              item={item}
              index={index}
              code={code}
              changedValues={changedValues}
              newObject={newObject || {}}
              removedObject={removedObject || {}}
            />
          )}
        </div>
      </div>
    </List.Item>
  )
}
