import { Steps } from 'antd'
import lodash from 'lodash'
import { trans } from '../../../../../resources/localization/Localization'
import { formatDateTime } from '../../../../../Util/string'
import { IMilestone } from '../../../type'
import moment from 'moment'
import PackageStatusInterface from 'src/Interface/PackageStatusesInterface'

const { Step } = Steps
interface TracksPackageProps {
  total: number
  statuses: PackageStatusInterface[]
  milestones: IMilestone[]
  code: string
  index: number
}

const TracksPackage: React.FC<TracksPackageProps> = ({ milestones, statuses, total, index }) => {
  const customDot = () => {
    return <i className="far fa-dot-circle text-sm" />
  }

  const CustomDescription = ({ code }: { code: string }) => {
    const historyTrackByPosition = milestones
      .filter(({ status }) => status === code)
      .sort((a, b) => (moment(a.createdAt) > moment(b.createdAt) ? -1 : 1))
    const classes = 'flex flex-col items-start justify-start'

    return (
      <span className={`${classes} text-xs`}>
        {historyTrackByPosition.length > 0 ? (
          historyTrackByPosition.map(({ createdAt }, idx: number) => {
            const timestampRendered = lodash.isNull(createdAt)
              ? trans('orderDetail.undefined')
              : formatDateTime(createdAt)

            return (
              <span className={`${classes} mg-bt-4`} key={idx}>
                <span className="txt-color-gray4">{timestampRendered}</span>
              </span>
            )
          })
        ) : (
          <span className="txt-color-gray2">{`${trans('orderDetail.undefined')}`}</span>
        )}
      </span>
    )
  }

  const codes = lodash.uniq(lodash.map(milestones, 'status'))
  let positions: Number[] = []
  for (let index = 0; index < codes.length; index++) {
    const status = statuses.find(({ code }) => code === codes[index])
    positions.push(lodash.get(status, 'position')!)
  }
  const positionMax = lodash.max(positions)

  return (
    <div className={`package-timeline ${total === index ? 'pd-bt-0' : 'package-timeline-tabs'} pd-t-12`}>
      <Steps progressDot={customDot} responsive={false}>
        {milestones?.length > 0 &&
          statuses.map(({ name, code, id, position }: any) => {
            const indexOfStatus = milestones.findIndex(({ status }) => code === status)
            const status = indexOfStatus > -1 ? 'finish' : 'wait'
            const txtClasses = status === 'finish' ? 'txt-color-black' : 'txt-color-gray2'
            return (
              <Step
                status={status}
                key={id}
                className={
                  position > positionMax! ? 'tail-none' : position === positionMax ? 'tail-none last-tail' : 'tail'
                }
                title={<span className={`txt-left text-[12px] font-medium line-h-20 ${txtClasses}`}>{name}</span>}
                description={<CustomDescription code={code} />}
              />
            )
          })}
      </Steps>
    </div>
  )
}

export default TracksPackage
