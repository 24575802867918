import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { SupplierApi } from '../../Api/SupplierApi'
import { formatDateTime } from '../../Util/string'
import DefaultLayout from '../../Component/Layout/Default'
import { trans } from '../../resources/localization/Localization'
import { FilterCommon, FilterCommonProps } from 'src/Component/TableCommon/Filter'
import { TableCommon } from 'src/Component/TableCommon'
import moment from 'moment'
import ParagraphWithCopy from 'src/Component/inputs/ParagraphWithCopyButton'

interface CustomerProps {}
const Customers: React.FC<CustomerProps> = (props) => {
  const firstItems: FilterCommonProps['firstItems'] = [
    {
      name: 'code',
      label: 'Mã khách hàng',
      type: 'text'
    },
    {
      name: 'query',
      label: 'Username/Tên khách hàng',
      type: 'text'
    },
    {
      name: 'mobile',
      label: 'Số điện thoại',
      type: 'number'
    }
  ]

  const secondItems: FilterCommonProps['firstItems'] = [
    {
      name: 'email',
      label: 'Email',
      type: 'text'
    },
    {
      name: 'timestame',
      label: ['Ngày đăng ký từ', 'Đến ngày'],
      type: 'date-range',
      fieldName: ['createdAtFrom', 'createdAtTo']
    }
  ]

  const columns = [
    {
      title: trans('customer.code'),
      dataIndex: 'code',
      width: 120,
      render: (code: string, record: any) => (
        <ParagraphWithCopy text={code} className="text-violet mg-bt-0 text-500">
          <Link to={`/customers/${record.username}`}>{code}</Link>
        </ParagraphWithCopy>
      )
    },
    {
      title: trans('customers.usernameInList'),
      dataIndex: 'username',
      width: 158,
      render: (username: string, record: any) => (
        <span className="text-reg text-nowrap">{`${username} (${record.name})`}</span>
      )
    },
    {
      title: trans('customer.phone'),
      dataIndex: 'mobile',
      width: 120,
      render: (mobile: string) => <span className="text-reg">{mobile ? mobile : '---'}</span>
    },
    {
      title: trans('customers.email'),
      dataIndex: 'email',
      width: 208,
      render: (email: string) => <span className="text-reg">{email}</span>
    },
    {
      title: trans('customers.createdAt'),
      dataIndex: 'createdAt',
      width: 100,
      render: (createdAt: string) => (
        <span className="text-reg">{createdAt ? formatDateTime(moment(createdAt)) : '---'}</span>
      )
    }
  ]

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1)
  }, [])

  return (
    <DefaultLayout title={trans('sidebar.customers')} {...props}>
      <FilterCommon firstItems={firstItems} secondItems={secondItems} />

      <TableCommon
        queryKey="customer-list"
        tableTitle={'Danh Sách Khách Hàng'}
        filterItems={[...firstItems, ...secondItems]}
        containerClassName="mt-3"
        columns={columns}
        fetchApiService={SupplierApi.customerFilter}
      />
    </DefaultLayout>
  )
}

export default Customers
