import lodash from 'lodash'
import React from 'react'
import ProviderInterface from '../../../Interface/ProviderInterface'
import ShipmentDetailInterface from '../../../Interface/ShipmentDetailInterface'
import useWindowDimensions from '../../../Util/dimention'
import { EMPTY_INFO } from '../../../Util/Constants'
import { MobileView } from './MobileView'
import { DesktopView } from './DesktopView'
import useIndustryGroup from 'src/hooks/fetch/useIndustryGroup'
import { useCustomer } from 'src/hooks/fetch/useCustomer'

interface InformationProps {
  details?: ShipmentDetailInterface
  providers: ProviderInterface[]
  currencyOfProvider: string
  expand: boolean
  loading: boolean
}

export const textFromData = (
  obj: { [id: string]: any },
  key: string,
  empty: string | Array<any>
) => {
  return lodash.isNil(lodash.get(obj, key)) || lodash.isEmpty(lodash.get(obj, key))
    ? empty
    : lodash.get(obj, key)
}

const Information: React.FC<InformationProps> = (props) => {
  const { details, expand, loading } = props

  const { industryGroupNameMapping } = useIndustryGroup()
  const { customer } = useCustomer(details?.username)

  const emptyTxt = '---'
  const refShipmentCode = textFromData(details!, 'refShipmentCode', emptyTxt)
  const refCustomerCode = textFromData(details!, 'refCustomerCode', emptyTxt)
  const originalReceipts = emptyTxt
  const displayAddress = textFromData(details!, 'address.display', emptyTxt)
  const listServices = textFromData(details!, 'services', [])
  const receivingWarehouseAddressReplace =
    details?.receiveWarehouse?.detailWarehouse.recipientDetail?.replaceAll(
      '[[order_code]]',
      `<span class="whitespace-nowrap">${details?.code}</span>`
    ) || EMPTY_INFO

  const { width } = useWindowDimensions()

  return width < 768 ? (
    <MobileView
      expand={expand}
      loading={loading}
      details={details}
      displayAddress={displayAddress}
      listServices={listServices}
      originalReceipts={originalReceipts}
      refShipmentCode={refShipmentCode}
      refCustomerCode={refCustomerCode}
      receivingWarehouseAddressReplace={receivingWarehouseAddressReplace}
      industryGroupNameMapping={industryGroupNameMapping}
      customer={customer}
    />
  ) : (
    <DesktopView
      expand={expand}
      loading={loading}
      details={details}
      displayAddress={displayAddress}
      listServices={listServices}
      originalReceipts={originalReceipts}
      receivingWarehouseAddressReplace={receivingWarehouseAddressReplace}
      industryGroupNameMapping={industryGroupNameMapping}
      customer={customer}
    />
  )
}

export default Information
