import React from 'react'
import Skeleton from 'react-loading-skeleton'
import FeeBox from './FeeBox'
import { trans } from '../../../resources/localization/Localization'

interface FinanceProps {
  expand: boolean
  loading: boolean
}

const Finance = (props: FinanceProps) => {
  const { expand, loading } = props

  return (
    <div className={`${expand ? 'mg-bt-24' : 'py-0'} shipment-content__text-finance pd-12`}>
      {expand ? (
        <h2 className="mg-0">{!loading ? trans('shipments.finance') : <Skeleton width={120} height={22} />}</h2>
      ) : (
        ''
      )}
      {expand && <FeeBox loading={loading} />}
    </div>
  )
}

export default Finance
