import { Avatar, Select } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { TableCommon } from 'src/Component/TableCommon'
import { FilterCommon, FilterCommonProps } from 'src/Component/TableCommon/Filter'
import ShipmentInterface, { IMidMile } from 'src/Interface/ShipmentInterface'
import { formatDateTime, formatMoneyByUnit } from 'src/Util/string'
import { useMidmile } from 'src/hooks/fetch/useMidmile'
import useShipmentStatus from 'src/hooks/fetch/useShipmentStatus'
import ParagraphWithCopy from 'src/Component/inputs/ParagraphWithCopyButton'
import IShipmentStatus from 'src/Interface/StatusInterface'
import { ListItem } from './ListItem'
import DefaultLayout from 'src/Component/Layout/Default'
import { trans } from 'src/resources/localization/Localization'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'
import RowExtended from './components/RowExtend'
import { SupplierApi } from 'src/Api/SupplierApi'
import CustomerInterface from 'src/Interface/CustomerInterface'
import { useEffect, useMemo } from 'react'
import { SHIPMENT_VIEW_MIDMILE_PARTNER } from 'src/Config/permission'
import SecurityService from 'src/Util/SecurityService'
import IMAGE_DEFAULT from 'src/resources/images/empty-image.jpg'

const Shipments = () => {
  const { t } = useTranslation()
  const { getMidmileOptions } = useMidmile()

  const { statuses, statusColorMapping, statusNameMapping } = useShipmentStatus()
  const [searchParams, setSearchParams] = useSearchParams()

  const onChangeOrderBy = (value: string) => {
    let urlSearchParams: any = {}
    searchParams.forEach((value, key) => {
      urlSearchParams[key] = value
    })
    setSearchParams({
      ...urlSearchParams,
      orderBy: value
    })
  }

  const columns = useMemo(() => {
    const columns: ColumnsType<any> = [
      {
        title: t('shipment.codeTitle'),
        width: '45%',
        render: (record: any) => {
          return (
            <div className="flex flex-nowrap gap-12">
              <Avatar src={record.image || IMAGE_DEFAULT} shape="square" size={72} className="bd-none-impt rad-6" />
              <div className="flex flex-col flex-grow gap-4">
                <div className="flex justify-between gap-12">
                  <ParagraphWithCopy text={record.code}>#{record.code}</ParagraphWithCopy>

                  <TooltipCommon title={'Thời gian tạo'}>
                    <span className="whitespace-nowrap">
                      {record.createdAt ? formatDateTime(record.createdAt) : '---'}
                    </span>
                  </TooltipCommon>
                </div>

                <div className="flex">
                  <span className="inline-flex gap-4 flex-grow">
                    <span className={`text-secondary`}>Khách hàng:</span>
                    <span className="font-medium">{record.username}</span>
                  </span>

                  <div className="flex">
                    <TooltipCommon title={'Đặt / Nhận'}>
                      <span className="mg-r-3">{`${record.orderedQuantity ? record.orderedQuantity : '---'} / ${
                        record.receivedQuantity ? record.receivedQuantity : '---'
                      }`}</span>
                    </TooltipCommon>
                  </div>
                </div>

                <div className="flex gap-4">
                  <span className={`text-secondary`}>Mã đơn hàng của bạn: </span>
                  <ParagraphWithCopy text={record.refShipmentCode} className="truncate max-w-[15vw] inline-block" />
                </div>
              </div>
            </div>
          )
        }
      },
      {
        title: 'Vận chuyển Midmile',
        dataIndex: ['midMileView', '0'],
        width: 200,
        render: (midMileView: IMidMile) => {
          if (!midMileView?.code) return '---'

          return (
            <span className="inline-flex gap-2 items-center whitespace-nowrap">
              <Avatar src={midMileView.logo} size={24} className="mg-r-4" />
              <span>{midMileView.name}</span>
            </span>
          )
        }
      },
      {
        title: 'Tổng phí',
        align: 'right',
        width: 180,
        render: (record: any) => {
          return <span className="font-medium whitespace">{formatMoneyByUnit(record.totalFee)}</span>
        }
      },
      {
        title: 'Tiền Hàng',
        width: 180,
        align: 'right',
        render: (record: ShipmentInterface) => {
          return <span className="font-medium whitespace">{formatMoneyByUnit(record.totalValue, 'CNY')}</span>
        }
      },
      {
        title: 'Cần thanh toán',
        align: 'right',
        width: 180,
        render: (record: ShipmentInterface) => {
          return (
            <span className="font-medium whitespace text-red">
              {formatMoneyByUnit(record.status === 'CANCELLED' ? 0 : record.totalUnpaid)}
            </span>
          )
        }
      },
      {
        title: 'Trạng thái đơn hàng',
        align: 'right',
        width: 180,
        render: (record: any) => {
          const status = statuses?.find((stt: IShipmentStatus) => stt.code === record.status)
          return (
            <span
              className="rounded-full px-12 py-4 text-white font-medium capitalize whitespace-nowrap"
              style={{
                backgroundColor: `${status?.color ? status.color : '#0082E6'}`
              }}>
              {status?.name}
            </span>
          )
        }
      }
    ]

    if (!SecurityService.can(SHIPMENT_VIEW_MIDMILE_PARTNER)) {
      columns.splice(1, 1)
    }
    return columns
  }, [statuses, t])

  const renderRowItem = (item: ShipmentInterface, index: number) => {
    return (
      <ListItem
        item={item}
        index={index}
        statusColorMapping={statusColorMapping}
        statusNameMapping={statusNameMapping}
      />
    )
  }

  const firstItems: FilterCommonProps['firstItems'] = [
    {
      name: 'statuses',
      type: 'list-select',
      options: statuses?.map((status) => ({ value: status.code, label: status.name }))
    }
  ]

  const secondItems: FilterCommonProps['secondItems'] = [
    { name: 'code', label: 'Mã đơn hàng', type: 'text' },
    {
      name: 'userCode',
      label: 'Mã khách hàng',
      type: 'text'
    },
    {
      name: 'username',
      label: 'Tên (username) khách hàng',
      type: 'select',
      selectProps: {
        showSearch: true,
        fetchApi: SupplierApi.getCustomers,
        fieldNames: { value: 'username', label: 'name' },
        mapLabel: (item: CustomerInterface) => `${item.name} (${item.username})`
      }
    },
    {
      name: 'midmile',
      label: 'Vận chuyển Midmile',
      type: 'select',
      options: getMidmileOptions(),
      selectProps: { showSearch: true }
    },
    {
      name: 'createdAt',
      fieldName: ['timestampFrom', 'timestampTo'],
      label: ['Thời gian tạo từ ngày', 'Đến ngày'],
      type: 'date-range'
    }
  ]

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1)
  }, [])

  return (
    <DefaultLayout title="Danh sách đơn ký gửi">
      <div className="mb-2 md:mb-3">
        <FilterCommon firstItems={firstItems} secondItems={secondItems} />
      </div>

      <TableCommon
        queryKey="shipment-list"
        filterItems={[...firstItems, ...secondItems]}
        tableTitle={'Danh sách đơn ký gửi'}
        columns={columns}
        fetchApiService={ShipmentsApi.get}
        actions={[
          <Select
            key={'aas'}
            style={{ width: 110 }}
            className="form-input"
            options={[
              { value: 'asc', label: 'Cũ trước' },
              { value: 'desc', label: 'Mới trước' }
            ]}
            value={searchParams.get('orderBy') || 'desc'}
            onChange={onChangeOrderBy}
          />
        ]}
        onRow={(item, index) => {
          const rowClassesByIdx = index! % 2 === 0 ? 'row-dark' : 'row-light'
          return { ...item, index, rowClassesByIdx }
        }}
        emptyText={trans('orders.empty')}
        rowItemRender={renderRowItem}
        components={{
          body: {
            row: RowExtended
          }
        }}
        isHasExtendedRow
      />
    </DefaultLayout>
  )
}

export default Shipments
