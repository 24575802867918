import { Modal, ModalFuncProps, ModalProps } from 'antd'
import { FC } from 'react'

interface IProps extends ModalProps {}

export const ModalCommon: FC<IProps> = ({
  className = '',
  children,
  cancelText = 'Hủy bỏ',
  okText = 'Xác nhận',
  okButtonProps,
  cancelButtonProps,
  ...props
}) => {
  return (
    <Modal
      bodyStyle={{ overflowY: 'auto' }}
      closeIcon={<i className="fa-regular fa-xmark" />}
      centered
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{
        icon: <i className="fa-regular fa-check" />,
        type: 'primary',
        ...okButtonProps
      }}
      cancelButtonProps={{
        type: 'ghost',
        icon: <i className="fa-regular fa-xmark" />,
        ...cancelButtonProps
      }}
      {...props}>
      {children}
    </Modal>
  )
}

class ConfirmModal {
  static confirm({ okButtonProps, cancelButtonProps, title, ...props }: ModalFuncProps) {
    Modal.confirm({
      title: (
        <div className="relative">
          {title ?? 'Xác nhận'}
          <span className="absolute cursor-pointer top-0 right-2" onClick={() => Modal.destroyAll()}>
            <i className="fa-regular fa-xmark" />
          </span>
        </div>
      ),
      okButtonProps: {
        icon: <i className="fa-regular fa-check" />,
        type: 'primary',
        ...okButtonProps
      },
      okText: 'Xác nhận',
      cancelText: 'Hủy bỏ',
      centered: true,
      maskClosable: true,
      cancelButtonProps: {
        type: 'ghost',
        icon: <i className="fa-regular fa-xmark" />,
        ...cancelButtonProps
      },
      ...props
    })
  }
}

export const confirm = ConfirmModal.confirm
