import {  List } from 'antd'
import React from 'react'
import { trans } from '../../../resources/localization/Localization'
import { formatDateTime } from '../../../Util/string'
import HeaderList from './HeaderList'
import ProviderListInterface from '../../../Interface/ProviderListInterface'
interface ListProps {
	total: number
	// 	page: number
	// 	size: number
	data: Array<ProviderListInterface>
	// providers: Array<ProviderInterface>
	// statuses?: Array<StatusInterface>
	// 	filter: OrdersCriteria
	// 	loading: boolean
	// 	handleFilter: (filter: OrdersCriteria) => void
	// 	setPage: (val: number) => void
	// 	setPageSize: (val: number) => void
	loading: boolean
	isSkeleton: boolean
	handleAddProvider: (body: any) => void
	isModalOpen: boolean
	handleOk: () => void
	handleCancel: () => void
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const ProviderList: React.FC<ListProps> = (props) => {
	// const { xl, md } = useBreakpoint()
	const {
		data,
		// providers,
		// statuses,
		//    page,
		//     size,
		total,
		//   filter,
		//    handleFilter,
		//     setPage,
		// 	 setPageSize,
		loading,
		isSkeleton,
		handleAddProvider,
		handleCancel,
		handleOk,
		isModalOpen,
		setIsModalOpen,
	} = props

	return (
		<>
			{!isSkeleton && (
				<List
					className='shipments_items pd-16'
					pagination={false}
					header={
						<HeaderList
							total={total}
							handleAddProvider={handleAddProvider}
							handleCancel={handleCancel}
							handleOk={handleOk}
							isModalOpen={isModalOpen}
							setIsModalOpen={setIsModalOpen}
							isSkeleton={isSkeleton}
							isLoading={loading}
						/>
					}
					dataSource={data}
					renderItem={(item: ProviderListInterface, idx) => {
						const logoShow = item.logo ? item.logo.slice(0, 30).trim() + '...' : '---'
						return (
							<div className='provider-item gap-12 grid'>
								<div className='text-500 font-size-14'>{item.name}</div>
								<div className='flex justify-between '>
									<div className='txt-color-gray1 font-size-14'>{trans('provider.domain')}:</div>
									<div className='text-400 font-size-14 text-al-r txt-color-view'>{item.domain}</div>
								</div>
								<div className='flex justify-between '>
									<div className='txt-color-gray1 font-size-14'>{trans('provider.logo')}:</div>
									<div className='text-400 font-size-14 text-al-r txt-color-view'>{logoShow}</div>
								</div>
								<div className='flex  justify-between'>
									<div className='txt-color-gray1 font-size-14'>{trans('provider.code')}:</div>
									<div className='text-400 font-size-14 text-al-r'>{item.code}</div>
								</div>
								<div className='flex justify-between'>
									<div className='txt-color-gray1 font-size-14'>{trans('provider.create_by')}:</div>
									<div className='text-400 font-size-14 text-al-r'>{item.createdBy}</div>
								</div>
								<div className='flex justify-between'>
									<div className='txt-color-gray1 font-size-14 width-50'>
										{trans('provider.description')}:
									</div>
									<div className='text-400 font-size-14 text-al-r'>{item.description}</div>
								</div>

								<div className='flex justify-between'>
									<div className='txt-color-gray1 font-size-14'>{trans('provider.created_at')}:</div>
									<div className='text-400 font-size-14 text-al-r txt-color-gray1'>
										{formatDateTime(item.createdAt)}
									</div>
								</div>
							</div>
						)
					}}
				/>
			)}
			{/* {isSkeleton && <SkeletonOrders data={data} loading={isSkeleton} />} */}
		</>
	)
}

export default ProviderList

