import { Divider } from "antd";
import lodash from "lodash";
import React from "react";
import Skeleton from "react-loading-skeleton";
import TruncateText from "../../../Component/TruncateText";
import OrderDetailInterface, { ServiceInterface } from '../../../Interface/OrderDetailInterface'
import { trans } from '../../../resources/localization/Localization'
import { formatMoneyByUnit } from '../../../Util/string'
import Item from './Item'
interface InformationProps {
  details?: OrderDetailInterface
  currencyOfProvider: string
  expand: boolean
  loading: boolean
}

export const textFromData = (
  obj: { [id: string]: any },
  key: string,
  empty: string | Array<any>
) => {
  return lodash.isNil(lodash.get(obj, key)) ||
    lodash.isEmpty(lodash.get(obj, key))
    ? empty
    : lodash.get(obj, key);
};

const Information: React.FC<InformationProps> = ({
  details,
  currencyOfProvider,
  expand,
  loading,
}) => {
  const emptyTxt = "---";
  const employeeNote = textFromData(details!, "employeeNote", emptyTxt);
  const displayAddress = textFromData(details!, "address.display", emptyTxt);
  const listServices = textFromData(details!, "services", []);
  const exchangeRateStr = `${formatMoneyByUnit(
    1,
    lodash.get(details, "currency", emptyTxt)
  )}
                          = ${formatMoneyByUnit(
                            lodash.get(details, "exchangeRate", ""),
                            currencyOfProvider
                          )}`;
  const fullNameOfCustomer = textFromData(details!, "profile.name", emptyTxt);
  const usernameOfCustomer = textFromData(
    details!,
    "profile.username",
    emptyTxt
  );
  const depositRateStr = `${
    lodash.isNull(lodash.get(details, "emdPercent"))
      ? emptyTxt
      : lodash.get(details, "emdPercent", emptyTxt)
  }%`;
  const netWeight = lodash.isNil(lodash.get(details, "netWeight"))
    ? emptyTxt
    : `${lodash.get(details, "netWeight")} kg`;
  const packingWeight = lodash.isNil(lodash.get(details, "packagingWeight"))
    ? emptyTxt
    : `${lodash.get(details, "packagingWeight")} kg`;
  const dimensionalWeight = lodash.isNil(
    lodash.get(details, "dimensionalWeight")
  )
    ? emptyTxt
    : `${lodash.get(details, "dimensionalWeight")} kg`;
  const chargedWeight = lodash.isNil(lodash.get(details, "actualWeight"))
    ? emptyTxt
    : `${lodash.get(details, "actualWeight")} kg`;
    
    const volumetric = lodash.isNil(lodash.get(details, "volumetric"))
    ? emptyTxt
    : `${lodash.get(details, "volumetric")} m3`;
  return (
    <div className={`order-content__text-info pd-12 ${!expand ? "py-0" : ""}`}>
      {!loading ? (
        <h3>{trans("order.information")}</h3>
      ) : (
        <Skeleton width={120} height={22} />
      )}
      {expand && (
        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-8">
            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.receiver")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">
                  {lodash.get(details, "address.fullname", "---")}
                </span>
              }
              loading={loading}
              widthContent={80}
              widthLabel={60}
            />

            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.phoneNumber")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">
                  {lodash.get(details, "address.phone", "---")}
                </span>
              }
              widthContent={80}
              widthLabel={60}
              loading={loading}
            />
            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.address")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">
                  {lodash.get(details, "address.fullname", "---")},
                  {displayAddress}
                </span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />
          </div>

          <Divider type="horizontal" className="mg-0" />

          <div className="flex flex-col gap-8">
            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("orders.customer")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">
                  {usernameOfCustomer}({fullNameOfCustomer})
                </span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />
            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.depositRate")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">{depositRateStr}</span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />

            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.exchangeRate")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">{exchangeRateStr}</span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />
          </div>
          <Divider type="vertical" className="h-auto border-divider" />
          <div className="flex flex-col gap-8">
            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.chargedWeight")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">{chargedWeight}</span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />
            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.netWeight")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">{netWeight}</span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />

            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.dimensionalWeight")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">
                  {dimensionalWeight}
                </span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />

            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("order.packingWeight")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">{packingWeight}</span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />

            <Item
              label={
                <span className="txt-color-gray1 font-size-14">
                  {trans("Thể tích")}:
                </span>
              }
              content={
                <span className="text-400 font-size-14">{volumetric}</span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={"flex-grow-1"}
            />
            <Divider type="horizontal" className="mg-0" />
            <div className="flex flex-col gap-8">
              <Item
                label={
                  <span className="txt-color-gray1 flex-basis-pc-50 font-size-14">
                    {trans("order.services")}:
                  </span>
                }
                content={
                  listServices.length > 0
                    ? listServices.map(
                        (item: ServiceInterface, idx: number) => {
                          let colorService = "";
                          if (item.needApprove) {
                            if (
                              item.approved === null ||
                              item.approved === undefined
                            ) {
                              colorService = "services-pending";
                            } else if (item.approved === false) {
                              colorService = "services-reject";
                            }
                          }
                          return (
                            <span
                              key={idx}
                              className={`mg-r-4 ${colorService} font-size-14`}
                            >
                              {`${item.name} ${
                                idx !== listServices.length - 1 ? "|" : ""
                              }`}{" "}
                            </span>
                          );
                        }
                      )
                    : "---"
                }
                loading={loading}
                widthLabel={60}
                containerClassContent="flex-grow-1"
              />
            </div>
            <Divider type="horizontal" className="mg-0" />

            <div className="flex items-center">
              {!loading ? (
                <span className="txt-color-gray1 font-size-14 mg-r-4">{`${trans(
                  "order.noteForOrder"
                )}:`}</span>
              ) : (
                <Skeleton width={60} height={22} />
              )}
              {!loading ? (
                <span className="text-reg">
                  <TruncateText defaultValue={employeeNote} />
                </span>
              ) : (
                <Skeleton containerClassName="flex-grow-1" height={22} />
              )}
            </div>
          </div>
        </div>
        // </div>
      )}
    </div>
  );
};

export default Information;
