import { Avatar, Button } from 'antd'
import lodash from 'lodash'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { CategoryApi } from '../../../../Api/CategoryApi'
import PackageStatusInterface from '../../../../Interface/PackageStatusesInterface'
import { trans } from '../../../../resources/localization/Localization'
import { formatNumber } from '../../../../Util/string'
import TracksPackage from './components/TrackPackages'
import './styles.scss'
import { IPackage } from 'src/Interface/PackageInterface'
import { ColumnType } from 'antd/lib/table'
import { useMidmile } from 'src/hooks/fetch/useMidmile'
import { ShipmentDetailContext } from '../../ShipmentDetailContext'
import { confirm } from 'src/Component/basic/ModalCommon'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { useParams } from 'react-router-dom'
import IShipmentPackage, { IWaybill } from '../../type'
import { PERMISSION_SHIPMENTS_WAYBILL_UPDATE } from 'src/Config/permission'
import SecurityService from 'src/Util/SecurityService'
import clsx from 'clsx'
import { TableCommon } from 'src/Component/TableCommon'
import { IDelivery } from 'src/Api/DeliveryAPI/response'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'
import Skeleton from 'react-loading-skeleton'

interface OrderItemsProps {
  packages: IPackage[]
  setPackages: (packages: IPackage[]) => void
  loading: boolean
  waybillFromShipment: IWaybill[]
  fetchWaybills: () => void
}

const Packages: React.FC<OrderItemsProps> = (props) => {
  const [statuses, setStatuses] = useState<PackageStatusInterface[]>([])
  const { packages, loading, waybillFromShipment, fetchWaybills } = props
  const [expandedRows, setExpandedRows] = useState<Array<any>>([])
  const { midmileLogoMapping, midmileNameMapping } = useMidmile()
  const [selectedWaybill, setSelectedWaybill] = useState<string>() // waybillCode
  const params = useParams()

  const packageFiltered = useMemo(() => {
    if (!selectedWaybill) return packages

    const packageFiltered = packages.filter((p) => p.waybillCode === selectedWaybill)
    setExpandedRows(packageFiltered?.[0]?.code ? [packageFiltered[0].code] : [])
    return packageFiltered
  }, [packages, selectedWaybill])

  const { shipmentStatusObject } = useContext(ShipmentDetailContext)

  const canUpdateWaybill = SecurityService.can(PERMISSION_SHIPMENTS_WAYBILL_UPDATE)

  useEffect(() => {
    getStatuses()
  }, [])

  const getStatuses = () => {
    CategoryApi.getPackageStatuses().then((res) => {
      setStatuses(lodash.sortBy(res.data, 'position'))
    })
  }

  const deleteWaybillHandler = async (waybillCode: string) => {
    try {
      await ShipmentsApi.deleteWaybill(params.code!, waybillCode)
      NotificationCommon.success({ message: 'Xóa mã vận đơn thành công' })
      fetchWaybills()
    } catch (err: any) {
      if (err.response?.data?.title === 'shipment_status_not_waybill_updatable') {
        NotificationCommon.error({
          message: `Không thể xóa mã vận đơn khi đơn đang ở trạng thái ${shipmentStatusObject?.name}`
        })
      }
    }
  }

  function onDeleteWaybill(waybillCode: any): void {
    confirm({
      title: 'Xác nhận',
      content: 'Bạn có chắc chắn muốn xóa mã vận đơn này?',
      onOk: () => deleteWaybillHandler(waybillCode)
    })
  }

  const columnsPackages: ColumnType<any>[] = [
    {
      title: <>#</>,
      key: 'index',
      width: '1%',
      align: 'right',
      render: (_: string, record: IShipmentPackage, index: number) => (
        <>
          <span className="text-[#707070]">{String(index + 1).padStart(2, '0')}</span>
        </>
      )
    },
    {
      title: <>{trans('package.code')}/Mã vận đơn</>,
      key: 'code',
      dataIndex: 'code',
      width: '12%',
      render: (code: string, record: IShipmentPackage) => (
        <>
          <span className="font-medium">{code ?? '---'}</span>
          <br />
          <span className="text-secondary">{record.waybillCode ? '#' + record.waybillCode : '---'}</span>
        </>
      )
    },
    {
      title: (
        <>
          {trans('package.weight')}{' '}
          <TooltipCommon
            title={<span className="text-xs">{trans('package.weight') + ' ' + trans('package.noteForWeight')}</span>}>
            <i className="fa-regular fa-circle-info text-primary text-sm"></i>
          </TooltipCommon>
        </>
      ),
      key: 'info',
      width: '28%',
      align: 'right',
      render: (record: any) => {
        const emptyTxt = '---'

        const actualWeight = lodash.isNull(lodash.get(record, 'actualWeight'))
          ? emptyTxt
          : lodash.get(record, 'actualWeight')

        const netWeight = lodash.isNull(lodash.get(record, 'netWeight')) ? emptyTxt : lodash.get(record, 'netWeight')

        const packagingWeight = lodash.isNull(lodash.get(record, 'packagingWeight'))
          ? emptyTxt
          : lodash.get(record, 'packagingWeight')

        const dimensionalWeight = lodash.isNull(lodash.get(record, 'dimensionalWeight'))
          ? emptyTxt
          : lodash.get(record, 'dimensionalWeight')

        return (
          <span className="font-medium text-[#5a5a5a]">
            {actualWeight === 0 ? '---' : <span>{actualWeight}kg</span>} /{' '}
            {netWeight === 0 ? '---' : <span>{netWeight}kg</span>} /{' '}
            {packagingWeight === 0 ? '---' : <span>{packagingWeight}kg</span>} /{' '}
            {dimensionalWeight === 0 ? '---' : <span>{dimensionalWeight}kg</span>}
          </span>
        )
      }
    },
    {
      title: (
        <>
          {trans('package.details')} {trans('package.noteForDetail')}
        </>
      ),
      key: 'details',
      width: '14%',
      render: (record: any) => {
        const emptyTxt = '---'
        const length = lodash.isNull(lodash.get(record, 'length')) ? emptyTxt : lodash.get(record, 'length', emptyTxt)
        const width = lodash.isNull(lodash.get(record, 'width')) ? emptyTxt : lodash.get(record, 'width', emptyTxt)
        const height = lodash.isNull(lodash.get(record, 'height')) ? emptyTxt : lodash.get(record, 'height', emptyTxt)
        return (
          <span className={'text-[#5A5A5A] font-medium'}>{`${formatNumber(length)} x ${formatNumber(
            width
          )} x ${formatNumber(height)} (cm)`}</span>
        )
      }
    },
    {
      title: <>Vận chuyển Midmile</>,
      key: 'midmile',
      dataIndex: 'midmile',
      render: (midmile: IPackage['midmile']) =>
        midmile ? (
          <>
            <Avatar src={midmileLogoMapping[midmile]} size={24} className="inline-block" />{' '}
            <span>{midmileNameMapping[midmile]}</span>
          </>
        ) : (
          '---'
        )
    },
    {
      title: <>{trans('package.status')}</>,
      key: 'notice',
      dataIndex: 'status',
      width: '12%',
      align: 'right',
      render: (status: string) => {
        const statusObj = statuses.find(({ code }) => code === status)
        const statusName = lodash.get(statusObj, 'name', '---')
        return (
          <span
            className={`white-nowrap text-white rad-25 py-6 px-16 font-medium`}
            style={{
              backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
                ? '#09B2AA'
                : lodash.get(statusObj, 'color')
            }}>
            {statusName}
          </span>
        )
      }
    }
  ]

  return (
    <>
      <div className="flex gap-16 flex-wrap items-center pt-2">
        <span className="text-secondary text-xs pl-3">Mã vận đơn: </span>

        <WaybillItem active={!selectedWaybill} onClick={() => setSelectedWaybill(undefined)}>
          Tất cả
        </WaybillItem>

        {loading ? (
          <>
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={24} />
            <Skeleton width={100} height={24} />
          </>
        ) : (
          waybillFromShipment.map((waybill, index: number) => {
            return (
              <WaybillItem
                key={index}
                active={selectedWaybill === waybill.code}
                canUpdateWaybill={canUpdateWaybill}
                onClick={() => setSelectedWaybill(selectedWaybill === waybill.code ? undefined : waybill.code)}
                onDelete={() => onDeleteWaybill(waybill.code)}>
                {waybill.code}
              </WaybillItem>
            )
          })
        )}
      </div>

      <TableCommon
        columns={columnsPackages}
        loading={loading}
        dataSource={packageFiltered}
        noContainerStyle
        containerClassName="mt-4"
        emptyText={'Chưa có kiện nào'}
        rowKey={(row) => row.code}
        expandable={{
          expandedRowKeys: expandedRows,
          expandRowByClick: true,
          expandedRowRender: ({ code, milestones }: IDelivery) => {
            return (
              <TracksPackage
                milestones={milestones ?? []}
                total={milestones?.length ?? 0}
                statuses={statuses}
                code={code}
                index={0}
              />
            )
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <i className="fas fa-chevron-up text-xs" onClick={(e) => onExpand(record, e)} aria-hidden />
            ) : (
              <i className="fas fa-chevron-right text-xs" onClick={(e) => onExpand(record, e)} aria-hidden />
            ),
          onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows])
        }}
        scroll={{ x: 675 }}
        rowClassName={(record: IDelivery) => {
          return expandedRows?.includes(record.code) ? 'active-row cursor-pointer' : 'cursor-pointer'
        }}
      />
    </>
  )
}
export default Packages

const WaybillItem: FC<{
  active: boolean
  children: string
  canUpdateWaybill?: boolean
  onClick: () => void
  onDelete?: () => void
}> = ({ active, children, onClick, canUpdateWaybill, onDelete }) => {
  return (
    <span
      className={clsx(
        'inline-block font-medium text-xs rounded-full bg-filter-item-bg px-16 py-4 cursor-pointer hover:bg-filter-item-hover-bg',
        {
          'bg-primary hover:bg-primary text-white': active
        }
      )}
      onClick={onClick}>
      {children}
      {canUpdateWaybill && onDelete && (
        <Button
          type="text"
          danger
          disabled={!canUpdateWaybill}
          onClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
          className={clsx('ml-2 h-auto')}>
          <i
            className={clsx('fa-regular fa-trash text-icon-normal', {
              'hover:!text-error': !active,
              '!text-[#FFF]': active
            })}></i>
        </Button>
      )}
    </span>
  )
}
