import { AxiosResponse } from 'axios'
import { IPackage } from 'src/Interface/PackageInterface'
import apiClient from '../../Util/ApiClient'
import { IDelivery, IDeliveryStatus } from './response'
import { IGetDataFilter } from '../type'

export class DeliveryApi {
  static getList = (filter: IGetDataFilter): Promise<AxiosResponse<IDelivery[]>> => {
    return apiClient.get('/admin/delivery-requests', { params: filter })
  }

  static getStatuses = (): Promise<IDeliveryStatus[]> => {
    return apiClient.get('/categories/delivery-request-statuses').then((response) => {
      return response.data
    })
  }

  static getDeliveryRequestPackages = (code: IDelivery['code']): Promise<AxiosResponse<IPackage[]>> => {
    return apiClient.get(`/admin/delivery-requests/${code}/packages`)
  }
}
