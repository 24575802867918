import React, { useContext, useEffect, useState } from 'react'
import { Form, Button } from 'antd'
import OrderItemInterface from 'src/Interface/OrderItemInterface'
import { FloatLabel } from 'src/Component/basic/FloatLabel'
import { ModalCommon } from 'src/Component/basic/ModalCommon'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { ShipmentDetailContext } from '../../ShipmentDetailContext'
import { useParams } from 'react-router-dom'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { UploadImage } from 'src/Component/inputs/UploadImage'

interface EditProductModalProps {
  open?: boolean
  productToEdit?: OrderItemInterface | undefined
  onCancel: () => void
}

const CreateOrEditProductModal: React.FC<EditProductModalProps> = ({ open, productToEdit, ...props }) => {
  const { code: shipmentCode } = useParams()
  const { refreshProducts } = useContext(ShipmentDetailContext)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const [disabled, setDisabled] = useState(false)

  const onCancel = () => {
    form.resetFields()
    props.onCancel()
  }

  const createProduct = (values: OrderItemInterface) => {
    return ShipmentsApi.createProduct(shipmentCode as string, values)
  }

  const updateProduct = (values: any) => {
    return ShipmentsApi.updateProduct(shipmentCode as string, productToEdit?.code as string, values)
  }

  const onSave = (values: any) => {
    const requestApi = productToEdit ? updateProduct : createProduct
    setLoading(true)
    const payload: any = {
      ...values,
      productUrl: values.url,
      quantity: values.orderedQuantity,
      unitPrice: values.price,
      productImage: values.image?.[0]?.response || values.image?.[0]?.url
    }

    delete payload.url
    delete payload.orderedQuantity
    delete payload.price
    delete payload.image

    requestApi(payload)
      .then(() => {
        NotificationCommon.success({
          message: productToEdit ? 'Cập nhật sản phẩm thành công' : 'Thêm sản phẩm thành công'
        })
        refreshProducts()
        form.resetFields()
        props.onCancel()
      })
      .catch(NotificationCommon.notifyHttpError)
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (productToEdit) {
      form.setFieldsValue({
        ...productToEdit,
        image: productToEdit.image
          ? [{ uid: 'test', name: productToEdit?.name, url: productToEdit.image, status: 'done' }]
          : []
      })
    }
    setDisabled(true)
  }, [form, productToEdit])

  return (
    <ModalCommon
      open={open ?? !!productToEdit}
      destroyOnClose
      title={productToEdit ? 'Chỉnh sửa sản phẩm' : 'Thêm sản phẩm'}
      onCancel={onCancel}
      okButtonProps={{
        disabled: loading || disabled,
        loading
      }}
      bodyStyle={{ paddingTop: 0 }}
      width={678}
      cancelButtonProps={{
        disabled: loading
      }}
      onOk={() => form.validateFields().then(onSave)}>
      <Form
        form={form}
        className="overflow-hidden pt-2"
        onFinish={onSave}
        onValuesChange={(_, values) => {
          setDisabled(
            !values.url ||
              !values.orderedQuantity ||
              !values.price ||
              !values.name?.trim() ||
              !values.translatedName?.trim()
          )
        }}>
        <FloatLabel
          inputProps={{ autoFocus: true }}
          label="Link sản phẩm"
          name="url"
          rules={[
            { required: true, message: 'Vui lòng nhập link sản phẩm' },
            { type: 'url', message: 'Chưa đúng định dạng link' }
          ]}
        />

        <FloatLabel
          label="Số lượng"
          name="orderedQuantity"
          rules={[{ required: true, message: 'Vui lòng nhập số lượng' }]}
          type="number"
          inputNumberProps={{
            decimalLength: 0,
            min: 1
          }}
        />

        <FloatLabel
          label="Đơn giá (¥)"
          name="price"
          rules={[{ required: true, message: 'Vui lòng nhập đơn giá' }]}
          type="number"
          inputNumberProps={{
            min: 1
          }}
        />

        <FloatLabel
          label="Tên sản phẩm tiếng Trung"
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm tiếng Trung' }]}
        />

        <FloatLabel
          label="Tên sản phẩm tiếng Việt"
          name="translatedName"
          rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm tiếng Việt' }]}
        />

        <FloatLabel label="Tên shop Trung Quốc" name="merchantName" />

        <FloatLabel label="Địa chỉ shop Trung Quốc" name="merchantContact" />

        <UploadImage />

        <Form.Item hidden>
          <Button htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </ModalCommon>
  )
}

export default CreateOrEditProductModal
