import { FilterCommon, FilterCommonProps } from 'src/Component/TableCommon/Filter'
import DefaultLayout from '../../Component/Layout/Default'
import { TableCommon } from 'src/Component/TableCommon'
import { TransactionHistoryApi } from 'src/Api/TransactionHistoryAPI'
import { ColumnsType } from 'antd/lib/table'
import { ITransaction } from './type'
import { SupplierApi } from 'src/Api/SupplierApi'
import { useTransactionType } from 'src/hooks/fetch/useTransactionType'
import CustomerInterface from 'src/Interface/CustomerInterface'
import { useState } from 'react'
import { MobileRow } from './components/MobileRow'
import {
  renderCode,
  renderUsername,
  renderAmount,
  renderBalanceAfter,
  renderMemo,
  renderTypeName,
  renderTimestamp
} from './helpers/data-render'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'

const TransactionHistory = (props: any) => {
  const { transactionTypeMappingToName, getTransactionTypeOptions } = useTransactionType()
  const [currentAccountDiorCode, setCurrentAccountDiorCode] = useState<CustomerInterface[]>([])

  const firstItems: FilterCommonProps['firstItems'] = [
    {
      name: 'query',
      label: 'Mã giao dịch',
      type: 'text'
    },
    {
      name: 'username',
      label: 'Khách hàng',
      type: 'select',
      selectProps: {
        showSearch: true,
        fetchApi: SupplierApi.getCustomers,
        fieldNames: { value: 'username', label: 'name' },
        mapLabel: (item: CustomerInterface) => {
          return `${item.username} (${item.name})`
        },
        onOptionChanged: (data: CustomerInterface[]) => {
          setCurrentAccountDiorCode(data)
        }
      },
      autoSearch: true
    },
    {
      name: 'types',
      label: 'Loại giao dịch',
      type: 'select',
      options: getTransactionTypeOptions(),
      selectProps: { mode: 'multiple' }
    }
  ]

  const columns: ColumnsType<ITransaction> = [
    {
      title: 'Giao dịch',
      dataIndex: 'ledgeTx',
      width: 230,
      render: (code: ITransaction['ledgeTx'], { teller, source }: ITransaction) => (
        <>
          <div className="whitespace-nowrap">
            <span className="text-secondary2">Mã: </span>
            {renderCode(code)}
          </div>

          <div className="whitespace-nowrap mt-1.5">
            <span className="text-secondary2 ">Người thực hiện: </span>
            <span>{teller || '---'}</span>
          </div>

          <div className="whitespace-nowrap mt-1.5">
            <span className="text-secondary2">Nguồn tạo: </span>
            <span>{source || '---'}</span>
          </div>
        </>
      )
    },
    {
      title: 'Khách hàng',
      dataIndex: 'owner',
      width: '10%',
      render: (owner: ITransaction['owner'], row: ITransaction) => (
        <>
          {row.ownerName}
          <br />
          <span className="whitespace-nowrap">
            <span className="text-secondary2">Username: </span>
            {renderUsername(owner)}
          </span>
        </>
      )
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      width: '10%',
      render: renderAmount,
      align: 'right'
    },
    {
      title: 'Số dư tài chính',
      dataIndex: 'balanceAfter',
      width: '10%',
      render: renderBalanceAfter,
      align: 'right'
    },
    {
      title: 'Nội dung giao dịch',
      width: '40%',
      render: ({ note, ...row }: ITransaction) => (
        <>
          {renderMemo(row.memo)}
          <div className="line-clamp-3 mt-1.5 text-secondary2">
            <TooltipCommon title={note && note?.length > 50 ? note : ''}>(Ghi chú: {note || '---'})</TooltipCommon>
          </div>
        </>
      )
    },
    {
      title: 'Loại giao dịch',
      width: '10%',
      render: ({ actualTimestamp, type }: ITransaction) => {
        return (
          <>
            {renderTypeName(type, transactionTypeMappingToName)}
            <br />
            {renderTimestamp(actualTimestamp)}
          </>
        )
      },
      align: 'right'
    }
  ]

  return (
    <DefaultLayout title={'Lịch sử giao dịch'} {...props}>
      <FilterCommon firstItems={firstItems} secondItems={secondItems} />

      <TableCommon
        queryKey="transaction-history-list"
        tableTitle={'Lịch sử giao dịch'}
        filterItems={[...firstItems, ...secondItems]}
        containerClassName="mt-3"
        columns={columns}
        fetchApiService={(params) => {
          if (params.username && currentAccountDiorCode) {
            params.account = currentAccountDiorCode.find((i) => i.username === params.username)?.diorAccount as string
          }

          return TransactionHistoryApi.get(params)
        }}
        mobileRowRender={(row: ITransaction) => (
          <MobileRow transaction={row} transactionTypeMappingToName={transactionTypeMappingToName} />
        )}
      />
    </DefaultLayout>
  )
}

export default TransactionHistory

const secondItems: FilterCommonProps['firstItems'] = [
  {
    name: 'timestame',
    label: ['Thời gian từ ngày', 'Đến ngày'],
    type: 'date-range',
    fieldName: ['nominalTimestampFrom', 'nominalTimestampTo']
  }
]
