import { Avatar } from 'antd'
import { IMidMile } from '../../Interface/ShipmentInterface'
import { FC } from 'react'
import { SHIPMENT_VIEW_MIDMILE_PARTNER } from '../../Config/permission'
import SecurityService from '../../Util/SecurityService'

interface IProps {
  midMileView?: IMidMile[]
  logo?: string
  name?: string
}

export const MidmileView: FC<IProps> = ({ midMileView, logo, name }) => {
  if (!SecurityService.can(SHIPMENT_VIEW_MIDMILE_PARTNER)) return null

  if (!midMileView?.[0]?.name && name) return <span>---</span>
  
  return (
    <div className="flex gap-4 items-center">
      <Avatar src={midMileView?.[0]?.logo ?? logo} size={24} className="mg-r-2" />
      <span>{midMileView?.[0]?.name ?? name}</span>
    </div>
  )
}
