import { RedoOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd'
import lodash, { identity, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SupplierApi } from '../../Api/SupplierApi'
import ConnectionBar from '../../Component/ConnectionBar'
import { OrdersCriteria, OrdersCriteriaDefault } from '../../Criteria/OrdersCriteria'
import ConnectionInterface from '../../Interface/ConnectionInterface'
import MarketPalaceInterface from '../../Interface/MarketPalaceInterface'
import ProfileInterface from '../../Interface/ProfileInterface'
import StatusInterface from '../../Interface/StatusInterface'
import { trans } from '../../resources/localization/Localization'
import { SELECT_CONNECTION, SELECT_PROFILE, TIME_STAMP_FROM, TIME_STAMP_TO } from '../../Util/Constants'
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
const beginDateFormat = 'YYYY-MM-DD HH:mm:00'
const endDateFormat = 'YYYY-MM-DD HH:mm:59'
interface FilterProps {
	statuses?: Array<StatusInterface>
	marketPalaces?: Array<MarketPalaceInterface>
	filter: OrdersCriteria
	loadingStatuses: boolean
	loadingMarkets: boolean
	orderBy?: 'asc' | 'desc'
	setFilter: (obj: OrdersCriteria | {}) => void
	handleFilter: (params: OrdersCriteria) => void
}
const Filter: React.FC<FilterProps> = (props) => {
	const { statuses, marketPalaces, handleFilter, setFilter, orderBy, filter } = props
	const [form] = Form.useForm()
	const [searchParams] = useSearchParams()
	const [checkedStatuses, setCheckedStatuses] = useState<string[]>(searchParams.get('statuses')?.split(',') || [])
	const [checkedMarketPalaces, setCheckedMarketPalaces] = useState<string[]>(searchParams.get('marketplaces')?.split(',') || [])
	const [showFilter, setShowFilter] = useState(true)
	const [usernameSelected, setUsernameSelected] = useState<string | undefined>()
	const [connectionSelected, setConnectionSelected] = useState<string | undefined>()
	const [connectionsFilter, setConnectionsFilter] = useState<Array<ConnectionInterface>>([])
	const [profilesFiltered, setProfilesFiltered] = useState<Array<ProfileInterface>>([])
	const [searchExpand, setSearchExpand] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			if (searchParams.get('providerUsername') && searchParams.get('providerUsername')!.length >= 3) {
				const response = await SupplierApi.getAllConnections(searchParams.get('providerUsername')!)
				const conn = response.data.find(
					(conn: ConnectionInterface) =>
						conn.providerUsername === searchParams.get('providerUsername') &&
						conn.provider.code === searchParams.get('provider')
				)
				if (conn) setConnectionSelected(conn.id)
				setConnectionsFilter(response.data)
			}
			if (searchParams.get('username') && searchParams.get('username')!.length >= 3) {
				const response = await SupplierApi.getProfiles(searchParams.get('username')!)
				const profile = response.data.find(
					(profile: ProfileInterface) => profile.username === searchParams.get('username')
				)
				if (profile) setUsernameSelected(profile.id)
				setProfilesFiltered(response.data)
			}
		}
		fetchData()
	}, [searchParams])

	const handleChangeStatuses = (key: string, selected: boolean) => {
		if (selected) {
			setCheckedStatuses([...checkedStatuses, key])
			setFilter((prevState: OrdersCriteria) => ({
				...prevState,
				statuses: [...checkedStatuses, key].join(),
			}))
		} else {
			setCheckedStatuses(checkedStatuses.filter((stt: string) => stt !== key))
			setFilter((prevState: OrdersCriteria) => ({
				...prevState,
				statuses: [...checkedStatuses.filter((stt: string) => stt !== key)].join(),
			}))
		}
	}

	const onFinish = () => {
		handleFilter({ ...lodash.pickBy(filter, (val) => !isEmpty(val) && identity(val)) })
	}

	const clearFilter = () => {
		setCheckedStatuses([])
		setCheckedMarketPalaces([])
		setConnectionsFilter([])
		setProfilesFiltered([])
		setUsernameSelected(undefined)
		setConnectionSelected(undefined)
		const clearValues = lodash.pickBy(OrdersCriteriaDefault, (val) => !isEmpty(val) && identity(val))
		handleFilter({...clearValues, orderBy})
	}

	const handleChangeMarketplaces = (code: string, checked: boolean) => {
		if (checked) {
			setCheckedMarketPalaces([...checkedMarketPalaces, code])
			setFilter((prevState: OrdersCriteria) => ({
				...prevState,
				marketplaces: [...checkedMarketPalaces, code].join(),
			}))
		} else {
			setCheckedMarketPalaces(checkedMarketPalaces.filter((stt: string) => stt !== code))
			setFilter((prevState: OrdersCriteria) => ({
				...prevState,
				marketplaces: [...checkedMarketPalaces.filter((stt: string) => stt !== code)],
			}))
		}
	}

	const handleChangeValue = (key: string, value: string) => {
		if (key === SELECT_PROFILE) {
			setUsernameSelected(value)
			const username = lodash.get(
				profilesFiltered?.find((x: ProfileInterface) => x.id === value),
				'username'
			)
			setFilter({ ...filter, username })
		}
		if (key === SELECT_CONNECTION) {
			const connection = connectionsFilter?.find((x: ConnectionInterface) => x.id === value)
			const providerUsername = lodash.get(connection, 'providerUsername')
			const provider = lodash.get(connection, 'provider.code')
			setConnectionSelected(value)
			setFilter({ ...filter, provider, providerUsername })
		}
	}

	const handleAutoComplete = async (key: string, value: string | null) => {
		if (value && value.length >= 3) {
			try {
				if (key === SELECT_CONNECTION) {
					const response = await SupplierApi.getAllConnections(value)
					setConnectionsFilter(response.data)
				}
				if (key === SELECT_PROFILE) {
					const response = await SupplierApi.getProfiles(value)
					setProfilesFiltered(response.data)
				}
			} catch (err) {}
		} else {
			if (key === SELECT_CONNECTION) {
				setConnectionsFilter([])
			}
			if (key === SELECT_PROFILE) {
				setProfilesFiltered([])
			}
		}
	}

	const handleChangeDate = (dates: any, dateString: string[]) => {
		setFilter({
			...filter,
			[TIME_STAMP_FROM]: dates ? moment(dates[0].format(beginDateFormat)).toISOString() : null,
			[TIME_STAMP_TO]: dates ? moment(dates[1].format(endDateFormat)).toISOString() : null,
		})
	}

	const handleClear = (key: string) => {
		if (key === SELECT_CONNECTION) {
			setFilter({ ...lodash.omit(filter, ['provider', 'providerUsername']) })
			setConnectionsFilter([])
		}
		if (key === SELECT_PROFILE) {
			setFilter({ ...lodash.omit(filter, ['username']) })
			setProfilesFiltered([])
		}
	}
	return (
		<Row className='orders-filter mg-0 mg-bt-12'>
			<Row className={`orders-filter__title ${showFilter ? 'mg-bt-20' : ''}`} align='middle'>
				<Col className='title'>
					<h3>{trans('orders.search')}</h3>
				</Col>
				<Col className='divider'>
					<Divider className='mg-0' />
				</Col>
				<Col>
					<i
						className={`cursor-pointer fa-solid fa-angle-${showFilter ? 'up' : 'down'}`}
						onClick={() => setShowFilter(!showFilter)}
					/>
				</Col>
			</Row>
			{showFilter && (
				<>
					<div className={`orders-filter__statuses mg-bt-20`}>
						<span className='orders-filter__statuses-label'>{`${trans('orders.status')}:`}</span>
						 {statuses?.map(({ code, name }: StatusInterface) => {
									const selected = checkedStatuses && checkedStatuses.includes(code) ? true : false
									return (
										<span
											className={selected ? 'status active' : 'status'}
											key={code}
											onClick={() => {
												handleChangeStatuses(code, !selected)
											}}>
											{name}
										</span>
									)
							  })}
					</div>
					{searchExpand && (
						<>
							<Form name='order-filter' onFinish={onFinish} className='orders-filter__form' form={form}>
								<Row gutter={[32, 10]} align='middle' className='orders-filter__form-input'>
									<Col xl={4} md={12} sm={24} xs={24}>
										<Item
											label={<span className={'form-label'}>{trans('orders.code')}:</span>}
											labelAlign='left'
											colon={false}>
											<Input
												placeholder={trans('orders.code')}
												onChange={(e) =>
													setFilter({
														...filter,
														providerOrderCode: e.target.value,
													})
												}
												value={filter.providerOrderCode ? filter.providerOrderCode : ''}
												allowClear
											/>
										</Item>
									</Col>
									<Col xl={7} md={12} sm={24} xs={24} className='orders-filter__form-input--date'>
										<Item
											labelAlign='left'
											label={<span className='form-label'>{`${trans('orders.createdAt')}:`}</span>}
											colon={false}>
											<RangePicker
												value={[
													filter.timestampTo ? moment(filter.timestampTo) : null,
													filter.timestampFrom ? moment(filter.timestampFrom) : null,
												]}
												placeholder={[
													trans('orders.createdAtPlaceholder'),
													trans('orders.createdAtPlaceholder'),
												]}
												format={'DD-MM-YYYY HH:mm'}
												suffixIcon={<i className='far fa-calendar-alt' />}
												onChange={(values: any, dateString: string[]) =>
													handleChangeDate(values, dateString)
												}
												showTime
												className='width100pc'
											/>
										</Item>
									</Col>
									<Col xl={7} md={12} sm={24} xs={24} className='flex-shrink-none'>
										<Item
											labelAlign='left'
											label={<span className={'form-label'}>{trans('orders.supplierAccount')}:</span>}
											// className='selection'
											colon={false}>
											<Select
												value={connectionSelected}
												placeholder={trans('orders.supplierAccountPlaceholder')}
												allowClear={true}
												showSearch
												filterOption={false}
												onSearch={lodash.debounce(
													(val) => handleAutoComplete(SELECT_CONNECTION, val),
													1000
												)}
												onChange={(value) => {
													handleChangeValue(SELECT_CONNECTION, value)
												}}
												onClear={() => handleClear(SELECT_CONNECTION)}
												// dropdownStyle={{ minWidth: '250px' }}
												notFoundContent={null}>
												{connectionsFilter?.map((conn: ConnectionInterface) => (
													<Option key={conn.id} value={conn.id}>
														<ConnectionBar
															divider='|'
															inFilter
															sizeImg={15}
															logo={conn.provider.logo}
															providerName={conn.provider.name}
															username={conn.providerUsername}
															className='font-size-12'
														/>
													</Option>
												))}
											</Select>
										</Item>
									</Col>
									<Col xl={6} md={12} sm={24} xs={24}>
										<Item
											labelAlign='left'
											label={<span className={'form-label'}>{trans('orders.customer')}:</span>}
											// className='selection'
											colon={false}>
											<Select
												value={usernameSelected}
												placeholder={trans('orders.customerPlaceholder')}
												allowClear={true}
												showSearch
												filterOption={false}
												onSearch={lodash.debounce((val) => handleAutoComplete(SELECT_PROFILE, val), 1000)}
												onChange={(value) => {
													handleChangeValue(SELECT_PROFILE, value)
												}}
												onClear={() => handleClear(SELECT_PROFILE)}
												style={{ width: '100%' }}
												notFoundContent={null}>
												{profilesFiltered?.map((profile: ProfileInterface) => (
													<Option key={profile.id} value={profile.id}>{`${lodash.get(
														profile,
														'name'
													)} (${lodash.get(profile, 'username')})`}</Option>
												))}
											</Select>
										</Item>
									</Col>
								</Row>
							</Form>
							<Row className='flex mg-bt-20 marketplaces' gutter={6}>
								<Col span={24}>
									<span className='label mg-r-22'>{`${trans('orders.source')}:`}</span>
									{marketPalaces &&
										marketPalaces.length > 0 &&
										marketPalaces.map(({ id, code, name }: MarketPalaceInterface) => {
											const selected =
												checkedMarketPalaces && checkedMarketPalaces.includes(code) ? true : false
											return (
												<Checkbox
													key={id}
													onChange={(e) => handleChangeMarketplaces(code, e.target.checked)}
													checked={selected}>
													{name}
												</Checkbox>
											)
										})}
								</Col>
							</Row>
						</>
					)}
					<Row align='middle' justify='space-between' className='actions'>
						<Col span={12}>
							<span className='text cursor-pointer mg-r-7' onClick={() => setSearchExpand(!searchExpand)}>{`${
								searchExpand ? trans('orders.filterCollapse') : trans('orders.filterOpen')
							}`}</span>
							<i
								className={`cursor-pointer fa-solid fa-angle-${searchExpand ? 'up' : 'down'}`}
								onClick={() => setSearchExpand(!searchExpand)}
							/>
						</Col>
						{showFilter && (
							<Col span={12} className={'actions-btn'}>
								{!lodash.isEmpty(lodash.pickBy(filter, (val: any) => !isEmpty(val) && identity(val))) && (
									<Button className='clear' onClick={clearFilter} icon={<RedoOutlined />}>
										<span className='mg-0'>{trans('orders.cleanFilter')}</span>
									</Button>
								)}
								<Button className='btn btn-primary' type='primary' htmlType='submit' onClick={onFinish} form='order-filter'>
									{trans('orders.search')}
								</Button>
							</Col>
						)}
					</Row>
				</>
			)}
		</Row>
	)
}

export default Filter

