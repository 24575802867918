import clsx from 'clsx'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

interface MobileViewProps {
  loading: boolean
  tableData: any[]
  title: () => JSX.Element
  mobileRowRender: (item: any, index: number) => JSX.Element
}

export const MobileView: FC<MobileViewProps> = ({ mobileRowRender, tableData, title, loading }) => {
  return (
    <>
      <div className={clsx('px-12 py-20', { 'opacity-60': loading })}>{title()}</div>

      <div className="flex flex-col gap-12">
        {loading
          ? new Array(5).fill(0).map((_, index) => (
              <div
                key={index}
                className={clsx('p-4 rounded-xl flex flex-col gap-8', {
                  'bg-[#F8F8F8]': index % 2
                })}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            ))
          : tableData?.map((item: any, index: number) => (
              <div
                key={item.id || item.code || index}
                className={clsx('p-4 rounded-xl hover:bg-[#F8F8F8]', {
                  'bg-[#F8F8F8]': index % 2
                })}>
                {mobileRowRender(item, index)}
              </div>
            ))}
      </div>
    </>
  )
}
