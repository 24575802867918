import { Avatar, Dropdown, MenuProps } from 'antd'
import lodash from 'lodash'
import React, { useContext, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  PERMISSION_SHIPMENTS_UPDATE_STATUS,
  PERMISSION_SHIPMENT_LOG_VIEW,
  SHIPMENT_UPDATE_MIDMILE_PARTNER,
  SHIPMENT_VIEW_MIDMILE_PARTNER
} from '../../../Config/permission'
import ShipmentDetailInterface from '../../../Interface/ShipmentDetailInterface'
import SecurityService from '../../../Util/SecurityService'
import useWindowDimensions from '../../../Util/dimention'
import { formatMoneyByUnit } from '../../../Util/string'
import IMAGE_DEFAULT from 'src/resources/images/empty-image.jpg'
import { trans } from '../../../resources/localization/Localization'
import { MidmileView } from '../../../Component/views/MidmileView'
import { ShipmentsApi } from '../../../Api/ShipmentsApi'
import { NotificationCommon } from '../../../Component/basic/NotificationCommon'
import ParagraphWithCopy from 'src/Component/inputs/ParagraphWithCopyButton'
import { IMidMile } from 'src/Interface/ShipmentInterface'
import { Status } from 'src/Component/views/status'
import IShipmentStatus from 'src/Interface/StatusInterface'
import { confirm } from 'src/Component/basic/ModalCommon'
import { LogView } from 'src/Component/views/LogView'
import { LogListItem } from './LogListItem'
import { usePackageStatus } from 'src/hooks/fetch/usePackageStatus'
import { useMidmile } from 'src/hooks/fetch/useMidmile'
import { ShipmentDetailContext } from '../ShipmentDetailContext'

interface HeadingProps {
  details?: ShipmentDetailInterface
  loading: boolean
  statuses: IShipmentStatus[]
  statusColorMapping: { [key in string]: string }
  statusNameMapping: { [key in string]: string }
  midmileList: IMidMile[]
}

const Heading: React.FC<HeadingProps> = ({
  details,
  loading,
  statuses,
  statusColorMapping,
  statusNameMapping,
  midmileList
}) => {
  const [codeSelectedForViewLog, setCodeSelectedForViewLog] = useState<string>()

  const { packageStatusesNameMapping } = usePackageStatus()
  const { midmileNameMapping } = useMidmile()
  const { refreshDetails } = useContext(ShipmentDetailContext)
  const midmileCode = details?.midMileViews?.[0]?.code

  const { width } = useWindowDimensions()

  const isCanViewMidmile = useMemo(() => {
    return SecurityService.can(SHIPMENT_VIEW_MIDMILE_PARTNER)
  }, [])

  const isCanChangeMidmile = useMemo(() => {
    return SecurityService.can(SHIPMENT_UPDATE_MIDMILE_PARTNER)
  }, [])

  const isCanChangeStatus = useMemo(() => {
    return SecurityService.can(PERMISSION_SHIPMENTS_UPDATE_STATUS)
  }, [])

  const onChangeMidmile = ({ selectedKeys }: { selectedKeys: string[] }) => {
    if (!details) return

    ShipmentsApi.updateMidmile(details.code, [...selectedKeys])
      .then(() => {
        NotificationCommon.success({ message: 'Thay đổi đối tác vận chuyển midmile thành công' })
        refreshDetails()
      })
      .catch(NotificationCommon.notifyHttpError)
  }

  const updateShipmentStatus = (status: string) => {
    if (!details) return

    ShipmentsApi.updateStatus(details.code, status)
      .then(() => {
        NotificationCommon.success({ message: 'Thay đổi trạng thái đơn hàng thành công' })
        refreshDetails(true)
      })
      .catch(NotificationCommon.notifyHttpError)
  }

  const onChangeShipmentStatus = ({ selectedKeys }: { selectedKeys: string[] }) => {
    confirm({
      content: 'Bạn có chắc chắn muốn thay đổi trạng thái đơn hàng?',
      onOk: () => {
        updateShipmentStatus(selectedKeys[0])
      }
    })
  }

  const midmileItems: MenuProps['items'] = useMemo(
    () =>
      midmileList.map((midmile) => ({
        key: midmile.code,
        label: <MidmileView midMileView={[midmile]} />
      })),
    [midmileList]
  )

  const shipmentStatusItems: MenuProps['items'] = useMemo(
    () =>
      statuses.map((status) => ({
        key: status.code,
        label: status.name
      })),
    [statuses]
  )

  const renderMidMile = () => {
    if (!isCanViewMidmile) return null

    return (
      <div className="flex gap-4 items-center">
        <span className="txt-color-gray mg-r-4 font-size-14">Vận chuyển Midmile:</span>
        {isCanChangeMidmile ? (
          <Dropdown
            menu={{
              items: midmileItems,
              selectable: true,
              defaultSelectedKeys: midmileCode ? [midmileCode] : undefined,
              onSelect: onChangeMidmile
            }}
            getPopupContainer={(trigger) => trigger.parentNode as any}
            trigger={['click']}>
            <span className="inline-flex items-center gap-4 cursor-pointer">
              <MidmileView midMileView={details?.midMileViews} />{' '}
              <i className="fa-regular fa-pen-to-square text-primary"></i>
            </span>
          </Dropdown>
        ) : (
          <span>
            <MidmileView midMileView={details?.midMileViews} />
          </span>
        )}
      </div>
    )
  }

  const renderCodeAndStatus = () => {
    if (loading) {
      return (
        <>
          <Skeleton width={120} height={24} /> <Skeleton width={80} height={24} />
        </>
      )
    }

    if (!details) return null

    return (
      <>
        <ParagraphWithCopy text={details?.code} className="mg-bt-0 text-violet text-md text-500  text-nowrap">
          #{details?.code}
        </ParagraphWithCopy>

        <div>
          {isCanChangeStatus ? (
            <Dropdown
              menu={{
                items: shipmentStatusItems,
                selectable: true,
                selectedKeys: details.status ? [details.status] : undefined,
                onSelect: onChangeShipmentStatus
              }}
              getPopupContainer={(trigger) => trigger.parentNode as any}
              trigger={['click']}>
              <span className="inline-flex items-center gap-4 cursor-pointer">
                <Status
                  backgroundColor={statusColorMapping[details.status]}
                  status={statusNameMapping[details.status] ?? details?.status}
                  className="mr-1"
                />
                <i className="fa-regular fa-pen-to-square text-primary"></i>
              </span>
            </Dropdown>
          ) : (
            <Status
              backgroundColor={statusColorMapping[details.status]}
              status={statusNameMapping[details.status] ?? details?.status}
            />
          )}
        </div>
      </>
    )
  }

  const renderAvatar = () =>
    !loading ? (
      <Avatar
        size={60}
        shape="square"
        className="mg-r-16 rad-6 shipment-heading__basic-image flex-shrink-none"
        src={details?.image ? lodash.get(details, 'image', '') : IMAGE_DEFAULT}
      />
    ) : (
      <Skeleton width={60} height={60} className="mg-r-8" />
    )

  const renderMoney = () => {
    return (
      <>
        {!loading ? (
          <span className="txt-color-gray">
            {trans('shipments.costTotal')}:{' '}
            <span className="text-500 text-[#1a1a1a]  font-size-14">
              {formatMoneyByUnit(lodash.identity(details?.totalFee) ? details?.totalFee : '---')}
            </span>
          </span>
        ) : (
          <Skeleton width={120} height={24} className="mg-r-3" />
        )}

        {!loading ? (
          <span className="txt-color-gray">
            {trans('shipments.totalAmount')}:{' '}
            <span className="text-[#1a1a1a] font-medium">{formatMoneyByUnit(details?.totalValue, 'CNY')}</span>
          </span>
        ) : (
          <Skeleton width={120} height={24} className="mg-r-3" />
        )}
      </>
    )
  }

  const renderLogs = () => {
    return (
      SecurityService.can(PERMISSION_SHIPMENT_LOG_VIEW) && (
        <>
          {!loading ? (
            <span
              className="cursor-pointer text-primary"
              onClick={() => {
                console.log('click')
                setCodeSelectedForViewLog(details?.code)
              }}>
              <i className="far fa-file-alt mg-r-4" />
              Logs
            </span>
          ) : (
            <Skeleton width={113} height={20} />
          )}
        </>
      )
    )
  }

  return (
    <>
      {width < 768 ? (
        <>
          <div className="shipment-heading grid gap-4">
            <div className="flex align-items-center pb-3">
              {renderAvatar()}
              <div className="flex flex-col gap-6">{renderCodeAndStatus()}</div>
            </div>

            {renderMidMile()}
            {renderMoney()}
            {renderLogs()}
          </div>
        </>
      ) : (
        <div className="shipment-heading width100pc ">
          {renderAvatar()}
          <div className="items-center grid gap-8 w-full" style={{ gridTemplateColumns: 'auto auto auto auto' }}>
            <div className="grid content-center gap-4">{renderCodeAndStatus()}</div>

            <div className="grid content-center gap-4">{renderMoney()}</div>

            <div className="grid content-center gap-4">
              {renderMidMile()}
              {renderLogs()}
            </div>
          </div>
        </div>
      )}

      <LogView
        code={codeSelectedForViewLog}
        onCancel={() => setCodeSelectedForViewLog(undefined)}
        fetchLogs={ShipmentsApi.getLogs}
        title="Log đơn ký gửi"
        ListItemContent={(p) => (
          <LogListItem
            {...p}
            shipmentStatusNameMapping={statusNameMapping}
            packageStatusesNameMapping={packageStatusesNameMapping}
            midmileNameMapping={midmileNameMapping}
          />
        )}
      />
    </>
  )
}

export default Heading
