import React, { FC, memo } from 'react'
import { Table, Skeleton, type TableProps } from 'antd'

import './style.scss'

interface IProps extends TableProps<any> {
  skeletonRow?: number
}

const SkeletonTable: FC<IProps> = memo(function skeletonTable({ columns = [], skeletonRow = 12, title }: IProps) {
  return (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(skeletonRow)].map((_, index) => ({
        key: `key${index}`
      }))}
      style={{ marginTop: title ? 64 : 0 }}
      className="skeleton-table"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns={columns?.map((column: any) => {
        return {
          ...column,
          render: () => {
            return (
              <div className={`skeleton-${column.align}`}>
                <Skeleton key={column.dataIndex} title={false} paragraph={{ rows: 1 }} active />
              </div>
            )
          }
        }
      })}
    />
  )
})

export default SkeletonTable
