import { Button, Form } from 'antd'
import { FC, useState } from 'react'
import { FloatLabel } from 'src/Component/basic/FloatLabel'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { CustomerApi } from 'src/Api/CustomerAPI/index.service'
import { ModalCommon } from 'src/Component/basic/ModalCommon'
import localStore from 'src/Util/LocalStore'
import { useParams } from 'react-router-dom'
import { ICreateDepositPayload } from 'src/Api/CustomerAPI/request'

type FieldType = ICreateDepositPayload

interface CreateDepositModalProps {
  open: boolean
  onCancel: () => void
  onSuccess: () => void
}

export const CreateDepositModal: FC<CreateDepositModalProps> = ({ open, onCancel, onSuccess }) => {
  const { username } = useParams()

  const [isDisableSubmit, setIsDisable] = useState(true)
  const [form] = Form.useForm<FieldType>()
  const [isLoading, setIsLoading] = useState(false)
  const loggedUser = localStore.getJson('loggedUser')

  const onFinish = (values: FieldType) => {
    if (isLoading) return
    setIsLoading(true)
    CustomerApi.createDeposit(username!, {
      ...values,
      amount: Number(values.amount),
      teller: loggedUser.username
    })
      .then(() => {
        onSuccess()
        NotificationCommon.success({
          message: <span>Tạo giao dịch nạp tiền thành công.</span>
        })
        form.resetFields()
        onCancel()
      })
      .catch(NotificationCommon.notifyHttpError)
      .finally(() => {
        setIsDisable(true)
        setIsLoading(false)
      })
  }

  return (
    <ModalCommon
      open={open}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      title="Tạo giao dịch nạp tiền"
      destroyOnClose
      onOk={() => form.validateFields().then((values) => onFinish(values))}
      okButtonProps={{
        disabled: isDisableSubmit,
        loading: isLoading
      }}>
      <Form
        form={form}
        onValuesChange={() => {
          const values = form.getFieldsValue()
          setIsDisable(!values.memo || !String(values.amount ?? ''))
        }}
        onFinish={onFinish}
        className="flex flex-col gap-4">
        <FloatLabel
          form={form}
          name="amount"
          label={<span>Số tiền cần nạp</span>}
          required
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số tiền cần nạp'
            },
            () => ({
              validator(_, value: string) {
                if (value.length > 0 && Number(value) === 0) {
                  return Promise.reject(new Error('Số tiền cần nạp phải khác 0'))
                }
                return Promise.resolve()
              }
            })
          ]}
          inputNumberProps={{
            autoFocus: true,
            suffix: 'đ',
            decimalLength: 0,
            disabled: isLoading
          }}
          type="number"
        />

        <FloatLabel
          form={form}
          name="memo"
          label="Lý do"
          required
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập lý do'
            },
            () => ({
              validator(_, value: string) {
                if (value.length > 0 && value.trim().length < 1) {
                  return Promise.reject(new Error('Lý do không được chứa toàn khoảng trắng'))
                }
                return Promise.resolve()
              }
            })
          ]}
          inputProps={{
            disabled: isLoading
          }}
        />

        <FloatLabel
          form={form}
          name="note"
          label="Ghi chú"
          type="textare"
          inputProps={{
            disabled: isLoading
          }}
          className="!mb-0"
        />

        <Form.Item hidden>
          <Button htmlType="submit" />
        </Form.Item>
      </Form>
    </ModalCommon>
  )
}
