import { Avatar } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { TooltipCommon } from '../basic/TooltipCommon'
interface BarProps {
  logo?: string
  providerName?: string
  code?: string
  username?: string
  sizeImg: number
  divider: string
  inFilter: boolean
  className?: string
  isRedirect?: boolean
  isShowProviderTitle?: boolean
}
const ConnectionBar: React.FC<BarProps> = (props) => {
  const { logo, providerName, code, username, sizeImg, className, divider, inFilter, isRedirect, isShowProviderTitle } =
    props
  return (
    <div className={`flex items-center flex-nowrap gap-4 mg-0 ${className ? className : ''}`}>
      <span>
        {isRedirect ? (
          <Link to={`/customers/${username}`} target="_blank">
            {username}
          </Link>
        ) : (
          username
        )}
      </span>
      <span>{divider}</span>
      {inFilter && <span>{divider}</span>}
      <Avatar src={logo} size={sizeImg} shape="circle" />

      <TooltipCommon title={isShowProviderTitle && 'Nhà cung cấp'}>
        <span style={{ whiteSpace: 'nowrap' }}>{providerName ?? code}</span>
      </TooltipCommon>
    </div>
  )
}

export default ConnectionBar
