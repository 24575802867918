import { Table } from 'antd'
import { ColumnType } from 'antd/lib/table/interface'
import { useDeliveryList } from './hooks/useDeliveryList'
import { IDelivery } from 'src/Api/DeliveryAPI/response'
import { TableCommon } from 'src/Component/TableCommon'
import { FilterCommon, FilterCommonProps } from 'src/Component/TableCommon/Filter'
import { IPackage } from 'src/Interface/PackageInterface'
import { formatDateTime, formatNumber } from 'src/Util/string'
import useDeliveryRequestStatus from 'src/hooks/fetch/useDeliveryRequestStatus'
import { DeliveryApi } from 'src/Api/DeliveryAPI/index.service'
import DefaultLayout from 'src/Component/Layout/Default'
import SkeletonTable from 'src/Component/TableCommon/SkeletonTable'
import ParagraphWithCopy from 'src/Component/inputs/ParagraphWithCopyButton'
import { Status } from 'src/Component/views/status'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'
import { Link } from 'react-router-dom'

export const DeliveryRequestList = (props: any) => {
  const { expandedRows, packageMapping, getDeliveryRequestPackages, setExpandedRows } = useDeliveryList()
  const { statusNameMapping, statusColorMapping } = useDeliveryRequestStatus()

  const columns: ColumnType<IDelivery>[] = [
    {
      title: 'Mã yêu cầu giao',
      key: 'code',
      dataIndex: 'code',
      width: '10%',
      render: (code: IDelivery['code']) => <ParagraphWithCopy text={code} />
    },
    {
      title: 'Thời gian tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: '10%',
      render: (createdAt: IDelivery['createdAt']) => <div className="min-w-[120px]">{formatDateTime(createdAt)}</div>
    },
    {
      title: 'Khách hàng',
      key: 'username',
      width: '15%',
      render: (row: IDelivery) => (
        <>
          {row.userFullname}
          <br />
          <span className="whitespace-nowrap">
            <span className="text-secondary2">Username: </span>
            <Link to={`/customers/${row.username}`}>{row.username ?? '---'}</Link>
          </span>
        </>
      )
    },
    {
      title: 'Tổng cân nặng',
      key: 'totalWeight',
      dataIndex: 'totalWeight',
      align: 'right',
      width: '10%',
      render: (weight: IDelivery['totalWeight']) => `${formatNumber(weight)} kg`
    },
    {
      title: 'Địa chỉ',
      key: 'address',
      dataIndex: 'address',
      render: (address: IDelivery['address']) => (
        <TooltipCommon placement="topLeft" title={address}>
          <div className="line-clamp-1">{address}</div>
        </TooltipCommon>
      )
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      render: (status: IDelivery['status']) => (
        <Status backgroundColor={statusColorMapping[status]} status={statusNameMapping[status] ?? status ?? '---'} />
      )
    }
  ]

  return (
    <DefaultLayout title={'Danh sách yêu cầu giao'} {...props}>
      <FilterCommon firstItems={firstItems} secondItems={[]} />

      <div className="mb-8">
        <TableCommon
          queryKey="delivery-request-list"
          filterItems={[...firstItems]}
          tableTitle="Danh sách yêu cầu giao"
          columns={columns}
          containerClassName="mt-3"
          fetchApiService={DeliveryApi.getList}
          notFoundText="Không tìm thấy yêu cầu giao nào"
          emptyText="Chưa có yêu cầu giao nào"
          rowKey={(row) => row.code}
          expandable={{
            expandedRowKeys: expandedRows,
            expandRowByClick: true,
            expandedRowRender: ({ code }: IDelivery) => {
              return (
                <div className="pr-16 pl-[48px]">
                  <Table
                    dataSource={packageMapping[code]}
                    loading={{
                      spinning: !packageMapping[code],
                      indicator: <SkeletonTable columns={columnsPackageWithoutTitle as never} skeletonRow={1} />
                    }}
                    columns={columnsPackage}
                    pagination={false}
                    className={'child-table'}
                    rowKey={'id'}
                  />
                </div>
              )
            },
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <i className="fas fa-chevron-up text-xs" onClick={(e) => onExpand(record, e)} aria-hidden />
              ) : (
                <i className="fas fa-chevron-right text-xs" onClick={(e) => onExpand(record, e)} aria-hidden />
              ),
            onExpandedRowsChange: (expandedRows) => setExpandedRows([...expandedRows]),
            onExpand: (expanded, record) => {
              if (expanded) {
                getDeliveryRequestPackages(record.code)
              }
            }
          }}
          rowClassName={(record: IDelivery) => {
            return expandedRows?.includes(record.code) ? 'active-row show-expand-icon cursor-pointer' : 'cursor-pointer'
          }}
        />
      </div>
    </DefaultLayout>
  )
}

const firstItems: FilterCommonProps['firstItems'] = [
  {
    name: 'createdAt',
    fieldName: ['timestampFrom', 'timestampTo'],
    label: ['Thời gian tạo từ ngày', 'Đến ngày'],
    type: 'date-range'
  }
]

const columnsPackage: ColumnType<IPackage>[] = [
  {
    title: 'Mã đơn',
    key: 'shipmentCode',
    dataIndex: 'shipmentCode',
    width: '20%'
  },
  {
    title: 'Mã kiện',
    key: 'code',
    dataIndex: 'code',
    width: '20%'
  },
  {
    title: <div className="pr-[40%] 2xl:pr-[60%]">Thể tích</div>,
    key: 'volumetric',
    dataIndex: 'volumetric',
    align: 'right',
    width: '20%',
    render: (volume: IPackage['volumetric']) => (
      <div className="pr-[40%] 2xl:pr-[60%] whitespace-nowrap">
        {volume === 0 ? 'Chưa xác định' : `${formatNumber(volume)} cm3`}
      </div>
    )
  },
  {
    title: <div className="pr-[40%] 2xl:pr-[50%]">Cân nặng</div>,
    key: 'netWeight',
    dataIndex: 'netWeight',
    align: 'right',
    width: '20%',
    render: (weight: IPackage['netWeight']) => <div className="pr-[40%] 2xl:pr-[50%]">{formatNumber(weight)} kg</div>
  },
  {
    title: <div className="pl-[30%]">Ngày tạo</div>,
    key: 'createdAt',
    dataIndex: 'createdAt',
    width: '20%',
    render: (createdAt: IPackage['createdAt']) => <div className="pl-[30%]">{formatDateTime(createdAt)}</div>
  }
]

const columnsPackageWithoutTitle = columnsPackage.map((column) => {
  return {
    ...column,
    title: undefined
  }
})
