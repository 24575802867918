import { notification } from 'antd'
import axios from 'axios'
import lodash from 'lodash'
import appConfig from '../Config/App'

const saboHttpClient = axios.create({
  baseURL: appConfig.apiClientUrl
})

//call api 2m
saboHttpClient.defaults.timeout = 12000000

saboHttpClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (lodash.get(error, 'response.status') >= 500) {
      notification.error({
        message: 'Máy chủ gặp sự cố. Vui lòng thử lại sau',
        key: 'server_error'
      })
    }

    return Promise.reject(error)
  }
)

export { saboHttpClient }
