import { Image, Steps } from 'antd'
import lodash, { reverse } from 'lodash'
import React, { ReactNode, useEffect, useState } from 'react'
import MilestoneInterface from '../../Interface/MilestoneInterface'
import StepIconFinishedImg from '../../resources/images/icons/step-finish.png'
import StepIconImg from '../../resources/images/icons/step.png'
import { formatDateTime } from '../../Util/string'
import { trans } from '../../resources/localization/Localization'
import Skeleton from 'react-loading-skeleton'
import ShipmentDetailInterface from '../../Interface/ShipmentDetailInterface'
import useWindowDimensions from '../../Util/dimention'

interface CustomDescriptionInterface {
  createdAt: string
  handlingTime?: string
}

type StepsOmitType = {
  status?: 'wait' | 'process' | 'finish' | 'error'
}
const { Step } = Steps
interface TrackOrderProps {
  statusNameMapping: {[key in string]: string}
  details?: ShipmentDetailInterface
  milestones: MilestoneInterface[]
  loading: boolean
  getMilestones: () => void
}

const TrackOrder: React.FC<TrackOrderProps> = (props) => {
  const { width } = useWindowDimensions()
  const { milestones, loading, statusNameMapping } = props

  const [milestonesRendered, setMileStoneRendered] = useState<MilestoneInterface[]>([])

  useEffect(() => {
    if (width <= 1024) {
      setMileStoneRendered([...reverse(milestones)])
    } else {
      setMileStoneRendered(milestones)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestones])

  const customDot = (iconDot: ReactNode, { status }: StepsOmitType) => {
    return !loading ? (
      <Image preview={false} src={status !== 'process' ? StepIconFinishedImg : StepIconImg} />
    ) : (
      <Skeleton width={10} height={10} circle />
    )
  }

  const CustomDescription = ({ createdAt }: CustomDescriptionInterface) => {
    return <div className="mt-1">{!loading ? createdAt : <Skeleton width={120} height={20} />}</div>
  }

  return width <= 1024 ? (
    <>
      <Steps
        className={`${width <= 1024 ? 'package-timeline ' : ''}`}
        current={width > 1024 ? 0 : milestonesRendered.length - 1}
        responsive={false}
        progressDot={customDot}
        direction={`${width <= 1024 ? 'horizontal' : 'vertical'}`}>
        {milestonesRendered &&
          milestonesRendered.length > 0 &&
          milestonesRendered.map(({ status, createdAt, id }: MilestoneInterface) => {
            const timestampRendered = lodash.isNull(createdAt) ? trans('order.undefined') : formatDateTime(createdAt)

            return (
              <Step
                key={id}
                title={
                  <span className="text-500 font-size-14">
                    {!loading ? statusNameMapping[status] : <Skeleton width={80} height={22} />}
                  </span>
                }
                description={<CustomDescription createdAt={timestampRendered} />}
              />
            )
          })}
      </Steps>
    </>
  ) : (
    <Steps
      className={`${width <= 1024 ? '' : ''}`}
      current={width > 1024 ? 0 : milestonesRendered.length - 1}
      progressDot={customDot}
      direction={`${width <= 1024 ? 'horizontal' : 'vertical'}`}>
      {milestonesRendered &&
        milestonesRendered.length > 0 &&
        milestonesRendered.map(({ status, createdAt, handlingTime, id }: MilestoneInterface) => {
          const timestampRendered = lodash.isNull(createdAt) ? trans('order.undefined') : formatDateTime(createdAt)
          const handlingTimeRendered = lodash.isNull(handlingTime) ? trans('order.undefined') : `${handlingTime} ngày`
          return (
            <Step
              key={id}
              title={
                <span className="text-500 font-size-14">
                  {!loading ? statusNameMapping[status] : <Skeleton width={80} height={22} />}
                </span>
              }
              description={<CustomDescription createdAt={timestampRendered} handlingTime={handlingTimeRendered} />}
            />
          )
        })}
    </Steps>
  )
}

export default TrackOrder
