import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { DeliveryApi } from 'src/Api/DeliveryAPI/index.service'
import { IDeliveryStatus } from 'src/Api/DeliveryAPI/response'

function useDeliveryRequestStatus() {
  const { data: statuses } = useQuery<IDeliveryStatus[]>('delivery-statuses', DeliveryApi.getStatuses)

  const { statusNameMapping, statusColorMapping } = useMemo(() => {
    const statusNameMapping: Record<IDeliveryStatus['code'], IDeliveryStatus['name']> = {}
    const statusColorMapping: Record<IDeliveryStatus['code'], IDeliveryStatus['color']> = {}

    statuses?.forEach((status) => {
      statusNameMapping[status.code] = status.name
      statusColorMapping[status.code] = status.color
    })
    return { statusNameMapping, statusColorMapping }
  }, [statuses])

  return {
    statuses,
    statusNameMapping,
    statusColorMapping
  }
}

export default useDeliveryRequestStatus
