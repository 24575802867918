import { saboHttpClient } from 'src/Util/SaboHttpClient'
import { AxiosResponse } from 'axios'
import { IPackageStatus } from 'src/Screen/ShipmentDetail/type'
import { IUpdateFeePayload } from './request'
import apiClient from 'src/Util/ApiClient'
import { ShipmentsCriteria } from 'src/Criteria/ShipmentsCriteria'
import { PaymentLogInterface } from 'src/Interface/PackageOrderInterface'
import OrderItemInterface from 'src/Interface/OrderItemInterface'

const BASE_URL = '/admin/shipments'
export class ShipmentsApi {
  static get(filter: ShipmentsCriteria) {
    return apiClient.get(BASE_URL, { params: filter })
  }

  static getShipmentStatuses() {
    return saboHttpClient
      .get(`/categories/package-statuses`)
      .then((response: AxiosResponse<IPackageStatus[]>) => response.data)
  }

  static getWaybills(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/waybills`).then((res) => res.data)
  }

  static getDetails(code: string) {
    return apiClient.get(`${BASE_URL}/${code}`)
  }

  static getMilestone(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/milestones`, {
      params: { limit: 1000, sort: 'timestamp:desc' }
    })
  }

  static getItems(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/products`, {
      params: { limit: 1000, sort: 'timestamp:desc' }
    })
  }

  static getFees(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/fees`)
  }

  static updateStatus(shipmentCode: string, status: string) {
    return apiClient.post(`/admin/shipments/${shipmentCode}/status`, { status })
  }

  static getTransactions(code: string) {
    return apiClient.get(`admin/shipments/${code}/transactions`, {
      params: { limit: 1000, sort: 'timestamp:desc' }
    })
  }

  static getPaymentRequests(code: string) {
    return apiClient.get(`admin/payment-requests`, {
      params: { size: 1000, shipmentCode: code }
    })
  }

  static updateStatusPaymentRequest(id: string, paymentStatus: string) {
    return apiClient.post(`admin/payment-requests/${id}/change-status`, {
      paymentStatus
    })
  }
  static updateMethodPaymentRequest(id: string, paymentMethod: string) {
    return apiClient.post(`admin/payment-requests/${id}/change-method`, {
      paymentMethod
    })
  }
  static getPackagesOfShipments(code: string) {
    return apiClient
      .get(`admin/shipments/${code}/packages`, {
        params: { sort: 'createdAt:desc' }
      })
      .then((res) => res.data)
  }

  static getMilestoneOfShipments(code: string, packageCode: string) {
    return apiClient.get(`admin/shipments/${code}/packages/${packageCode}/milestones`, {
      params: { code, packageCode, sort: 'createdAt:desc' }
    })
  }
  static getLogs = (code: string): Promise<AxiosResponse<PaymentLogInterface[]>> => {
    return apiClient.get(`admin/shipments/${code}/logs`, {
      params: { sort: 'timestamp:desc' }
    })
  }

  static getMidmileList() {
    return apiClient.get(`/admin/mid-mile?offset=0&limit=1000`)
  }

  static updateMidmile(shipmentCode: string, midMiles: string[]) {
    return apiClient.patch(`/admin/shipments/${shipmentCode}/mid-mile`, { midMiles })
  }

  static updateFee(shipmentCode: string, payload: IUpdateFeePayload) {
    return apiClient.patch(`/admin/shipments/${shipmentCode}/fee`, payload)
  }

  // create product
  static createProduct(shipmentCode: string, payload: any) {
    return apiClient.post(`/admin/shipments/${shipmentCode}/products`, payload)
  }

  // update product
  static updateProduct(shipmentCode: string, productCode: string, payload: OrderItemInterface) {
    return apiClient.post(`/admin/shipments/${shipmentCode}/products/${productCode}`, payload)
  }

  // delete product
  static deleteProduct(shipmentCode: string, productCode: string) {
    return apiClient.delete(`/admin/shipments/${shipmentCode}/products/${productCode}`)
  }

  // waybills
  static createWaybill = (shipmentCode: string, body: { waybillCode: string; isForce: boolean }): Promise<any> => {
    return apiClient.post(`admin/shipments/${shipmentCode}/waybills`, body).then((response: AxiosResponse) => {
      return response.data
    })
  }

  static deleteWaybill = (shipmentCode: string, waybillCode: string): Promise<any> => {
    return apiClient.delete(`admin/shipments//${shipmentCode}/waybills/${waybillCode}`)
  }
}
