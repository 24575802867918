import { Tooltip, TooltipProps } from 'antd'
import { FC, ReactNode } from 'react'

type IProps = TooltipProps & {
  children?: ReactNode
}

export const TooltipCommon: FC<IProps> = ({ children, ...props }) => {
  return (
    <Tooltip color="#CD5E77" arrowPointAtCenter {...props}>
      {children}
    </Tooltip>
  )
}
