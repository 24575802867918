import { Button, Form, Radio } from 'antd'
import { FC, useState } from 'react'
import { SupplierApi } from 'src/Api/SupplierApi'
import DefaultLayout from 'src/Component/Layout/Default'
import { FloatLabel } from 'src/Component/basic/FloatLabel'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { CustomerApi } from 'src/Api/CustomerAPI/index.service'
import CustomerInterface from 'src/Interface/CustomerInterface'
import { AccountBalanceInterface } from 'src/Interface/AccountBalanceInterface'
import { IAdjustBalancePayload } from 'src/Api/CustomerAPI/request'
import { confirm } from 'src/Component/basic/ModalCommon'
import localStore from 'src/Util/LocalStore'
import { CustomerInformation } from './components/CustomerInformation'

type FieldType = IAdjustBalancePayload

export const TransactionCreation: FC = (props) => {
  const [isDisableSubmit, setIsDisable] = useState(true)
  const [form] = Form.useForm<FieldType>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBalance, setIsLoadingBalance] = useState(false)

  const loggedUser = localStore.getJson('loggedUser')

  const [customer, setCustomer] = useState<CustomerInterface & { balance?: AccountBalanceInterface }>()

  const onSubmit = (values: FieldType) => {
    if (isLoading || !customer?.username) return
    setIsLoading(true)
    CustomerApi.adjustBalance(customer?.username, {
      ...values,
      amount: Number(values.amount),
      teller: loggedUser.username
    })
      .then(() => {
        NotificationCommon.success({
          message: (
            <span>
              Tạo giao dịch thành công.
              <br /> Bấm <a href="/transactions">vào đây</a> để xem chi tiết
            </span>
          )
        })
        setCustomer(undefined)
        form.resetFields()
      })
      .catch(NotificationCommon.notifyHttpError)
      .finally(() => {
        setIsDisable(true)
        setIsLoading(false)
      })
  }

  const onFinish = (values: FieldType) => {
    confirm({
      content: 'Bạn có chắc chắn muốn tạo giao dịch này?',
      onOk: () => onSubmit(values),
      okButtonProps: { loading: isLoading }
    })
  }

  const getAccountBalance = (customerSelected: CustomerInterface) => {
    setCustomer(customerSelected)

    setIsLoadingBalance(true)
    CustomerApi.getAccountBalance(customerSelected.username)
      .then((res) => {
        if (res.data) {
          setCustomer({ ...customerSelected, balance: res.data })
        }
      })
      .finally(() => {
        setIsLoadingBalance(false)
      })
  }

  return (
    <DefaultLayout title={'Tạo giao dịch điều chỉnh'} {...props}>
      <div className="flex flex-col-reverse md:flex-row gap-16 w-full">
        <div className="flex-1 py-16 px-24 bg-[#FFF] rounded-md shadow-[0px_6px_15px_0px_rgba(0,0,0,0.1)]">
          <div>
            <h5 className="text-lg">Tạo giao dịch tài chính khách hàng</h5>

            <Form
              form={form}
              onValuesChange={() => {
                const values = form.getFieldsValue()
                setIsDisable(!values.username || !values.memo || !String(values.amount ?? ''))
              }}
              onFinish={onFinish}
              className="flex flex-col gap-4">
              <Form.Item label="Loại giao dịch" className="mb-2">
                <Radio.Group disabled={isLoading}>
                  <Radio>Điều chỉnh</Radio>
                </Radio.Group>
              </Form.Item>

              <FloatLabel
                form={form}
                name="username"
                label="Mã khách hàng/Tên đăng nhập"
                required
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn username khách hàng'
                  }
                ]}
                type="select"
                selectProps={{
                  showSearch: true,
                  fetchApi: SupplierApi.getCustomers,
                  fieldNames: { value: 'username', label: 'name' },
                  mapLabel: (item: CustomerInterface) => {
                    return `${item.username} (${item.name})`
                  },
                  onChange: (_: string, options: CustomerInterface | any) => getAccountBalance(options),
                  disabled: isLoading
                }}
              />

              <FloatLabel
                form={form}
                name="amount"
                label={<span>Số tiền cần điều chỉnh</span>}
                required
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập số tiền cần điều chỉnh'
                  },
                  () => ({
                    validator(_, value: string) {
                      if (value.length > 0 && Number(value) === 0) {
                        return Promise.reject(new Error('Số tiền cần điều chỉnh phải khác 0'))
                      }
                      return Promise.resolve()
                    }
                  })
                ]}
                inputNumberProps={{
                  suffix: 'đ',
                  decimalLength: 0,
                  isAllowNagative: true,
                  disabled: isLoading
                }}
                type="number"
              />

              <FloatLabel
                form={form}
                name="memo"
                label="Lý do"
                required
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập lý do'
                  },
                  () => ({
                    validator(_, value: string) {
                      console.log('value', value)
                      if (value.length > 0 && value.trim().length < 1) {
                        return Promise.reject(new Error('Lý do không được chứa toàn khoảng trắng'))
                      }
                      return Promise.resolve()
                    }
                  })
                ]}
                inputProps={{
                  disabled: isLoading
                }}
              />

              <FloatLabel
                form={form}
                name="note"
                label="Ghi chú"
                type="textare"
                inputProps={{
                  disabled: isLoading
                }}
              />

              <Form.Item className="flex justify-end mb-0">
                <Button
                  icon={<i className="fa-solid fa-plus" />}
                  type="primary"
                  disabled={isDisableSubmit}
                  loading={isLoading}
                  htmlType="submit">
                  Tạo giao dịch
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

        <CustomerInformation customer={customer} isLoadingBalance={isLoadingBalance} />
      </div>
    </DefaultLayout>
  )
}
