import { Button, Form } from 'antd'
import { FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { FloatLabel } from 'src/Component/basic/FloatLabel'
import { ModalCommon } from 'src/Component/basic/ModalCommon'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import ShipmentFeeInterface from 'src/Interface/FeeInterface'
import { ShipmentDetailContext } from '../ShipmentDetailContext'
import { IUpdateFeePayload } from 'src/Api/ShipmentsApi/request'

interface ChangeFeeModalProps {
  feeToEdit: ShipmentFeeInterface | undefined
  setFeeToEdit: (fee: ShipmentFeeInterface | undefined) => void
}

type FieldType = {
  newFee?: string
  reason?: string
}

export const ChangeFeeModal: FC<ChangeFeeModalProps> = ({ feeToEdit, setFeeToEdit }) => {
  const [form] = Form.useForm<FieldType>()
  const { code } = useParams()
  const [isChanged, setIsChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDisable, setIsDisable] = useState(true)

  const { refreshDetails } = useContext(ShipmentDetailContext)

  const onCancel = () => {
    setFeeToEdit(undefined)
    form.resetFields()
  }

  const onSubmit = (value: FieldType) => {
    if (!code || !feeToEdit?.code) return
    if (loading) return

    const payload: IUpdateFeePayload = {
      feeCode: feeToEdit?.code,
      newFee: Number(value.newFee),
      reason: value.reason?.trim() as string
    }

    if (payload.newFee === feeToEdit.fee && payload.reason === feeToEdit.reason) {
      onCancel()
      return
    }

    payload.reason === feeToEdit.reason && delete payload.reason
    payload.newFee === feeToEdit.fee && delete payload.newFee
    setLoading(true)

    ShipmentsApi.updateFee(code, payload)
      .then(() => {
        refreshDetails()
        NotificationCommon.success({ message: 'Thay đổi phí thành công' })
        onCancel()
      })
      .catch(NotificationCommon.notifyHttpError)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    // lần đầu sửa thì ko fill sẵn
    if (!feeToEdit || !feeToEdit.manual) return

    form.setFieldsValue({
      newFee: String(feeToEdit.fee),
      reason: feeToEdit.reason
    })
  }, [feeToEdit, form])

  return (
    <ModalCommon
      destroyOnClose
      open={!!feeToEdit}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSubmit(values)
          })
          .catch(() => {})
      }}
      cancelButtonProps={{
        disabled: loading
      }}
      bodyStyle={{ paddingTop: 0 }}
      okButtonProps={{
        loading,
        disabled: String(feeToEdit?.fee).length < 0 || !isChanged || isDisable
      }}
      title={'Thay đổi ' + feeToEdit?.name}>
      <Form
        form={form}
        className="overflow-hidden pt-2"
        onValuesChange={(changedValue, values: FieldType) => {
          setIsChanged(true)
          setIsDisable(!values.newFee || !values.reason?.trim())
        }}
        onFinish={onSubmit}>
        <FloatLabel
          label="Giá trị mới"
          form={form}
          name="newFee"
          required
          type="number"
          rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
          inputNumberProps={{
            autoFocus: true,
            suffix: 'đ',
            min: -99999999999,
            decimalLength: 4
          }}
        />

        <FloatLabel
          label="Lý do thay đổi"
          form={form}
          name="reason"
          required
          type="textare"
          rules={[{ required: true, message: 'Vui lòng nhập lý do thay đổi' }]}
        />

        <Form.Item hidden>
          <Button htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </ModalCommon>
  )
}
