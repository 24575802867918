import React from 'react'
import { getCleanFormFilter } from '../../../Util/string'
import DataTable from './DataTable'
import { Pagination } from 'antd'
import useWindowDimensions from '../../../Util/dimention'
import ProviderList from './List'
import { trans } from '../../../resources/localization/Localization';

interface ProviderItemsProps {
	data: any
	page: number
	size: number
	total: number
	isSkeleton: boolean
	filter: any | {}
	handleFilter: (params: any | {}) => void
	setPage: (val: number) => void
	setPageSize: (val: number) => void
	handleAddProvider: (body: any) => void
	isModalOpen: boolean
	handleOk: () => void
	handleCancel: () => void
	setIsModalOpen:  React.Dispatch<React.SetStateAction<boolean>>
	isLoading:boolean
}

const ProviderItems: React.FC<ProviderItemsProps> = (props) => {
	const {
		data,
		isLoading,
		//   accounts,
		// statuses,
		filter,
		page,
		size,
		total,
		handleFilter,
		// providers,
		setPage,
		setPageSize,
		isSkeleton,
		handleAddProvider,
		isModalOpen,
		handleOk,
		handleCancel,
		setIsModalOpen
	} = props

	const onChangePage = (page: number, pageSize: number) => {
		setPage(page)
		setPageSize(pageSize)
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					page: (page - 1).toString(),
					size: pageSize.toString(),
				},
				[],
				['pageSize']
			)
		)
	}

	const { width } = useWindowDimensions()

	return (
		<div className='provider'>
			{width < 768 ? (
				<ProviderList
					handleAddProvider={handleAddProvider}
					isSkeleton={isSkeleton}
					handleOk={handleOk}
					handleCancel={handleCancel}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					total={total}
					// filter={filter}
					// accounts={accounts}
					// statuses={statuses}
					// providers={providers}
					data={data}
					loading={isLoading}
				/>
			) : (
				<DataTable
					isSkeleton={isSkeleton}
					total={total}
					data={data}
					handleAddProvider={handleAddProvider}
					handleOk={handleOk}
					handleCancel={handleCancel}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					loading={isLoading}
				/>
			)}

			<div className='flex justify-end mg-t-10'>
				<Pagination
					total={total}
					pageSize={size}
					current={page}
					onChange={onChangePage}
					className='shipments_pagination'
					defaultCurrent={0}
					pageSizeOptions={['10','20','25', '50', '100']}
					locale={{ items_per_page: `/ ${trans('orders.page')}` }}
					showSizeChanger={true}
				/>
			</div>
		</div>
	)
}

export default ProviderItems

