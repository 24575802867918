import { Button, Tabs } from 'antd'
import { isNull } from 'lodash'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  PERMISSION_SHIPMENTS_PRODUCT_VIEW,
  PERMISSION_SHIPMENTS_PACKAGE_VIEW,
  PERMISSION_SHIPMENTS_TRANSACTION_VIEW,
  PERMISSION_SHIPMENTS_PRODUCT_UPDATE,
  PERMISSION_SHIPMENTS_WAYBILL_UPDATE
} from '../../../Config/permission'
import OrderItemCommand from '../../../Interface/OrderItemInterface'
import PackageInterface from '../../../Interface/PackageInterface'
import ProviderInterface from '../../../Interface/ProviderInterface'
import TransactionInterface from '../../../Interface/TransactionInterface'
import { TAB_PACKAGES, TAB_PRODUCT, TAB_TRANSACTION } from '../../../Util/Constants'
import SecurityService from '../../../Util/SecurityService'
import OrderItems from './products'
import Packages from './Packages'
import Transactions from './Transactions'
import { Col } from 'antd'
import { ShipmentsApi } from '../../../Api/ShipmentsApi'
import ShipmentDetailInterface from '../../../Interface/ShipmentDetailInterface'
import CreateOrEditProductModal from './products/CreateOrEditProductModal'
import { ShipmentDetailContext } from '../ShipmentDetailContext'
import { WaybillCreating } from './Packages/components/WaybillCreating'

interface TabProps {
  items: OrderItemCommand[]
  providers: ProviderInterface[]
  currencyOfProvider: string
  transactions: TransactionInterface[]
  loadingTransactions: boolean
  details?: ShipmentDetailInterface
  loading: boolean
  updateDetail: () => void
  fetchMileStone: () => void
}

const TabLayout: React.FC<TabProps> = (props) => {
  let { code } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { items, transactions, loadingTransactions, loading } = props

  const { shipmentStatusObject } = useContext(ShipmentDetailContext)

  const [currentTabKey, setCurrentTabKey] = useState('')
  const [waybillFromShipment, setWaybillFromShipment] = useState<any>([])

  const [packages, setPackages] = useState<PackageInterface[]>([])
  const [loadingPackages, setLoadingPackages] = useState(false)

  const [isShowAddProduct, setIsShowAddProduct] = useState<boolean>(false)
  const [isShowAddWaybill, setIsShowAddWaybills] = useState<boolean>(false)

  const fetchWaybills = useCallback(async () => {
    setLoadingPackages(true)

    const values = await Promise.all([ShipmentsApi.getWaybills(code!), ShipmentsApi.getPackagesOfShipments(code!)])
    setWaybillFromShipment(values[0])
    setPackages(values[1])

    setLoadingPackages(false)
  }, [code])

  const handleChangeTab = (activeKey: string) => {
    setCurrentTabKey(activeKey)
    setSearchParams({ tab: activeKey })
  }

  const tabKeys = useMemo(() => {
    const tabKeys: string[] = []
    if (SecurityService.can(PERMISSION_SHIPMENTS_PRODUCT_VIEW)) {
      tabKeys.push(TAB_PRODUCT)
    }
    if (SecurityService.can(PERMISSION_SHIPMENTS_PACKAGE_VIEW)) {
      tabKeys.push(TAB_PACKAGES)
    }
    if (SecurityService.can(PERMISSION_SHIPMENTS_TRANSACTION_VIEW)) {
      tabKeys.push(TAB_TRANSACTION)
    }
    return tabKeys
  }, [])

  useEffect(() => {
    fetchWaybills()
  }, [fetchWaybills])

  useEffect(() => {
    const keyFromParams = isNull(searchParams.get('tab')) ? TAB_PRODUCT : searchParams.get('tab')
    setCurrentTabKey(keyFromParams && tabKeys.includes(keyFromParams) ? keyFromParams : tabKeys[0])
  }, [code, searchParams, tabKeys])

  if (tabKeys.length === 0) {
    return null
  }

  return (
    <>
      <Col span={24} className="shipment-tabs">
        <Tabs
          activeKey={currentTabKey}
          onChange={handleChangeTab}
          tabBarExtraContent={{
            right: (
              <>
                {currentTabKey === TAB_PRODUCT &&
                  SecurityService.can(PERMISSION_SHIPMENTS_PRODUCT_UPDATE) &&
                  shipmentStatusObject?.productUpdatable && (
                    <Button
                      danger
                      icon={<i className="fa-solid fa-plus" />}
                      onClick={() => setIsShowAddProduct(true)}
                      className="px-8 md:!px-4 h-[28px] md:h-8">
                      <span className="!hidden md:!inline">Thêm sản phẩm</span>
                    </Button>
                  )}

                {currentTabKey === TAB_PACKAGES && SecurityService.can(PERMISSION_SHIPMENTS_WAYBILL_UPDATE) && (
                  <Button
                    danger
                    icon={<i className="fa-solid fa-plus" />}
                    onClick={() => setIsShowAddWaybills(true)}
                    className="px-8 md:!px-4 h-[28px] md:h-8">
                    <span className="!hidden md:!inline">Thêm mã vận đơn</span>
                  </Button>
                )}
              </>
            )
          }}>
          {SecurityService.can(PERMISSION_SHIPMENTS_PRODUCT_VIEW) && (
            <Tabs.TabPane
              tab={
                <span className=" md:px-3 pd-bt-4 flex items-center">
                  <span
                    className={`${
                      currentTabKey === TAB_PRODUCT ? '' : 'txt-color-gray2'
                    }  mg-r-4 text-reg text-reg-500`}>
                    Sản phẩm
                  </span>

                  <span className={`${currentTabKey === TAB_PRODUCT ? 'badge badge-active' : 'badge'}`}>
                    {items?.length > 0 ? (
                      <span className={`${currentTabKey === TAB_PRODUCT ? '' : ' txt-color-gray2'}`}>
                        {items.length ?? 0}
                      </span>
                    ) : (
                      <span className={`${currentTabKey === TAB_PRODUCT ? '' : ' txt-color-gray2'}`}>0</span>
                    )}
                  </span>
                </span>
              }
              key={TAB_PRODUCT}>
              <OrderItems items={items} loading={loading} />
            </Tabs.TabPane>
          )}

          {SecurityService.can(PERMISSION_SHIPMENTS_PACKAGE_VIEW) && (
            <Tabs.TabPane
              tab={
                <span className=" md:px-3 pd-bt-4 flex items-center">
                  <span
                    className={`${currentTabKey === TAB_PACKAGES ? '' : 'txt-color-gray2'}
									mg-r-4 text-reg text-reg-500`}>
                    Vận chuyển
                  </span>
                  <span className={`${currentTabKey === TAB_PACKAGES ? 'badge badge-active' : 'badge'}`}>
                    {packages.length > 0 ? (
                      <span className={`${currentTabKey === TAB_PACKAGES ? '' : 'txt-color-gray2'}`}>
                        {packages.length}
                      </span>
                    ) : (
                      <span className={`${currentTabKey === TAB_PACKAGES ? '' : 'txt-color-gray2'}`}>0</span>
                    )}
                  </span>
                </span>
              }
              key={TAB_PACKAGES}>
              <Packages
                waybillFromShipment={waybillFromShipment}
                packages={packages}
                setPackages={setPackages}
                loading={loadingPackages}
                fetchWaybills={fetchWaybills}
              />
            </Tabs.TabPane>
          )}

          {SecurityService.can(PERMISSION_SHIPMENTS_TRANSACTION_VIEW) && (
            <Tabs.TabPane
              tab={
                <span className=" md:px-3 pd-bt-4 flex items-center">
                  <span
                    className={`mg-r-4 text-reg text-reg-500
									${currentTabKey === TAB_TRANSACTION ? '' : 'txt-color-gray2'}`}>
                    Giao dịch
                  </span>
                  <span className={`${currentTabKey === TAB_TRANSACTION ? 'badge badge-active' : 'badge'}`}>
                    {transactions.length > 0 ? (
                      <span className={`${currentTabKey === TAB_TRANSACTION ? '' : 'txt-color-gray2'}`}>
                        {transactions.length}
                      </span>
                    ) : (
                      <span className={`${currentTabKey === TAB_TRANSACTION ? '' : 'txt-color-gray2'}`}>0</span>
                    )}
                  </span>
                </span>
              }
              key={TAB_TRANSACTION}>
              <Transactions items={transactions} loading={loadingTransactions} />
            </Tabs.TabPane>
          )}
        </Tabs>

        <CreateOrEditProductModal open={isShowAddProduct} onCancel={() => setIsShowAddProduct(false)} />
      </Col>

      <WaybillCreating open={isShowAddWaybill} onCancel={() => setIsShowAddWaybills(false)} onSussess={fetchWaybills} />
    </>
  )
}

export default TabLayout
