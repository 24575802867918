import { Table } from 'antd'
import { formatDateTime, formatMoneyByUnit } from '../../../Util/string'
import { trans } from '../../../resources/localization/Localization'
import TransactionInterface from '../../../Interface/TransactionInterface'
import EmptyData from '../../../Component/EmptyData'
import RowExtended from './RowExtend'
// import Paragraph from 'antd/lib/typography/Paragraph'
import { ColumnType } from 'antd/es/table/interface'
import { useTransactionType } from 'src/hooks/fetch/useTransactionType'

interface TransactionProps {
  loading: boolean
  items: TransactionInterface[]
}

function Transactions(props: TransactionProps) {
  const { transactionTypeMappingToName } = useTransactionType()
  const { items } = props

  const formatAmount = (amount: any, currency: string) => {
    let color = amount < 0 ? 'text-red' : 'text-green'
    return <span className={color + ' font-medium'}>{formatMoneyByUnit(amount, currency)}</span>
  }

  const columns: ColumnType<TransactionInterface>[] = [
    {
      title: 'Mã giao dịch',
      key: 'txid',
      dataIndex: 'txid',
      width: '20%',
      render: (txid: TransactionInterface['txid']) => <span className="whitespace-nowrap">{txid}</span>
    },
    {
      title: 'Người thực hiện',
      key: 'owner',
      dataIndex: 'owner',
      width: '10%'
    },
    {
      title: 'Thời gian',
      key: 'timestamp',
      dataIndex: 'actualTimestamp',
      width: '20%',
      render: (actualTimestamp: TransactionInterface['actualTimestamp']) =>
        actualTimestamp ? formatDateTime(actualTimestamp) : '---'
    },
    {
      title: 'Giá trị',
      key: 'value',
      align: 'right',
      width: '10%',
      render: (record: TransactionInterface) => (
        <span className="font-medium">{formatAmount(record.amount, record.currency)}</span>
      )
    },
    {
      title: 'Loại giao dịch',
      key: 'type',
      dataIndex: 'type',
      width: '10%',
      render: (type: TransactionInterface['type']) => transactionTypeMappingToName[type] ?? '---'
    },
    {
      title: 'Nội dung',
      dataIndex: 'memo',
      key: 'memo',
      ellipsis: true,
      width: '30%'
    }
  ]

  const components = { body: { row: RowExtended } }

  return (
    <Table
      columns={columns}
      dataSource={items}
      components={components}
      locale={{ emptyText: <EmptyData text={trans('orders.empty')} /> }}
      className="shipmentDetail-table"
      onRow={(record, index) => {
        const rowClassesByIdx = index! % 2 === 0 ? 'row-dark' : 'row-light'
        return { ...record, index, rowClassesByIdx }
      }}
      rowClassName={(record, idx) => {
        const rowClassesByIdx = idx % 2 === 0 ? 'row-dark ' : 'row-light'
        return rowClassesByIdx
      }}
      pagination={{
        total: items.length,
        pageSize: 5,
        position: ['bottomRight'],
        locale: { items_per_page: `/ ${trans('orders.page')}` },
        hideOnSinglePage: true,
        showSizeChanger: false
      }}
      scroll={{ x: 'max-content' }}
    />
  )
}
export default Transactions
