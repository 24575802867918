import { FC } from 'react'

interface IProps {
  backgroundColor: string
  status: string
  className?: string
}

export const Status: FC<IProps> = ({ backgroundColor, status, className = '' }) => {
  if (!status) return <>---</>

  return (
    <div
      className={`rounded-full px-12 py-4 text-white font-medium capitalize whitespace-nowrap w-fit ${className}`}
      style={{ backgroundColor }}>
      {status}
    </div>
  )
}
