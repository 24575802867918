import { Button } from 'antd'
import React from 'react'
import { trans } from '../../../resources/localization/Localization'
import ModalAddProvider from '../ModalAddProvider'
import useWindowDimensions from '../../../Util/dimention'
import SecurityService from '../../../Util/SecurityService'
import { PERMISSION_PROVIDER_UPDATE } from '../../../Config/permission'
interface HeaderListProps {
	total: number
	handleAddProvider: (body: any) => void
	isModalOpen: boolean
	handleOk: () => void
	handleCancel: () => void
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
	isSkeleton: boolean
	isLoading: boolean
}

const HeaderList: React.FC<HeaderListProps> = (props) => {
	const { total, handleAddProvider, handleCancel, handleOk, isModalOpen, isLoading, isSkeleton, setIsModalOpen } =
		props

	const { width } = useWindowDimensions()

	return width > 768 ? (
		<div className='flex justify-between provider-header-list '>
			<div className='flex items-center'>
				<h3 className='txt-color-black mg-t-8 mg-r-10 mg-l-8 font-size-16 line-h-24 '>{trans('provider.list')}</h3>
				<span
					className={`${
						total > 0 ? 'bg-color-badge txt-color-white' : 'bg-color-gray-badge txt-color-gray4'
					} rad-20  font-size-12 line-h-20 px-8 mg-r-20`}
				>
					{total > 0 ? total : ''}
				</span>
			</div>
			<div>
				{SecurityService.can(PERMISSION_PROVIDER_UPDATE) && (
					<Button
						type='primary'
						icon={<i className='fa-light fa-plus' />}
						ghost
						className='font-size-14 text-400 cursor-pointer px-16'
						onClick={() => setIsModalOpen(true)}
					>
						{trans('provider.ModalAdd')}
					</Button>
				)}
				{isModalOpen && (
					<ModalAddProvider
						isSkeleton={isSkeleton}
						isModalOpen={isModalOpen}
						handleOk={handleOk}
						handleCancel={handleCancel}
						handleAddProvider={handleAddProvider}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	) : (
		<div className='flex justify-between bg-white header-list '>
			<div className='flex items-center'>
				<h3 className='txt-color-black mg-t-8 mg-r-10  font-size-16 line-h-24 '>{trans('provider.list')}</h3>
				<span
					className={`${
						total > 0 ? 'bg-color-badge txt-color-white' : 'bg-color-gray-badge txt-color-gray4'
					} rad-20  font-size-12 line-h-20 px-8 mg-r-20`}
				>
					{total}
				</span>
			</div>
			<div className='flex items-center'>
				{SecurityService.can(PERMISSION_PROVIDER_UPDATE) && (
					<span
						// type='primary'
						// ghost
						className='font-size-14 text-400 cursor-pointer txt-color-view'
					>
						{<i className='fa-light fa-plus ' onClick={() => setIsModalOpen(true)} />}
						{/* {trans('provider.ModalAdd')} */}
					</span>
				)}
				{isModalOpen && (
					<ModalAddProvider
						handleAddProvider={handleAddProvider}
						isModalOpen={isModalOpen}
						handleOk={handleOk}
						handleCancel={handleCancel}
						isSkeleton={isSkeleton}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	)
}

export default HeaderList

