import { Row, Col, Avatar, List } from 'antd'
import lodash from 'lodash'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'
import { MidmileView } from 'src/Component/views/MidmileView'
import { SHIPMENT_VIEW_MIDMILE_PARTNER } from 'src/Config/permission'
import ShipmentInterface, { ServiceInterface } from 'src/Interface/ShipmentInterface'
import SecurityService from 'src/Util/SecurityService'
import { formatDateTime1, formatMoneyByUnit } from 'src/Util/string'
import { trans } from 'src/resources/localization/Localization'
import IMAGE_DEFAULT from 'src/resources/images/empty-image.jpg'
import ParagraphWithCopy from 'src/Component/inputs/ParagraphWithCopyButton'

interface IProps {
  item: ShipmentInterface
  index: number
  statusColorMapping: { [key in string]: string }
  statusNameMapping: { [key in string]: string }
}

export const ListItem: FC<IProps> = ({ item, index, statusColorMapping, statusNameMapping }) => {
  const navigate = useNavigate()
  const services = lodash.get(item, 'services', [])

  return (
    <List.Item
      key={item.code}
      onClick={() => navigate(`/shipments/${item.code}`)}
      className={`cursor-pointer ${index % 2 === 0 ? 'row-dark' : 'row-light'}`}
      style={{ minWidth: 1080 }}>
      <List.Item.Meta
        avatar={
          <Avatar
            src={item.image ? item?.image : IMAGE_DEFAULT}
            shape="square"
            size={72}
            className="bd-none-impt rad-6"
          />
        }
        title={
          <div className="flex justify-between items-center gap-16">
            <Row align="top" className="flex-grow" gutter={[32, 0]}>
              <Col xs={10} xxl={8}>
                <div className="flex justify-between">
                  <TooltipCommon color="#CD5E77" title={<span className="">{trans('shipments.code')}</span>}>
                    <ParagraphWithCopy
                      containerClassName="inline"
                      text={item.code}
                      children={item.code ? '#' + item.code : '---'}
                    />
                  </TooltipCommon>

                  {SecurityService.can(SHIPMENT_VIEW_MIDMILE_PARTNER) && (
                    <TooltipCommon title="Vận chuyển Midmile" color="#CD5E77">
                      {item?.midMileView?.[0]?.code ? (
                        <span>
                          <MidmileView midMileView={item.midMileView} />
                        </span>
                      ) : (
                        '---'
                      )}
                    </TooltipCommon>
                  )}
                </div>

                <TooltipCommon color="#CD5E77" title={<span className="">{trans('shipments.createAtTooltip')}</span>}>
                  <span className="inline-block text-secondary mt-1">
                    {item.createdAt ? formatDateTime1(item.createdAt) : '---'}{' '}
                  </span>
                </TooltipCommon>

                <div className="mt-1">
                  <TooltipCommon color="#CD5E77" title={<span className="">Đặt / Nhận</span>}>
                    <span>{`${item.orderedQuantity ? item.orderedQuantity : '---'} / ${
                      item.receivedQuantity ? item.receivedQuantity : '---'
                    }`}</span>
                  </TooltipCommon>
                </div>
              </Col>

              <Col xs={8} xxl={11}>
                <Row align="middle" className="">
                  {item.services?.length > 0 && (
                    <div className="mg-r-18 flex flex-wrap">
                      <span className="txt-color-gray mg-r-4">{`${trans('shipments.services')}:`}</span>

                      {services.length > 0
                        ? services.map((item: ServiceInterface, index: number) => {
                            return (
                              <span className="font-medium" key={index}>
                                <span className="mr-1">{`${item.name}`}</span>
                                {index !== services.length - 1 && <span className="mr-1">|</span>}
                              </span>
                            )
                          })
                        : '---'}
                    </div>
                  )}
                </Row>

                <div className="mt-1">
                  <div>
                    <span className="mg-r-4 txt-color-gray">{trans('shipments.customer')}:</span>
                    <span className={`${item.username ? 'text-500' : ''}`}>
                      {' '}
                      {item.username ? item.username : '---'}
                    </span>
                  </div>

                  <div className="mt-1">
                    <span className="mg-r-4 txt-color-gray">Mã đơn hàng của bạn:</span>
                    <span className={`txt-color-black ${item.refShipmentCode ? 'text-500' : ''}`}>
                      {item.refShipmentCode ? item.refShipmentCode : '---'}
                    </span>
                  </div>
                </div>
              </Col>

              <Col xs={6} xxl={5}>
                <div className="grid">
                  <div>
                    <span className="mg-r-4 txt-color-gray">Tổng phí:</span>
                    <span className={`${item.totalFee ? 'text-500' : ''}`}>
                      {item.totalFee ? formatMoneyByUnit(item.totalFee) : <span className="txt-color-black">---</span>}
                    </span>
                  </div>

                  <div className="mt-1">
                    <span className="mg-r-4 txt-color-gray">Tiền hàng:</span>
                    <span className={`${item.totalValue ? 'text-500' : ''}`}>
                      {item.totalValue ? (
                        formatMoneyByUnit(item.totalValue, 'CNY')
                      ) : (
                        <span className="txt-color-black">---</span>
                      )}
                    </span>
                  </div>

                  <div className="mt-1">
                    <span className="mg-r-4 txt-color-gray">{trans('shipments.needPaid')}:</span>
                    <span className={`txt-color-red ${item.totalUnpaid ? 'text-500' : ''}`}>
                      {item.totalUnpaid ? (
                        formatMoneyByUnit(item.totalUnpaid)
                      ) : (
                        <span className="txt-color-black">---</span>
                      )}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="flex items-center justify-center min-w-[130px]">
              <span
                className="rounded-full px-12 py-4 text-white font-medium capitalize whitespace-nowrap"
                style={{ backgroundColor: statusColorMapping[item.status] }}>
                {statusNameMapping[item.status]}
              </span>
            </div>
          </div>
        }
      />
    </List.Item>
  )
}
