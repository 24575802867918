import apiClient from '../../Util/ApiClient'
export class TransactionHistoryApi {
  static get(filter: any) {
    return apiClient.get('/admin/customers/transactions', {
      params: {
        ...filter,
        sort: 'nominalTimestamp:desc'
      }
    })
  }
}
