import { Divider } from 'antd'
import { trans } from '../../../resources/localization/Localization'
import lodash from 'lodash'
import { ServiceInterface } from '../../../Interface/OrderDetailInterface'
import Skeleton from 'react-loading-skeleton'
import { FC } from 'react'
import Item from './Item'
import ShipmentDetailInterface from 'src/Interface/ShipmentDetailInterface'
import CustomerInterface from 'src/Interface/CustomerInterface'
import { formatNumber } from 'src/Util/string'

interface IProps {
  expand: boolean
  loading: boolean
  details: ShipmentDetailInterface | undefined
  displayAddress: string
  listServices: any
  originalReceipts: any
  receivingWarehouseAddressReplace: string
  industryGroupNameMapping: { [key in string]: string }
  customer: CustomerInterface | undefined
}

export const DesktopView: FC<IProps> = ({
  expand,
  loading,
  details,
  displayAddress,
  listServices,
  originalReceipts,
  receivingWarehouseAddressReplace,
  industryGroupNameMapping,
  customer
}) => {
  return (
    <div className={`shipment-content__text-info pd-12 ${!expand ? 'py-0' : ''}`}>
      {!loading ? <h3>{trans('shipments.information')}</h3> : <Skeleton width={120} height={22} />}
      {expand && (
        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-8">
            <Item
              label={<span className="txt-color-gray1 font-size-14">Khách hàng:</span>}
              content={
                <a href={`/customers/${details?.username}`} target="_blank" rel="noreferrer">
                  {details?.username ? details?.username : '---'} {customer?.name && `(${customer?.name})`}
                </a>
              }
              loading={loading}
            />

            <Item
              label={<span className="txt-color-gray1 font-size-14">{trans('shipments.receiver')}:</span>}
              content={<span className="text-400 font-size-14">{lodash.get(details, 'address.receiver', '---')}</span>}
              loading={loading}
            />

            <Item
              label={<span className="txt-color-gray1 font-size-14">{trans('shipments.phoneNumber')}:</span>}
              content={
                <span className="text-400 font-size-14">{lodash.get(details, 'address.phoneNumber', '---')}</span>
              }
              loading={loading}
            />
            <Item
              label={<span className="txt-color-gray1 font-size-14">{trans('shipments.address')}:</span>}
              content={
                <span className="text-400 font-size-14">
                  {lodash.get(details, 'address.receiver', '---')},{displayAddress}
                </span>
              }
              loading={loading}
              containerClassContent={'flex-grow-1'}
            />
          </div>

          <Divider type="horizontal" className="mg-0" />
          <div className="flex flex-col gap-8">
            <Item
              label="Ngành hàng:"
              content={industryGroupNameMapping[details?.hsCode as string]}
              loading={loading}
              containerClassContent="flex-grow-1"
            />

            <Item
              label={
                <span className="txt-color-gray1 flex-basis-pc-50 font-size-14">{trans('shipments.services')}:</span>
              }
              content={
                listServices.length > 0
                  ? listServices.map((item: ServiceInterface, idx: number) => {
                      let colorService = ''
                      if (item.needApprove) {
                        if (item.approved === null || item.approved === undefined) {
                          colorService = 'services-pending'
                        } else if (item.approved === false) {
                          colorService = 'services-reject'
                        }
                      }
                      return (
                        <span key={idx} className={`mg-r-4 ${colorService} font-size-14`}>
                          {`${item.name} ${idx !== listServices.length - 1 ? '|' : ''}`}{' '}
                        </span>
                      )
                    })
                  : '---'
              }
              loading={loading}
              containerClassContent="flex-grow-1"
            />
          </div>
          <Divider type="horizontal" className="mg-0" />

          {!loading ? (
            <div className="flex">
              <div className="flex flex-basis-50pc flex-col flex-grow-1">
                <span className="txt-color-gray1 font-size-14">{trans('shipments.originalInvoiceCode')}:</span>
                <div className="font-size-14">{originalReceipts}</div>

                <span className="txt-color-gray1 font-size-14 mg-t-12">Mã khách hàng của bạn:</span>
                <div className="font-size-14">{details?.refCustomerCode || '---'}</div>
              </div>

              <div className="flex-basis-50pc">
                <span className="txt-color-gray1 font-size-14">Mã đơn hàng của bạn:</span>
                <div className="font-size-14">{details?.refShipmentCode || '---'}</div>
              </div>
            </div>
          ) : (
            <div className="flex">
              <div className="flex flex-basis-50pc flex-col flex-grow-1">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
              <div className="flex-basis-50pc">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
            </div>
          )}

          <Divider type="horizontal" className="mg-0" />
          <div className="grid gap-y-3" style={{ gridTemplateColumns: '1fr 1fr' }}>
            {!loading ? (
              <div className="flex flex-col flex-grow-1">
                <span className="txt-color-gray1 font-size-14">Cân nặng dự kiến: </span>
                <div className="font-size-14">
                  {details?.expectedWeight ? formatNumber(details.expectedWeight) + ' (kg)' : '---'}
                </div>
              </div>
            ) : (
              <div className="flex flex-col flex-grow-1">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
            )}

            {!loading ? (
              <div className="flex flex-col flex-grow-1">
                <span className="txt-color-gray1 font-size-14">Thể tích dự kiến:</span>
                <div className="font-size-14">
                  {details?.expectedVolume ? `${formatNumber(details?.expectedVolume)} (m3)` : '---'}
                </div>
              </div>
            ) : (
              <div className="flex flex-col flex-grow-1">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
            )}

            {!loading ? (
              <div className="flex flex-col flex-grow-1">
                <span className="txt-color-gray1 font-size-14">Cân nặng tính phí: </span>
                <div className="font-size-14">{details?.actualWeight ? details.actualWeight + ' (kg)' : '---'}</div>
              </div>
            ) : (
              <div className="flex flex-col flex-grow-1">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
            )}

            {!loading ? (
              <div className="flex flex-col flex-grow-1">
                <span className="txt-color-gray1 font-size-14">Thể tích thực tế:</span>
                <div className="font-size-14">
                  {details?.actualVolume ? `${formatNumber(details.actualVolume)} (cm3)` : '---'}
                </div>
              </div>
            ) : (
              <div className="flex flex-col flex-grow-1">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
            )}
          </div>
          <Divider type="horizontal" className="mg-0" />

          <div className="basic-item dpl-block">
            <div className="basic-item__label">
              {!loading ? (
                <p className="whitespace-pre-wrap">
                  Địa chỉ nhận tại Trung Quốc:{' '}
                  <span dangerouslySetInnerHTML={{ __html: receivingWarehouseAddressReplace }}></span>
                </p>
              ) : (
                <Skeleton />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
