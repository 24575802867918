import { Table, Tooltip } from "antd";
import lodash from "lodash";
import React, { useState } from "react";
import { formatDateTime1 } from "../../../Util/string";
import HeaderList from "./HeaderList";
import RowExtended from "./RowExtend";
import { trans } from "../../../resources/localization/Localization";
import ProviderListInterface from "../../../Interface/ProviderListInterface";
import SkeletonTable from "../../../Component/SkeletonTable";
import EmptyData from "../../../Component/EmptyData";

interface DataTableProps {
  isSkeleton: boolean;
  loading: boolean;
  total: number;
  // filter: any | {}
  // accounts: any[]
  // statuses: any[]
  // providers: any[]
  data: ProviderListInterface[];
  handleAddProvider: (body: any) => void;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DataTable: React.FC<DataTableProps> = (props) => {
  const {
    total,
    data,
    isSkeleton,
    //    statuses,
    //     providers,
    handleAddProvider,
    handleCancel,
    handleOk,
    isModalOpen,
    loading,
    setIsModalOpen,
  } = props;
  const [expandedRows] = useState<Array<any>>([]);

  const columns = [
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          {trans("provider.name")}
        </span>
      ),
      // align:'center' as 'center',
      width: "15%",
      render: (item: any) => (
        <Tooltip
          title={<span className="font-size-14 text-500"> {item.name}</span>}
		  color="#CD5E77"
		  placement="top"
		  className="provider-tooltip"
        >
          <span className="font-size-14 text-500"> {item.name}</span>
        </Tooltip>
      ),
      ellipsis: true,
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          {trans("provider.logo")}
        </span>
      ),
      width: "20%",
      render: (item: any) => {
        const logoShow = item.logo
          ? item.logo.slice(0, 25).trim() + "..."
          : "---";
        const logoTooltip = item.logo
          ? item.logo.slice(0, 150).trim() + "..."
          : "---";

        return (
          <>
            <Tooltip
              placement="top"
              // overlayClassName='provider-tooltip'
              className="provider-tooltip"
              title={
                // <Paragraph>
                <div className="font-size-12 text-400 txt-color-white">
                  {item.logo && item.logo.length > 50
                    ? logoTooltip
                    : item.logo
                    ? item.logo
                    : "---"}
                </div>
                // </Paragraph>
              }
              color="#CD5E77"
            >
              <span className={`font-size-14 `}>
                {item.logo && item.logo.length > 30
                  ? logoShow
                  : item.logo
                  ? item.logo
                  : "---"}
              </span>
            </Tooltip>
          </>
        );
      },
      ellipsis: true,
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          {trans("provider.domain")}
        </span>
      ),
      width: "20%",
      render: (item: any) => (
        <span className="font-size-14">{item.domain}</span>
      ),
      ellipsis: true,
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          {trans("provider.code")}
        </span>
      ),
      width: "18%",
      render: (item: any) => <span className="font-size-14">{item.code}</span>,
      ellipsis: true,
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray mg-r-15">
          {trans("provider.create_by")}
        </span>
      ),
      width: "20%",
      render: (item: any) => (
        <span className="font-size-14">
          {item.createdBy ? item.createdBy : "---"}
        </span>
      ),
      ellipsis: true,
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray mg-r-15">
          {trans("provider.description")}
        </span>
      ),
      width: "25%",
      render: (item: any) => {
        // let description = item.description.slice(0, 200)
        return (
          <span className="font-size-14">
            {item.description ? item.description : "---"}
          </span>
        );
      },
      // ellipsis: true,
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          {trans("provider.created_at")}
        </span>
      ),
      width: "18%",
      align: "right" as "right",
      render: (item: any) => (
        <span className="txt-color-gray1 font-size-14">
          {item.createdAt ? formatDateTime1(item.createdAt) : "---"}
        </span>
      ),
      ellipsis: true,
    },
  ];
  const components = { body: { row: RowExtended } };

  return (
    <SkeletonTable
      columns={columns}
      loading={isSkeleton}
      className="main-table"
    >
      <Table
        rowKey={(item: any) => item.id}
        columns={columns}
        dataSource={data}
        pagination={false}
        className="table-improved font-size-14 table-provider"
        locale={{ emptyText: <EmptyData text={trans("orders.empty")} /> }}
        components={components}
        onRow={(item: any, index) => {
          const rowClassesByIdx =
            index! % 2 === 0 ? "row-dark" : "row-light-provider";
          return { ...item, index, rowClassesByIdx };
        }}
        title={() => (
          <HeaderList
            total={total}
            handleAddProvider={handleAddProvider}
            handleCancel={handleCancel}
            handleOk={handleOk}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isSkeleton={isSkeleton}
            isLoading={loading}
          />
        )}
        // components={}
        scroll={{ x: 526 }}
        rowClassName={(item, idx) => {
          const rowClassesActiveByExpanded = lodash.includes(
            expandedRows,
            item.id
          )
            ? "active-row cursor-pointer"
            : "data-row cursor-pointer";
          const rowClassesByIdx =
            idx % 2 === 0 ? "row-dark" : "row-light-provider";
          return `${rowClassesActiveByExpanded} ${rowClassesByIdx}`;
        }}
      />
    </SkeletonTable>
  );
};

export default DataTable;
