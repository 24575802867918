import { useEffect, useState } from 'react'
import { SupplierApi } from 'src/Api/SupplierApi'
import CustomerInterface from 'src/Interface/CustomerInterface'

export const useCustomer = (username: string | undefined) => {
  const [customer, setCustomer] = useState<CustomerInterface>()

  useEffect(() => {
    if (!username) {
      return
    }

    SupplierApi.customerDetail(username).then((res) => {
      setCustomer(res)
    })
  }, [username])

  return { customer }
}
