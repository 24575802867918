import { Key, useCallback, useState } from 'react'
import { DeliveryApi } from 'src/Api/DeliveryAPI/index.service'
import { IDelivery } from 'src/Api/DeliveryAPI/response'
import { IPackage } from 'src/Interface/PackageInterface'

export const useDeliveryList = () => {
  const [expandedRows, setExpandedRows] = useState<Key[]>()
  const [packageMapping, setPackageMapping] = useState<Record<string, IPackage[]>>({})

  const getDeliveryRequestPackages = useCallback(
    (code: IDelivery['code']) => {
      if (packageMapping[code]) return

      DeliveryApi.getDeliveryRequestPackages(code).then((res) => {
        setPackageMapping((prev) => ({ ...prev, [code]: res.data }))
      })
    },
    [packageMapping]
  )

  return { expandedRows, setExpandedRows, packageMapping, getDeliveryRequestPackages }
}
