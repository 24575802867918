export enum ShipmentLogEvent {
  WAYBILL_REMOVED = 'WAYBILL_REMOVED',
  WAYBILL_ADDED = 'WAYBILL_ADDED',
  PACKAGES_CHANGED = 'PACKAGES_CHANGED',
  PACKAGES_ADDED = 'PACKAGES_ADDED',
  PACKAGES_REMOVED = 'PACKAGES_REMOVED',
  SHIPMENT_UPDATE_STATUS = 'SHIPMENT_UPDATE_STATUS',
  SHIPMENT_UPDATE_FEE = 'SHIPMENT_UPDATE_FEE',
  SHIPMENT_PRODUCT_CREATE = 'SHIPMENT_PRODUCT_CREATE',
  SHIPMENT_PRODUCT_UPDATE = 'SHIPMENT_PRODUCT_UPDATE',
  SHIPMENT_PRODUCT_DELETE = 'SHIPMENT_PRODUCT_DELETE'
}

export type PackageChangedKeys =
  | 'length'
  | 'width'
  | 'height'
  | 'volume'
  | 'packagingWeight'
  | 'actualWeight'
  | 'netWeight'
  | 'dimensionalWeight'
  | 'status'
  | 'midmile'

export type ProductsChangedKeys =
  | 'url'
  | 'orderedQuantity'
  | 'price'
  | 'name'
  | 'translatedName'
  | 'merchantName'
  | 'merchantContact'
  | 'image'
