const appConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  apiClientUrl: process.env.REACT_APP_API_CLIENT_URL,
  loginUrl: process.env.REACT_APP_LOGIN_URL,
  logoutUrl: process.env.REACT_APP_LOGOUT_URL,
  authenticationUrl: window.location.origin + '/authentication',
  tinyMCEKey: process.env.REACT_APP_TINYMCE_API_KEY,
}

export default appConfig
