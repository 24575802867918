import { Image } from 'antd'
import React from 'react'
import EmptyImg from '../../resources/images/empty-3.png'

interface EmptyProps {
  text?: string | any
  inRowExpanded?: boolean
  canAddRecord?: boolean
}

const EmptyData: React.FC<EmptyProps> = ({ text, canAddRecord, children }) => {
  return !canAddRecord ? (
    <div className="flex flex-col justify-center items-center py-6 gap-4">
      <Image preview={false} src={EmptyImg} />
      <span className={'text-reg text-gray'}>{text ?? children}</span>
    </div>
  ) : (
    <div>{children}</div>
  )
}

export default EmptyData
