import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import { trans } from '../../resources/localization/Localization'
import lodash from 'lodash'
import TextArea from 'antd/lib/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import FloatLabel from '../../Component/Floatlabel'

interface ModalAddProviderProps {
	// showModal: () => void
	isModalOpen: boolean
	handleOk: () => void
	handleCancel: () => void
	handleAddProvider: (body: any) => void
	isSkeleton: boolean
	isLoading:boolean
}

const ModalAddProvider: React.FC<ModalAddProviderProps> = (props) => {
	const { isModalOpen, isLoading, handleCancel, handleAddProvider } = props
	const [body, setBody] = useState<any>({})

	/**
	 * @param keyOfObj
	 * @param valueOfObj
	 */
	const onChange = (key: string, value: any) => {
		setBody({ ...body, [key]: value })
	}

	const validateData = (body: any) => {
		if (!body.name || !body.code || !body.domain || body?.name?.trim().length === 0) {
			return false
		}
		return true
	}

	
	return (
		<>
			<Modal
				className='modalAddProvider'
				title={trans('provider.ModalAdd')}
				open={isModalOpen}
				onCancel={handleCancel}
				cancelText={trans('provider.btnCancel')}
				cancelButtonProps={{
					className: 'font-size-14 px-14 text-400',
					icon: <CloseOutlined />,
				}}
				okText={trans('provider.btnAdd')}
				okButtonProps={{
					className: 'font-size-14 px-14 text-400',
					disabled: !validateData(body),
					loading: isLoading,
					htmlType: 'submit',
					icon: <i className='fa-light fa-plus' />,
				}}
				onOk={() => handleAddProvider(body)}
			>
				<div
					className=''
					// onBlur={() => handleBody(body)}
				>
					<FloatLabel
						className='mg-bt-16 txt-color-gray1'
						label={t('provider.namePlaceholder')}
						value={lodash.get(body, 'name')}
						required
					>
						<Input
							className='m22-input'
							placeholder=''
							allowClear
							value={lodash.get(body, 'name')}
							onChange={(e) => onChange('name', e.target.value)}
							// onBlur={() => handleBody(body)}
							// onFocus={()=>handleBody(body)}
							size='large'
						/>
					</FloatLabel>
					<FloatLabel
						className='mg-bt-16 txt-color-gray1'
						label={trans('provider.logoPlaceholder')}
						value={lodash.get(body, 'logo')}
					>
						<Input
							className='m22-input'
							placeholder=''
							allowClear
							value={lodash.get(body, 'logo')}
							onChange={(e) => {
								onChange('logo', e.target.value.trim())
							}}
							// onBlur={() => handleBody(body)}
							// onFocus={()=>handleBody(body)}
							size='large'
						/>
					</FloatLabel>
					<FloatLabel
						className='mg-bt-16 txt-color-gray1'
						label={trans('provider.domainPlaceholder')}
						value={lodash.get(body, 'domain')}
						required
					>
						<Input
							className='m22-input'
							placeholder=''
							allowClear
							value={lodash.get(body, 'domain')}
							onChange={(e) => onChange('domain', e.target.value.trim())}
							// onBlur={() => handleBody(body)}
							// onFocus={()=>handleBody(body)}
							size='large'
							type='url'
						/>
					</FloatLabel>
					<FloatLabel
						className='mg-bt-16 txt-color-gray1'
						label={trans('provider.codePlaceholder')}
						value={lodash.get(body, 'code')}
						required
					>
						<Input
							className='m22-input'
							placeholder=''
							allowClear
							value={lodash.get(body, 'code')}
							onChange={(e) => onChange('code', e.target.value.trim())}
							// onBlur={() => handleBody(body)}
							// onFocus={()=>handleBody(body)}
							size='large'
						/>
					</FloatLabel>
					<FloatLabel
						className='mg-bt-16  txt-color-gray1'
						label={trans('provider.descriptionPlaceholder')}
						value={lodash.get(body, 'description')}
						isTextArea
					>
						<TextArea
							className='provider-textarea'
							value={lodash.get(body, 'description')}
							placeholder=''
							onChange={(e) => onChange('description', e.target.value)}
							// onBlur={() => handleBody(body)}
							// onFocus={()=>handleBody(body)}
							size='large'
						/>
					</FloatLabel>
				</div>
			</Modal>
		</>
	)
}

export default ModalAddProvider

