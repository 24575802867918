import { useCallback, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { IMidMile } from '../../Interface/ShipmentInterface'
import { Avatar } from 'antd'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { SelectCommonProps } from 'src/Component/inputs/SelectCommon'

export type MidMileMappingType = { [key in IMidMile['code']]: IMidMile['logo'] | IMidMile['name'] }

export const useMidmile = () => {
  const [midmileList, setMidlileList] = useState<IMidMile[]>([])
  const [midmileLogoMapping, setMidmileLogoMapping] = useState<MidMileMappingType>({})
  const [midmileNameMapping, setMidmileNameMapping] = useState<MidMileMappingType>({})

  useEffect(() => {
    ShipmentsApi.getMidmileList().then((response: AxiosResponse<IMidMile[]>) => {
      setMidlileList(response.data)
      const obj: MidMileMappingType = {}
      const objName: MidMileMappingType = {}
      response.data.forEach((midmile) => {
        obj[midmile.code] = midmile.logo
        objName[midmile.code] = midmile.name
      })
      setMidmileLogoMapping(obj)
      setMidmileNameMapping(objName)
    })
  }, [])

  const getMidmileOptions = useCallback((): SelectCommonProps['options'] => {
    const options = midmileList.map((midmile) => {
      return {
        label: (
          <span className="inline-flex items-center gap-8">
            <Avatar size={20} src={midmile.logo} />
            <span>{midmile.name}</span>
          </span>
        ),
        value: midmile.code,
        textLabel: midmile.name,
      }
    })
    return options
  }, [midmileList])

  return { getMidmileOptions, midmileList, midmileLogoMapping, midmileNameMapping }
}
