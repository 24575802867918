import apiClient from '../Util/ApiClient'
const BASE_URL = '/admin/providers'
export class ProviderApi {
	static filter(filter: any) {
		let limit = filter && filter.size ? filter.size : 25
		let offset = filter && filter.page ? filter.page * limit : 0
		return apiClient.get(BASE_URL, {
			params: {
				...filter,
				limit,
				offset,
				sort: 'createdAt:desc',
			},
		})
	}
	static createProvider(body: any) {
		return apiClient.post(BASE_URL, body)
	}

	static getAll = () => {
		return apiClient.get('/categories/providers?size=1000').then((res) => res.data)
	}
}

