import lodash from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CategoryApi } from '../../Api/CategoryApi'
import { OrdersApi } from '../../Api/OrdersApi'
import DefaultLayout from '../../Component/Layout/Default'
import { OrdersCriteria } from '../../Criteria/OrdersCriteria'
import MarketPalaceCommand from '../../Interface/MarketPalaceInterface'
import StatusCommand from '../../Interface/StatusInterface'
import { getCleanFormFilter } from '../../Util/string'
import { useProvider } from '../../hooks/fetch/useProvider'
import { trans } from '../../resources/localization/Localization'
import Filter from './Filter'
import List from './List'

const Orders = (props: any) => {
  const [orders, setOrders] = useState<Array<OrdersCriteria>>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(Number(searchParams.get('page')) - 1)
  const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
  const {providers, getOrderLink} = useProvider()
  const [orderStatuses, setOrderStatuses] = useState<Array<StatusCommand>>([])
  const [marketPalaces, setMarketplaces] = useState<Array<MarketPalaceCommand>>([])
  const [filter, setFilter] = useState<OrdersCriteria>({})
  const [loading, setLoading] = useState(false)
  const [loadingStatuses, setLoadingStatuses] = useState(false)
  const [loadingMarketPalaces, setLoadingMarketPalaces] = useState(false)

  const getOrders = useCallback(async () => {
    const providerUsername = searchParams.get('providerUsername')
    const providerOrderCode = searchParams.get('providerOrderCode')
    const provider = searchParams.get('provider')
    const username = searchParams.get('username')
    const statuses = searchParams.get('statuses')
    const marketplaces = searchParams.get('marketplaces')
    const timestampTo = searchParams.get('timestampTo')
    const timestampFrom = searchParams.get('timestampFrom')
    const page = searchParams.get('page')
    const size = searchParams.get('size')
    const orderBy = searchParams.get('orderBy')
    const temp = {
      page: Number(page) - 1,
      size,
      providerUsername,
      providerOrderCode,
      provider,
      username,
      statuses,
      marketplaces,
      timestampFrom,
      timestampTo,
      orderBy : orderBy || 'desc',
    }
    setFilter((prevState) => ({ ...prevState, ...getCleanFormFilter(temp) }))
    setLoading(true)
    try {
      const response = await OrdersApi.filter(getCleanFormFilter(temp))
      const currentPage = lodash.get(response, 'headers.x-page-number')
      const total = lodash.get(response, 'headers.x-total-count')
      setOrders(response.data)
      setPage(parseInt(currentPage) + 1)
      setTotal(parseInt(total))
      setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [searchParams])

  const getMarketPalaces = useCallback(async () => {
    setLoadingMarketPalaces(true)
    try {
      const response = await CategoryApi.getMarketPalaces()
      setMarketplaces(response.data)
      setLoadingMarketPalaces(false)
    } catch (err) {
      setLoadingMarketPalaces(false)
    }
  }, [])

  const getStatuses = useCallback(async () => {
    setLoadingStatuses(true)
    try {
      const response = await CategoryApi.getStatuses()
      setOrderStatuses(response.data)
      setLoadingStatuses(false)
    } catch (err) {
      setLoadingStatuses(false)
    }
  }, [])

  useEffect(() => {
    getOrders()
  }, [getOrders])

  useEffect(() => {
    getStatuses()
    getMarketPalaces()
  }, [getStatuses, getMarketPalaces])

  const handleFilter = (params: any) => {
    params.page = Number(params.page || 0) + 1
    setFilter({ ...params })
    setSearchParams({ ...params })
  }

  return (
    <DefaultLayout title={trans('orders.list')} {...props}>
      <Filter
        handleFilter={handleFilter}
        statuses={orderStatuses}
        marketPalaces={marketPalaces}
        orderBy={searchParams.get('orderBy') as any}
        setFilter={(obj: OrdersCriteria) => setFilter(obj)}
        filter={filter}
        loadingStatuses={loadingStatuses}
        loadingMarkets={loadingMarketPalaces}
      />
      <List
        total={total}
        page={page}
        orderBy={searchParams.get('orderBy') as any}
        size={pageSize}
        items={orders}
        providers={providers}
        statuses={orderStatuses}
        handleFilter={handleFilter}
        setPage={(val: number) => setPage(val)}
        setPageSize={(val: number) => setPagesize(val)}
        filter={filter}
        loading={loading}
        getOrderLink={getOrderLink}
      />
    </DefaultLayout>
  )
}

export default Orders
