import axios, { AxiosResponse } from 'axios'
import apiClient from '../Util/ApiClient'
import localStore from 'src/Util/LocalStore'
import { TransactionTypeInterface } from 'src/Interface/TransactionInterface'
import { saboHttpClient } from 'src/Util/SaboHttpClient'

const BASE_URL = '/categories'

export class CategoryApi {
  static getProviders() {
    return apiClient.get(`${BASE_URL}/providers`, {
      params: { size: 1000 }
    })
  }

  static getStatuses() {
    return apiClient.get(`${BASE_URL}/order-statuses`, {
      params: { size: 1000 }
    })
  }

  static getShipmentStatuses() {
    return apiClient.get(`${BASE_URL}/shipment-statuses`, {
      params: { size: 1000, type: 'INTERNAL', sort: 'position:asc' }
    })
  }

  static getMarketPalaces() {
    return apiClient.get(`${BASE_URL}/marketplaces`, {
      params: { size: 1000 }
    })
  }

  static getPaymentMethods() {
    return apiClient.get(`${BASE_URL}/payment-methods`)
  }

  static getPaymentStatuses() {
    return apiClient.get(`${BASE_URL}/payment-statuses`)
  }

  static getPaymentRequestTypes() {
    return apiClient.get(`${BASE_URL}/payment-request-types`)
  }

  static getTransactionStatuses() {
    return apiClient.get(`${BASE_URL}/transaction-statuses`)
  }

  static getTransactionTypes(): Promise<AxiosResponse<TransactionTypeInterface[]>> {
    return saboHttpClient.get(`${BASE_URL}/dior-transaction-types`)
  }
  static getConnectionStatuses() {
    return apiClient.get(`${BASE_URL}/connection-statuses`)
  }
  static getPackageStatuses = () => {
    return axios.get(`${process.env.REACT_APP_API_CLIENT_URL}/categories/package-statuses`, {
      headers: {
        'X-Tenant': localStore.getJson('tenant')?.code
      }
    })
  }
}
