import { Divider } from 'antd'
import React, { useContext, useMemo } from 'react'
import Item from './Item'
import { formatMoneyByUnit } from '../../../Util/string'
import { trans } from '../../../resources/localization/Localization'
import Skeleton from 'react-loading-skeleton'
import ShipmentFeeInterface from '../../../Interface/FeeInterface'
import { ChangeFeeModal } from './ChangeFeeModal'
import { ShipmentDetailContext } from '../ShipmentDetailContext'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'
import SecurityService from 'src/Util/SecurityService'
import { PERMISSION_SHIPMENTS_UPDATE_FEE } from 'src/Config/permission'

interface FeeBoxProps {
  loading: boolean
}

const FeeBox = (props: FeeBoxProps) => {
  const { loading } = props
  const { details } = useContext(ShipmentDetailContext)

  const [feeToEdit, setFeeToEdit] = React.useState<ShipmentFeeInterface>()

  const isAllowedToChangeFee = useMemo(() => SecurityService.can(PERMISSION_SHIPMENTS_UPDATE_FEE), [])

  const fees = details?.feeDetail ?? []
  const totalFee = details?.totalFee
  const totalPaid = details?.totalPaid
  const totalUnpaid = details?.totalUnpaid ?? 0

  const renderFee = () => {
    if (loading) {
      return (
        <div className="pl-6">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )
    }

    return fees.map((fee: ShipmentFeeInterface, idx: number) => {
      return (
        <div className="flex justify-between items-center fee-items mg-l-16 gap-16" key={idx}>
          <span className="flex py-4 label align-items-center txt-color-gray3">
            {fee.name ?? '---'}
            <span className="label-notice txt-size-h8 txt-color-gray2 mg-l-6">{`(2.${idx + 1})`}</span>
          </span>

          <span>
            {fee.manual && <span className="line-through text-[#b1b1b1] mr-2">{formatMoneyByUnit(fee.prevFee)}</span>}

            <span className="whitespace-nowrap">
              <TooltipCommon title={fee.manual && fee.reason}>
                {fee.free ? 'Miễn phí' : formatMoneyByUnit(fee.fee)}
              </TooltipCommon>

              {isAllowedToChangeFee && (
                <span
                  onClick={() => {
                    setFeeToEdit(fee)
                  }}
                  className="text-primary ml-2 cursor-pointer">
                  <i className="fa-regular fa-pen-to-square"></i>
                </span>
              )}
            </span>
          </span>
        </div>
      )
    })
  }

  return (
    <div className="fees">
      <div className="fees-calculate">
        <Item
          number={'(1)'}
          label={'Tổng phí'}
          labelClass={'text-reg text-500'}
          valueClass="text-500"
          value={totalFee}
          currencySubValue={'currencyMarketplace'}
          loading={loading}
        />
        {renderFee()}
        <Divider className="mg-0 my-4" dashed={true} />
      </div>

      <div className="fees-summary">
        <Item
          number={'(2)'}
          label={trans('shipments.paid')}
          value={totalPaid}
          loading={loading}
          labelClass={`text-500`}
          valueClass={`txt-color-green text-500`}></Item>

        <Item
          number={'(3)'}
          label={trans('shipments.refund')}
          value={details?.totalRefund}
          labelClass={`text-500`}
          loading={loading}
          valueClass="text-500"></Item>

        {details && details.status !== 'CANCELLED' ? (
          <Item
            number={'(4)'}
            valueClass={'text-reg text-500 txt-color-red'}
            labelClass={'text-reg text-500'}
            label={totalUnpaid >= -1 ? trans('shipments.needPay') : trans('shipment.excessCash')}
            value={totalUnpaid ? Math.abs(totalUnpaid) : totalUnpaid}
            loading={loading}></Item>
        ) : null}
      </div>

      {feeToEdit && <ChangeFeeModal feeToEdit={feeToEdit} setFeeToEdit={setFeeToEdit} />}
    </div>
  )
}

export default FeeBox
