import moment from 'moment'
import { Link } from 'react-router-dom'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'
import ParagraphWithCopy from 'src/Component/inputs/ParagraphWithCopyButton'
import { formatMoneyByUnit } from 'src/Util/string'
import { TransactionMappingType } from 'src/hooks/fetch/useTransactionType'
import { ITransaction } from '../type'

export const renderCode = (code: ITransaction['ledgeTx']) => (
  <ParagraphWithCopy text={code} className="text-primary font-normal">
    #{code}
  </ParagraphWithCopy>
)

export const renderUsername = (owner: ITransaction['owner']) => <Link to={`/customers/${owner}`}>{owner}</Link>

export const renderAmount = (data: ITransaction['amount'], row: ITransaction) => (
  <span className={`${data > -1 ? 'text-success' : 'text-danger'}  font-medium`}>
    {formatMoneyByUnit(data, row.currency)}
  </span>
)

export const renderBalanceAfter = (data: ITransaction['balanceAfter'], row: ITransaction) => (
  <span className={`${data > -1 ? '' : 'text-danger'}  font-medium`}>{formatMoneyByUnit(data, row.currency)}</span>
)

export const renderNote = (note: ITransaction['note']) => {
  if (!note) {
    return (
      <>
        <span className="text-secondary2 hidden md:inline">Ghi chú: </span>---
      </>
    )
  }

  return (
    <div className="line-clamp-1 md:line-clamp-3">
      <TooltipCommon title={note?.length > 50 ? note : ''}>
        <span className="text-secondary2 hidden md:inline">Ghi chú: </span>
        {note}
      </TooltipCommon>
    </div>
  )
}

export const renderMemo = (memo: ITransaction['memo']) => {
  return (
    <TooltipCommon title={memo?.length > 50 ? memo : ''}>
      <div
        dangerouslySetInnerHTML={{
          __html: formatMemo(memo)
        }}
        className="line-clamp-2 md:line-clamp-3"></div>
    </TooltipCommon>
  )
}

export const renderTimestamp = (actualTimestamp: ITransaction['actualTimestamp']) => {
  return <span className="text-[#7d7e7d] whitespace-nowrap">{moment(actualTimestamp).format('HH:mm MM/DD/YYYY')}</span>
}

export const renderTypeName = (type: ITransaction['type'], transactionTypeMappingToName: TransactionMappingType) => {
  return <span className="md:font-medium">{transactionTypeMappingToName[type] || type}</span>
}

function formatMemo(memo: string): string | TrustedHTML {
  const shipmentPattern = /(đơn ký gửi )#([A-Z0-9_A-Z0-9]+)/
  if (shipmentPattern.test(memo)) {
    return memo.replace(shipmentPattern, (_, g1, g2) => {
      return g1 + `<a href="/shipments/${g2}" target="_blank">#${g2}</a>`
    })
  }

  return memo
}
