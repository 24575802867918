import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { queryClient } from 'src/App'

export const usePackageStatus = () => {
  const { data: packageStatuses } = useQuery({
    queryKey: 'shipmentPackages',
    queryFn: ShipmentsApi.getShipmentStatuses,
    initialData: () => {
      return queryClient.getQueryData('shipmentPackages')
    }
  })

  const { packageStatusesNameMapping, packageStatusesColorMapping } = useMemo(() => {
    const packageStatusesNameMapping: Record<string, string> = {}
    const packageStatusesColorMapping: Record<string, string> = {}

    packageStatuses?.forEach((status: any) => {
      packageStatusesNameMapping[status.code] = status.name
      packageStatusesColorMapping[status.code] = status.color ?? '#c15d5d'
    })

    return { packageStatusesNameMapping, packageStatusesColorMapping }
  }, [packageStatuses])

  return { packageStatuses, packageStatusesNameMapping, packageStatusesColorMapping }
}
