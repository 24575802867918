import { Avatar, Button, Skeleton } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SupplierApi } from '../../../Api/SupplierApi'
import DefaultLayout from '../../../Component/Layout/Default'
import { trans } from '../../../resources/localization/Localization'
import { formatDateTime, formatMoneyByUnit } from '../../../Util/string'
import { useQuery } from 'react-query'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { AxiosError } from 'axios'
import { CustomerApi } from 'src/Api/CustomerAPI/index.service'
import clsx from 'clsx'
import { CreateDepositModal } from './components/CreateDepositModal'
import SecurityService from 'src/Util/SecurityService'
import { PERMISSION_TRANSACTION_DEPOSIT } from 'src/Config/permission'

interface OrderDetailProps {}

const CustomerDetail: React.FC<OrderDetailProps> = (props) => {
  const { username } = useParams()
  const { xs } = useBreakpoint()
  const [addressDefault, setAddressDefault] = useState('')
  const [openCreateDeposit, setOpenCreateDeposit] = useState(false)

  const queryKey = 'customer' + username

  const {
    data: customerDetail,
    isLoading: loadingDetails,
    refetch: refetchDetails
  } = useQuery([queryKey, username], (data) => SupplierApi.customerDetail(data.queryKey[1]), {
    onError: (error) => {
      NotificationCommon.notifyHttpError(error as AxiosError)
    }
  })

  const {
    data: balance,
    isLoading: loadingBalance,
    refetch: refetchBalance
  } = useQuery(
    ['balance', username],
    (data) => CustomerApi.getAccountBalance(data.queryKey[1] as string).then((res) => res.data),
    {
      onError: (error) => {
        NotificationCommon.notifyHttpError(error as AxiosError)
      }
    }
  )

  const fetchDefaultAddressForShip = useCallback(async () => {
    try {
      const res = await SupplierApi.getDefaultAddress(username!)
      setAddressDefault(`${res.data?.address1} ${res.data?.display}`)
    } catch (err) {
      setAddressDefault('---')
    }
  }, [username])

  useEffect(() => {
    fetchDefaultAddressForShip()
  }, [fetchDefaultAddressForShip])

  const balanceDisplay = loadingBalance ? (
    <Skeleton.Input active size="small" />
  ) : (
    <span
      className={clsx(
        balance && balance.balance >= 0 ? 'text-success' : 'text-danger',
        'font-medium cursor-default text-lg'
      )}>
      {formatMoneyByUnit(balance?.balance, balance?.currency)}{' '}
      {SecurityService.can(PERMISSION_TRANSACTION_DEPOSIT) && (
        <Button className="" onClick={() => setOpenCreateDeposit(true)} type="link" size="small">
          Nạp tiền
        </Button>
      )}
    </span>
  )

  const name = loadingDetails ? (
    <Skeleton.Input active size="small" />
  ) : (
    <span className="text-lg text-700">{lodash.get(customerDetail, 'name', '---')}</span>
  )

  const usernameAndCode = (
    <span className="text-700 text-md">{`${lodash.get(customerDetail, 'username', '---')} | ${lodash.get(
      customerDetail,
      'code',
      '---'
    )}`}</span>
  )

  const createdAt = (
    <div className="flex flex-col gap-4">
      <span className="text-gray text-nowrap">{`${trans('customers.createdAt')}:`}</span>
      {loadingDetails ? (
        <Skeleton.Input active size="small" block={true} />
      ) : (
        <span className="text-reg text-500 text-nowrap">
          {customerDetail?.createdAt ? formatDateTime(customerDetail?.createdAt) : '---'}
        </span>
      )}
    </div>
  )

  const address = (
    <div className="flex flex-col gap-4">
      <span className="text-gray text-nowrap">{`${trans('customer.addressDefaultForReceiveOrder')}:`}</span>
      {loadingDetails ? (
        <Skeleton.Input active size="small" block={true} />
      ) : (
        <span className="text-reg text-500">{addressDefault ? addressDefault : '---'}</span>
      )}
    </div>
  )

  return (
    <DefaultLayout title={trans('customer.detail')} {...props}>
      <div className="flex flex-col customer-detail">
        <div className="flex box-main box-info gap-30 mg-bt-12">
          {loadingDetails ? (
            <div className="flex flex-col items-center gap-10 avatar">
              <Skeleton.Avatar shape="circle" size={160} active />
              <Skeleton.Input active size="small" />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-10 avatar">
              <Avatar
                shape="circle"
                size={160}
                src={customerDetail?.avatar ? customerDetail?.avatar : ''}
                className="flex-shrink-0"
              />
              {xs ? (
                <div className="flex flex-col">
                  {name}
                  {usernameAndCode}
                  {balanceDisplay}
                </div>
              ) : (
                usernameAndCode
              )}
            </div>
          )}

          <div className="flex flex-col gap-16 flex-basis-60pc">
            {!xs && (
              <div>
                {name}
                <div className="mt-1">{balanceDisplay}</div>
              </div>
            )}

            <div className={`flex ${!xs ? 'gap-50' : ''}`}>
              <div className="flex flex-col gap-12">
                <div className="flex gap-60">
                  {xs && createdAt}

                  <div className="flex flex-col gap-4">
                    <span className="text-gray text-nowrap">{`${trans('customer.phone')}:`}</span>
                    {loadingDetails ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <span className="text-reg text-500 text-nowrap">
                        {customerDetail?.mobile ? customerDetail.mobile : '---'}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <span className="text-gray text-nowrap">{`${trans('customer.emailAddress')}:`}</span>
                  {loadingDetails ? (
                    <Skeleton.Input active size="small" />
                  ) : (
                    <span className="text-reg text-500">{customerDetail?.email ? customerDetail.email : '---'}</span>
                  )}
                </div>

                {xs && address}
              </div>

              <div className="flex flex-col gap-12 flex-grow-1">
                {!xs && createdAt}
                {!xs && address}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateDepositModal
        open={openCreateDeposit}
        onCancel={() => setOpenCreateDeposit(false)}
        onSuccess={() => {
          refetchDetails()
          refetchBalance()
        }}
      />
    </DefaultLayout>
  )
}

export default CustomerDetail
