import _ from 'lodash'
import localStore from '../../Util/LocalStore'

let language = localStore.getItem('language')

let lang = (function lang(_language) {
  switch (_language) {
    case 'en':
      return require('./locals/en/en.json')
    case 'vi':
      return require('./locals/vn/vn.json')
    default:
      return require('./locals/vn/vn.json')
  }
})(language)

const fallback = require('./locals/vn/vn.json')

export const trans = (key, params = {}) => {
  let value = _.get(lang, key, key)
  if (value === key || !value) {
    let value = _.get(fallback, key, key)
    if (Object.keys(params).length > 0) {
      for (let i in params) {
        value = value.replace(`{{${i}}}`, params[i])
      }
    }
    return _.get(fallback, key, key)
  }

  return value
}
