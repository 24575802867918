import React from 'react'
import Paragraph from 'antd/lib/typography/Paragraph'

import './style.scss'
import { TooltipCommon } from 'src/Component/basic/TooltipCommon'

interface ParagraphWithCopyProps {
  children?: any
  text: string | undefined
  className?: string
  containerClassName?: string
}

const ParagraphWithCopy: React.FC<ParagraphWithCopyProps> = ({
  children,
  text,
  className,
  containerClassName = '',
}: ParagraphWithCopyProps): JSX.Element => {
  return (
    <Paragraph
      copyable={
        text
          ? {
              text,
              icon: [
                <TooltipCommon title={'Copy'} key={'asas'}>
                  <i className={`far fa-copy`}></i>
                </TooltipCommon>,
                <TooltipCommon title={'Copied'} key={'sdasd'}>
                  <i className={`far fa-check`}></i>
                </TooltipCommon>
              ],
              tooltips: [false, false]
            }
          : undefined
      }
      className={`text-copy ${containerClassName}`}>
      <span className={className || ''}>{children || text || '---'}</span>
    </Paragraph>
  )
}

export default ParagraphWithCopy
