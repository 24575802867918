import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode } from 'react'

interface INotificationArgs extends Omit<ArgsProps, 'message'> {
  message: string | ReactNode
}

export class NotificationCommon {
  static success({ message = 'Thao tác thành công', description, key, ...args }: INotificationArgs) {
    notification.success({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: 'Thành công',
      description: message,
      key: key ?? String(description),
      ...args
    })
  }

  static error({ message = 'Đã xảy ra lỗi', description, key, ...args }: INotificationArgs) {
    notification.error({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: 'Thất bại',
      description: message,
      key: key ?? String(description),
      ...args
    })
  }

  static notifyHttpError(error: AxiosError) {
    const status = error.response?.status
    if (!status) return
    if (status && status >= 500) return
    if (status && status === 401) return

    const errorKey = error.response?.data.title
    notification.error({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: 'Đã xảy ra lỗi',
      description: errorKey ? t('error.' + errorKey) : 'Lỗi không xác định. Vui lòng thử lại.',
      key: errorKey
    })
  }
}
