import { Badge, List, Pagination, Table, TableProps } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import {
  forwardRef,
  ForwardRefExoticComponent,
  Fragment,
  MutableRefObject,
  ReactNode,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import { DataTableCriteria } from './type'
import { useSearchParams } from 'react-router-dom'

import './style.scss'
import moment from 'moment'
import { useQuery } from 'react-query'
import SkeletonTable from './SkeletonTable'
import { t } from 'i18next'
import { NotificationCommon } from '../basic/NotificationCommon'
import EmptyData from '../EmptyData'
import { FilterCommonProps } from './Filter'
import { TooltipCommon } from '../basic/TooltipCommon'
import { queryClient } from 'src/App'
import React from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { MobileView } from './components/MobileView'

interface IProps extends TableProps<any> {
  /**
   * key duy nhất cho react-query, phải có nếu có nhiều bảng trên cùng 1 trang để tránh conflic cache
   */
  queryKey?: string
  noContainerStyle?: boolean
  filterItems?: FilterCommonProps['firstItems']
  tableTitle?: string
  emptyText?: string
  actions?: ReactNode[] | false
  noPagination?: boolean
  requireParams?: string | string[] // require field to start fetching data
  isHasExtendedRow?: boolean
  containerClassName?: string
  notFoundText?: string
  emptyAction?: ReactNode
  mobileRowRender?: (row: any, idx: number) => JSX.Element
  rowItemRender?: (row: any, idx: number) => ReactNode
  setShowProgressBar?: (isLoading: boolean) => void
  fetchApiService?: (filter: ITableCommonFilter) => Promise<any>
}
export interface TableCommonRefMethod {
  refreshData: () => void
}

export type TableCommonRef = MutableRefObject<TableCommonRefMethod | undefined>

export type ITableCommonFilter = DataTableCriteria & {
  [key in string]: string | number | string[]
}

export const LIMIT_DEFAULT = 25

export const TableCommon: ForwardRefExoticComponent<IProps & RefAttributes<TableCommonRefMethod | undefined>> =
  forwardRef(
    (
      {
        queryKey,
        fetchApiService,
        filterItems,
        actions,
        tableTitle,
        emptyText = 'Chưa có dữ liệu',
        emptyAction,
        dataSource,
        notFoundText = 'Không tìm thấy dữ liệu',
        noPagination = false,
        rowClassName,
        isHasExtendedRow,
        containerClassName = '',
        loading,
        requireParams,
        mobileRowRender,
        setShowProgressBar,
        rowItemRender,
        ...props
      }: IProps,
      ref: any
    ) => {
      const { columns = [] } = props
      const { xs } = useBreakpoint()

      const [searchParams, setSearchParams] = useSearchParams()
      const [total, setTotal] = useState(0)
      const [displayType, setDisplayType] = useState<'list' | 'table'>('table')

      const pagination = useMemo(
        () => ({
          current: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
          pageSize: searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : LIMIT_DEFAULT
        }),
        [searchParams]
      )

      const { dateRangeFields, arrayFields } = useMemo(() => {
        const dateRangeFields: string[] = []
        const arrayFields: string[] = []
        filterItems?.forEach((item) => {
          if (item.type === 'date-range' && item.fieldName) {
            dateRangeFields.push(item.fieldName[0], item.fieldName[1])
          }
          if (item.type === 'list-select' || item.type === 'select') {
            arrayFields.push(item.name)
          }
        })
        return { dateRangeFields, arrayFields }
      }, [filterItems])

      const getFilter = useCallback(() => {
        const filter: ITableCommonFilter = {
          offset: (pagination.current - 1) * pagination.pageSize,
          limit: pagination.pageSize,
          sort: 'createdAt:desc'
        }

        searchParams.forEach((value, key) => {
          if (key === 'page' || key === 'pageSize') return
          if (dateRangeFields?.includes(key)) {
            filter[key] =
              dateRangeFields[0] === key
                ? moment(value).startOf('day').toISOString()
                : moment(value).endOf('day').toISOString()
            return
          }
          if (arrayFields?.includes(key)) {
            filter[key] = searchParams.getAll(key).join(',')
            return
          }
          if (key === 'orderBy') {
            filter.sort = `createdAt:${value}`
            return
          }
          filter[key] = value
        })
        return filter
      }, [arrayFields, dateRangeFields, pagination, searchParams])

      const handleFetchData = async (filter: ITableCommonFilter) => {
        if (typeof requireParams === 'string' && !filter[requireParams]) return
        if (!fetchApiService) return undefined

        const res: AxiosResponse<any> = await fetchApiService(filter)
        setTotal(Number(res.headers['x-total-count']))
        return res.data
      }

      const { data, isLoading, refetch } = useQuery<any>(
        [queryKey, getFilter()],
        (data: any) => {
          return handleFetchData(data.queryKey[1])
        },
        {
          onError: (error) => {
            NotificationCommon.notifyHttpError(error as AxiosError)
          },
          retry: false
        }
      )

      const tableLoading: boolean = (loading as boolean) ?? isLoading
      // const tableLoading: boolean = true
      const tableData = data ?? dataSource

      const isSearching = useMemo(() => {
        if (!filterItems || filterItems?.length < 1) return false
        let isSearching = false
        searchParams.forEach((value, key) => {
          if (key !== 'page' && key !== 'pageSize' && key !== 'tab' && !!value) {
            isSearching = true
          }
        })
        return isSearching
      }, [searchParams, filterItems])

      const onChange = (page: number, pageSize: number) => {
        if (tableLoading) return

        searchParams.set('page', String(page))
        searchParams.set('pageSize', String(pageSize))
        setSearchParams(searchParams)
      }

      const refreshData = () => {
        searchParams.forEach((_, key) => {
          if (key !== requireParams) {
            searchParams.delete(key)
          }
        })
        setSearchParams(searchParams)
        refetch()
      }

      useEffect(() => {
        setShowProgressBar?.(tableLoading)
      }, [tableLoading, setShowProgressBar])

      useEffect(() => {
        return () => {
          if (queryKey) {
            queryClient.resetQueries({ queryKey, exact: true })
          }
        }
      }, [queryKey])

      useImperativeHandle(
        ref,
        (): TableCommonRefMethod => ({
          refreshData
        })
      )

      const renderPagination = () => {
        if (total < 10) return
        const totalPage = Math.ceil(total / (pagination.pageSize || LIMIT_DEFAULT))

        return (
          <Pagination
            {...pagination}
            total={total}
            pageSizeOptions={[10, 25, 50, 100]}
            showSizeChanger={true}
            locale={{ items_per_page: '/ Trang ' }}
            onChange={onChange}
            className={`table-common-pagination ${totalPage > 1 ? '' : 'icon-hidden'}`}
            showTotal={() => `Trang ${pagination.current}/${totalPage > 0 ? totalPage : 1}`}
            prevIcon={<i className="fa-solid fa-chevron-left"></i>}
            nextIcon={<i className="fa-solid fa-chevron-right"></i>}
            {...props.pagination}
          />
        )
      }

      const title = () => (
        <div className={`flex justify-between items-center gap-8 flex-wrap`}>
          <div className="flex items-center gap-4">
            <h3 className="txt-color-black font-size-16 m-0">{tableTitle}</h3>
            <Badge color="#CD5E77" count={total} showZero overflowCount={999} />

            {rowItemRender && (
              <div className="flex gap-8 ml-3">
                <TooltipCommon title={<span className="text-[12px] line-h-20">{t('shipments.tableLayout')}</span>}>
                  <span className={`text-[16px] cursor-pointer ${displayType === 'table' ? 'text-primary' : ''}`}>
                    <i className={`fa-light  fa-table-list`} onClick={() => setDisplayType('table')}></i>
                  </span>
                </TooltipCommon>

                <TooltipCommon title={<span className=" text-[12px] line-h-20">{t('shipments.listLayout')}</span>}>
                  <span className={`text-[16px] cursor-pointer ${displayType === 'list' ? 'text-primary' : ''}`}>
                    <i className={`fa-light fa-table-cells`} onClick={() => setDisplayType('list')}></i>
                  </span>
                </TooltipCommon>
              </div>
            )}
          </div>

          <div className="flex gap-16 flex-wrap">
            {renderPagination()}
            {actions}
          </div>
        </div>
      )

      const cls = useMemo(() => {
        let cls = `table-common ${containerClassName} overflow-hidden`
        if (props.noContainerStyle) {
          cls += ` table-no-container-style`
        }
        if (props.expandable) {
          cls += ` table-expandable`
        }

        if (isHasExtendedRow) {
          cls += ` table-has-extended-row`
        }
        return cls
      }, [containerClassName, isHasExtendedRow, props.expandable, props.noContainerStyle])

      const tableProps = { ...props }

      if (tableTitle || actions) {
        tableProps.title = title
      }

      if (xs && mobileRowRender) {
        return (
          <div className={cls}>
            <MobileView tableData={tableData} title={title} mobileRowRender={mobileRowRender} loading={tableLoading} />
          </div>
        )
      }

      return (
        <div className={cls}>
          {displayType === 'table' && (
            <Table
              tableLayout="auto"
              loading={{
                spinning: tableLoading,
                indicator: (
                  <SkeletonTable
                    columns={props.columns as any}
                    scroll={{ x: columns?.length > 4 ? 1100 : 675 }}
                    title={tableProps.title}
                  />
                )
              }}
              locale={{ emptyText: <EmptyData text={isSearching ? notFoundText : emptyText} /> }}
              dataSource={tableData}
              rowKey={(row) => row.id || row.code}
              scroll={tableData?.length ? { x: columns?.length > 4 ? 1100 : 342, y: 700 } : undefined}
              rowClassName={(record, index) => {
                if (!record) return 'empty-row'
                const rowClassesByIdx = index! % 2 === 0 ? 'row-light' : 'row-dark'
                if (typeof rowClassName === 'string') return rowClassesByIdx + ' ' + rowClassName

                return rowClassesByIdx + ' ' + (rowClassName?.(record, index, index) ?? '')
              }}
              components={
                !props.expandable
                  ? {
                      body: {
                        row: (record: any) => {
                          return (
                            <>
                              <tr className={'ant-table-row ' + record.className} onClick={record.onClick}>
                                {record.children}
                              </tr>

                              <tr className="h-8"></tr>
                            </>
                          )
                        }
                      }
                    }
                  : undefined
              }
              {...tableProps}
              pagination={false}
            />
          )}

          {displayType === 'list' && (
            <List
              header={tableProps.title}
              pagination={false}
              dataSource={tableData}
              renderItem={(item: any, index) => (
                <Fragment key={item.id || item.code || `item-${index}`}>{rowItemRender?.(item, index)}</Fragment>
              )}
            />
          )}
        </div>
      )
    }
  )
