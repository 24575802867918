import { Avatar, Button, Table } from 'antd'
import lodash from 'lodash'
import React, { useContext, useMemo } from 'react'
import EmptyData from 'src/Component/EmptyData'
import SkeletonTable, { SkeletonTableColumnsType } from 'src/Component/SkeletonTable'
import OrderItemInterface, { VariantPropertiesInterface } from 'src/Interface/OrderItemInterface'
import { trans } from 'src/resources/localization/Localization'
import { formatMoneyByUnit } from 'src/Util/string'
import { useState } from 'react'
import IMAGE_DEFAULT from 'src/resources/images/empty-image.jpg'
import RowExtended from '../RowExtend'
import CreateOrEditProductModal from './CreateOrEditProductModal'
import { confirm } from 'src/Component/basic/ModalCommon'
import { ShipmentsApi } from 'src/Api/ShipmentsApi'
import { useParams } from 'react-router-dom'
import { ShipmentDetailContext } from '../../ShipmentDetailContext'
import { NotificationCommon } from 'src/Component/basic/NotificationCommon'
import { ColumnsType } from 'antd/es/table/interface'
import { PERMISSION_SHIPMENTS_PRODUCT_UPDATE } from 'src/Config/permission'
import SecurityService from 'src/Util/SecurityService'

interface OrderItemsProps {
  items: OrderItemInterface[]
  loading: boolean
}

const OrderItems: React.FC<OrderItemsProps> = (props) => {
  const { items, loading } = props
  const orderingCurrency = 'CNY'

  const { code } = useParams()
  const { shipmentStatusObject, refreshProducts } = useContext(ShipmentDetailContext)

  const [productToEdit, setProductToEdit] = useState<OrderItemInterface>()

  const onDelete = (record: OrderItemInterface) => {
    confirm({
      title: 'Xác nhận xóa sản phẩm',
      content: 'Bạn có chắc chắn muốn xóa sản phẩm này?',
      onOk: () => {
        ShipmentsApi.deleteProduct(code!, record.code)
          .then(() => {
            NotificationCommon.success({ message: 'Xóa sản phẩm thành công' })
            refreshProducts()
          })
          .catch(NotificationCommon.notifyHttpError)
      }
    })
  }

  const isCanUpdateProduct = useMemo(
    () => SecurityService.can(PERMISSION_SHIPMENTS_PRODUCT_UPDATE) && shipmentStatusObject?.productUpdatable,
    [shipmentStatusObject]
  )

  const columns: ColumnsType<OrderItemInterface> = [
    {
      title: trans('orderItem.nameOfItem'),
      key: 'name',
      render: (record: OrderItemInterface) => {
        const properties = lodash.get(record, 'variantProperties', [])
        return (
          <div>
            <div className="flex">
              <div className="mg-r-12">
                <a href={lodash.get(record, 'url')} className="cursor-pointer" target="_blank" rel="noreferrer">
                  <Avatar
                    shape={'square'}
                    size={60}
                    src={record.image ? lodash.get(record, 'image', '') : IMAGE_DEFAULT}
                    className={'rad-4'}
                    style={{ border: 'none' }}
                  />
                </a>
                <div className="mt-2 font-medium">#{record.code}</div>
              </div>

              <div>
                <div>
                  <a href={lodash.get(record, 'url')} className="font-medium" target="_blank" rel="noreferrer">
                    <span className="font-size-14">{record.name ?? '---'}</span>
                  </a>
                  <br />
                  {record.translatedName && <span className="font-size-14">{record.translatedName}</span>}
                </div>

                <div className="flex mg-t-4">
                  {properties.length > 0
                    ? properties.map((pro: VariantPropertiesInterface) => (
                        <span key={pro.id} className=" mg-r-20">
                          <span className="txt-color-gray font-size-12">{pro.name}</span>
                          <span className="txt-color-black font-size-12">{pro.value}</span>
                        </span>
                      ))
                    : '---'}
                </div>
              </div>
            </div>
            <div className="grid mt-1 gap-1 font-size-12">
              <span>
                <span className="txt-color-gray">
                  <i className="fa-light fa-store"></i>
                </span>
                <span className="mg-l-4">{record.merchantName ?? '---'}</span>
              </span>

              <span>
                <span className="txt-color-gray">
                  <i className="fa-light fa-location-dot"></i>
                </span>
                <span className="mg-l-4">{record.merchantContact ?? '---'}</span>
              </span>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Số lượng',
      key: 'info',
      align: 'right',
      width: '15%',
      render: (record: OrderItemInterface) => {
        return <span className="font-medium">{record.orderedQuantity ?? '---'}</span>
      }
    },
    {
      title: <span className="robotoregular font-size-12 line-h-22 txt-color-gray">Đơn giá</span>,
      key: 'priceByProduct',
      align: 'right',
      width: '15%',
      render: (record: OrderItemInterface) => {
        const originalPrice = formatMoneyByUnit(lodash.get(record, 'price'), orderingCurrency)
        return <span className="font-medium">{originalPrice}</span>
      }
    },
    {
      title: 'Tác vụ',
      key: 'action',
      align: 'right',
      width: '15%',
      render: (record: OrderItemInterface) => {
        return (
          <div className="flex gap-4 justify-end">
            <Button
              size="small"
              className="!border-none !bg-[transparent]"
              onClick={() => setProductToEdit(record)}
              disabled={!isCanUpdateProduct}
              icon={<i className="fa-regular fa-pen-to-square" />}
            />

            <Button
              size="small"
              danger
              className="!border-none !bg-[transparent]"
              icon={<i className="fa-regular fa-trash" />}
              disabled={!isCanUpdateProduct}
              onClick={() => onDelete(record)}
            />
          </div>
        )
      }
    }
  ]

  const components = { body: { row: RowExtended } }

  return (
    <>
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]} className={`main-table`}>
        <Table
          rowKey={(record: OrderItemInterface) => record.id}
          columns={columns}
          components={components}
          dataSource={items}
          locale={{ emptyText: <EmptyData text={'Chưa có sản phẩm nào'} /> }}
          className="table-common table-no-container-style"
          pagination={{
            total: items.length,
            pageSize: 25,
            position: ['bottomRight'],
            locale: { items_per_page: `/ ${trans('orders.page')}` },
            hideOnSinglePage: true,
            showSizeChanger: false
          }}
          scroll={{ x: 572 }}
          size="small"
          onRow={(record, index) => {
            const rowClassesByIdx = index! % 2 === 0 ? 'row-dark' : 'row-light'
            return { ...record, index, rowClassesByIdx }
          }}
        />
      </SkeletonTable>

      <CreateOrEditProductModal productToEdit={productToEdit} onCancel={() => setProductToEdit(undefined)} />
    </>
  )
}
export default OrderItems
