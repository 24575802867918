import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ProviderCommand from '../../Interface/ProviderInterface'
import DefaultLayout from '../../Component/Layout/Default'
import { getCleanFormFilter } from '../../Util/string'
import { trans } from '../../resources/localization/Localization'
import '../../resources/scss/screens/_shipments.scss'
import { ShipmentsCriteria } from '../../Criteria/ShipmentsCriteria'
import ProviderItems from './Data/index'
import './_provider.scss'
import { ProviderApi } from '../../Api/ProviderApi'
import { notification } from 'antd'

const Shipments = (props: any) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [total, setTotal] = useState(0)
	const [page, setPage] = useState(Number(searchParams.get('page')))
	const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
	const [providers, setProviders] = useState<Array<ProviderCommand>>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [filter, setFilter] = useState<ShipmentsCriteria>({})
	const [isSkeleton, setIsSkeleton] = useState(true)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleOk = () => {
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	const getListProvider = useCallback(async () => {
		const page = searchParams.get('page')
		const size = searchParams.get('size')
		const temp = {
			page,
			size,
		}
		setFilter((prevState) => ({ ...prevState, ...getCleanFormFilter(temp) }))
		setTimeout(() => setIsSkeleton(false), 1000)
		try {
			const response = await ProviderApi.filter(getCleanFormFilter(temp))
			const currentPage = lodash.get(response, 'headers.x-page-number')
			const total = lodash.get(response, 'headers.x-total-count')
			setProviders(response.data)
			setPage(parseInt(currentPage) + 1)
			setTotal(parseInt(total))
			setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
			setTimeout(() => setIsSkeleton(false), 1000)
		} catch (err) {
			setTimeout(() => setIsSkeleton(false), 1000)
		}
	}, [searchParams])

	useEffect(() => {
		getListProvider()
	}, [getListProvider])

	const handleFilter = (params: any) => {
		setFilter({ ...params })
		setSearchParams({ ...params })
	}

	const handleAddProvider = (body: any) => {
		setIsLoading(true)

		ProviderApi.createProvider(body)
			.then((res) => {
				notification.success({
					key: 'SUCCESS',
					message: trans('provider.successTitle'),
					description: trans('provider.success'),
					duration: 3,
				})
				setIsSkeleton(true)
				setTimeout(() => {
					setIsSkeleton(false)

					setIsModalOpen(false)
					getListProvider()
				}, 300)
			})
			.catch((err) => {
				if (err.response.data.title === 'provider_existed') {
					notification.error({
						message: trans('provider.errorTitle'),
						description: trans('provider.provider_existed'),
						duration: 3,
					})
				} else {
					notification.error({
						message: trans('provider.errorTitle'),
						description: 'Đã có lỗi xảy ra. Vui lòng liên hệ kỹ thuật',
						duration: 3,
					})
				}
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<DefaultLayout title={trans('provider.list')} {...props}>
			{/* <Filter
				handleFilter={handleFilter}
				statuses={shipmentStatus}
				marketPalaces={marketPalaces}
				setFilter={(obj: ShipmentsCriteria) => setFilter(obj)}
				filter={filter}
				loadingStatuses={loadingStatuses}
				loadingMarkets={loadingMarketPalaces}
				providers={providers}
			/> */}
			<ProviderItems
				handleAddProvider={handleAddProvider}
				isSkeleton={isSkeleton}
				isLoading={isLoading}
				total={total}
				page={page}
				size={pageSize}
				data={providers}
				// providers={providers}
				// statuses={shipmentStatus}
				handleFilter={handleFilter}
				setPage={(val: number) => setPage(val)}
				setPageSize={(val: number) => setPagesize(val)}
				filter={filter}
				isModalOpen={isModalOpen}
				handleOk={handleOk}
				handleCancel={handleCancel}
				setIsModalOpen={setIsModalOpen}
			/>
		</DefaultLayout>
	)
}

export default Shipments

