import { useEffect, useMemo, useState } from 'react'
import { CategoryApi } from 'src/Api/CategoryApi'
import IShipmentStatus from 'src/Interface/StatusInterface'

function useShipmentStatus(shipmentStatus?: string) {
  const [statuses, setStatuses] = useState<IShipmentStatus[]>([])

  const { statusColorMapping, statusNameMapping } = useMemo(() => {
    const statusColorMapping: Record<string, string> = {}
    const statusNameMapping: Record<string, string> = {}

    statuses?.forEach((status) => {
      statusColorMapping[status.shipmentCode] = status.color
      statusNameMapping[status.shipmentCode] = status.name
    })
    return { statusColorMapping, statusNameMapping }
  }, [statuses])

  const shipmentStatusObject = useMemo(() => {
    return statuses?.find((status) => status.shipmentCode === shipmentStatus)
  }, [shipmentStatus, statuses])

  useEffect(() => {
    CategoryApi.getShipmentStatuses()
      .then((response) => {
        setStatuses(response.data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }, [])

  return {
    statuses,
    shipmentStatusObject,
    statusColorMapping,
    statusNameMapping
  }
}

export default useShipmentStatus
