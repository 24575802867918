import { createContext } from 'react'
import ShipmentDetailInterface from 'src/Interface/ShipmentDetailInterface'
import IShipmentStatus from 'src/Interface/StatusInterface'

type ShipmentDetailContextType = {
  shipmentStatusObject: IShipmentStatus | undefined
  details: ShipmentDetailInterface | undefined
  refreshDetails: (isRefreshMilestone?: boolean, isShowLoading?: boolean) => void
  refreshProducts: () => void
}

export const ShipmentDetailContext = createContext<ShipmentDetailContextType>({
  shipmentStatusObject: undefined,
  details: undefined,
  refreshDetails: () => null,
  refreshProducts: () => null
})
