import { useCallback, useEffect, useState } from 'react'
import { CategoryApi } from '../../Api/CategoryApi'
import ProviderInterface from '../../Interface/ProviderInterface'
import { useQuery } from 'react-query'
import { Avatar } from 'antd'

export const useProvider = () => {
  const { data } = useQuery({ queryFn: CategoryApi.getProviders, refetchOnWindowFocus: false })
  const providers: ProviderInterface[] = data?.data

  const [providerNameMapping, setProviderNameMapping] = useState({})
  const [providerOrderLinkMapping, setProviderOrderLinkMapping] = useState<{ [key: string]: string }>({})
  const [providerOrderShipmentLinkMapping, setProviderOrderShipmentLinkMapping] = useState<{ [key: string]: string }>(
    {}
  )

  useEffect(() => {
    if (!providers) return
    const temp: any = {}
    const orderLinks: any = {}
    const shimentLinks: any = {}
    providers.forEach((provide) => {
      temp[provide.code] = provide.name
      orderLinks[provide.code] = provide.redirectUrl.order
      shimentLinks[provide.code] = provide.redirectUrl.shipment
    })
    setProviderNameMapping(temp)
    setProviderOrderLinkMapping(orderLinks)
    setProviderOrderShipmentLinkMapping(shimentLinks)
  }, [providers])

  const getOrderLink: GetOrderLinkType = (provider, code) => {
    return providerOrderLinkMapping[provider]?.replace('{code}', code)
  }

  const getOrderShipmentLink: GetOrderLinkType = (provider, code) => {
    return providerOrderShipmentLinkMapping[provider]?.replace('{code}', code)
  }

  const getProviderOptions = useCallback(() => {
    if (!providers) return []

    return providers?.map((p) => ({
      value: p.code,
      label: (
        <span>
          <Avatar src={p.logo} size={24} /> {p.name}
        </span>
      ),
    }))
  }, [providers])

  const getProviderCurrency = useCallback(
    (providerCode) => {
      const provider = providers.find((provider: ProviderInterface) => provider.code === providerCode)
      if (provider?.currency) {
        return JSON.parse(provider?.currency).code
      }
    },
    [providers]
  )

  return {
    providers: providers ?? [],
    providerNameMapping,
    getOrderLink,
    getOrderShipmentLink,
    getProviderOptions,
    getProviderCurrency,
  }
}

export type GetOrderLinkType = (provider: string, code: string) => string
