import React from 'react'
import SecurityService from '../Util/SecurityService'
import {
  PERMISSION_CUSTOMER_LIST_VIEW,
  PERMISSION_DELIVERY_REQUEST_VIEW,
  PERMISSION_ORDERS_VIEW,
  PERMISSION_PACKAGE_PREFERENCE,
  PERMISSION_PROVIDER_VIEW,
  PERMISSION_SHIPMENTS_VIEW,
  PERMISSION_TRANSACTION_CREATION,
  PERMISSION_TRANSACTION_VIEW
} from './permission'
import { trans } from '../resources/localization/Localization'

export type showFunc = () => boolean

export interface IMenuItem {
  key: string
  title: string
  icon: any
  url: string
  show: boolean | showFunc
  children?: Array<IMenuItem>
}

export const menuItems: IMenuItem[] = [
  {
    key: 'DASHBOARD2',
    title: trans('sidebar.dashboard'),
    show: true,
    icon: <i className="fas fa-home" />,
    url: '/'
  },
  {
    key: 'ORDERS',
    title: trans('sidebar.order'),
    show: SecurityService.can(PERMISSION_ORDERS_VIEW),
    icon: <i className="fa-solid fa-paste" />,
    url: '/orders'
  },
  {
    key: 'SHIPMENTS',
    title: trans('sidebar.shipments'),
    show: SecurityService.can(PERMISSION_SHIPMENTS_VIEW),
    icon: <i className="fa-solid fa-paste" />,
    url: '/shipments'
  },
  {
    key: 'DELIVERY-REQUEST',
    title: 'Danh sách yêu cầu giao',
    show: SecurityService.can(PERMISSION_DELIVERY_REQUEST_VIEW),
    icon: <i className="fa-regular fa-hand-holding-box  " />,
    url: '/delivery-request'
  },
  {
    key: 'TRANSACTIONS',
    title: 'Lịch sử giao dịch',
    show: SecurityService.can(PERMISSION_TRANSACTION_VIEW),
    icon: <i className="fa-solid fa-money-bill"></i>,
    url: '/transactions'
  },
  {
    key: 'TRANSACTION-CREATION',
    title: 'Tạo giao dịch điều chỉnh',
    show: SecurityService.can(PERMISSION_TRANSACTION_CREATION),
    icon: <i className="fa-solid fa-money-bill-transfer"></i>,
    url: '/transaction/create'
  },
  {
    key: 'CUSTOMERS',
    title: trans('sidebar.customers'),
    show: SecurityService.can(PERMISSION_CUSTOMER_LIST_VIEW),
    icon: <i className="fa-solid fa-users" />,
    url: '/customers'
  },
  {
    key: 'provider',
    title: trans('sidebar.provider-management'),
    show: SecurityService.can(PERMISSION_PROVIDER_VIEW),
    icon: <i className="fa-solid fa-paste" />,
    url: '/provider-management'
  },
  {
    key: 'PREFERENCES',
    title: trans('sidebar.preference'),
    show: SecurityService.can(PERMISSION_PACKAGE_PREFERENCE),
    icon: <i className="fa-solid fa-gear"></i>,
    url: '/preferences'
  }
]

