/* eslint-disable array-callback-return */
import { Col, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import lodash from "lodash";
import Item from "./Item";
import { formatMoneyByUnit } from "../../../Util/string";
import OrderDetailInterface, {
  FeeInterface,
} from "../../../Interface/OrderDetailInterface";
import { trans } from "../../../resources/localization/Localization";
import Skeleton from "react-loading-skeleton";
interface FeeBoxProps {
  details?: OrderDetailInterface;
  currencyOfProvider: string;
  loading: boolean;
}
const FeeBox = (props: FeeBoxProps) => {
  const [totalAmount, setTotalAmount] = useState<number | null>(0);
  const [exchangedTotalAmount, setExchangedTotalAmount] = useState<
    number | null
  >(0);
  const [merchantShippingCost, setMerchantShippingCost] = useState<
    number | null
  >(0);
  const [exchangedMerchantShippingCost, setExchangedMerchantShippingCost] =
    useState<number | null>(0);
  const [totalFee, setTotalFee] = useState<number | null>(0);
  const [grandTotal, setGrandTotal] = useState<number | null>(0);
  const [totalRefund, setTotalRefund] = useState<number | null>(0);
  const [totalPaid, setTotalPaid] = useState<number | null>(0);
  const [totalUnpaid, setTotalUnpaid] = useState<number | null>(0);
  const [currencyMarketplace, setCurrencyMarketplace] = useState("");
  const { details, currencyOfProvider, loading } = props;
  useEffect(() => {
    if (details) {
      setTotalAmount(lodash.get(details, "totalAmount", 0));
      setExchangedTotalAmount(lodash.get(details, "exchangedTotalAmount", 0));
      setMerchantShippingCost(lodash.get(details, "merchantShippingCost", 0));
      setExchangedMerchantShippingCost(
        lodash.get(details, "exchangedMerchantShippingCost", 0)
      );
      setTotalPaid(lodash.get(details, "totalPaid", 0));
      setTotalUnpaid(lodash.get(details, "totalUnpaid", 0));
      setTotalRefund(lodash.get(details, "totalRefund", 0));
      setGrandTotal(lodash.get(details, "grandTotal", 0));
      setCurrencyMarketplace(lodash.get(details, "currency", ""));
      let totalFee = 0;
      details?.fees?.map((x: FeeInterface) => {
        if (!x.free) {
          if (x.manual && x.actualAmount) {
            totalFee = totalFee + x.actualAmount;
          } else if (!x.manual && x.provisionalAmount) {
            totalFee = totalFee + x.provisionalAmount;
          }
        }
      });
      setTotalFee(totalFee);
      let total =
        totalFee +
        lodash.get(details, "exchangedTotalAmount", 0)! +
        lodash.get(details, "exchangedMerchantShippingCost", 0)!;
      setGrandTotal(total);
      setTotalUnpaid(
        total -
          lodash.get(details, "totalPaid", 0)! +
          lodash.get(details, "totalRefund", 0)!
      );
    }
  }, [details]);

  const renderItemFee = (item: FeeInterface, index: number) => {
    return (
      <Row key={index} className="flex justify-between items-center">
        <Col className="items-center flex">
          <span className={`txt-size-h8 txt-color-black`}>
            {!loading ? (
              lodash.get(item, "type.name", "--")
            ) : (
              <Skeleton width={60} height={20} />
            )}
          </span>
        </Col>
        <Col>
          {item.manual || item.free ? (
            <Row className={"justify-content-end"}>
              <span
                className={
                  "txt-size-h8 txt-color-secondary txt-linethrough mg-r-5"
                }
              >
                {item.provisionalAmount &&
                  formatMoneyByUnit(item.provisionalAmount, currencyOfProvider)}
              </span>
              {!loading ? (
                <span className={"txt-size-h8 txt-color-black"}>
                  {item.free
                    ? "Miễn phí"
                    : formatMoneyByUnit(item.actualAmount, currencyOfProvider)}
                </span>
              ) : (
                <Skeleton width={60} height={20} />
              )}
            </Row>
          ) : (
            <Row className={"justify-content-end"}>
              <span className={"txt-size-h8 txt-color-black"}>
                {!loading ? (
                  formatMoneyByUnit(item.provisionalAmount, currencyOfProvider)
                ) : (
                  <Skeleton width={60} height={22} />
                )}
              </span>
            </Row>
          )}
        </Col>
      </Row>
    );
  };
  const renderFee = () => {
    return (
      <div className={"mg-l-8"}>
        {details?.fees?.map((x: FeeInterface, index: number) =>
          renderItemFee(x, index)
        )}
      </div>
    );
  };
  return (
    <div className="fees">
      <div className="fees-calculate">
        <Item
          number={"(1)"}
          label={trans("order.cash")}
          subValue={totalAmount}
          value={exchangedTotalAmount}
          currencyValue={currencyOfProvider}
          currencySubValue={currencyMarketplace}
          loading={loading}
        />
        <Item
          number={"(2)"}
          label={trans("orders.merchantShippingCost")}
          subValue={merchantShippingCost}
          value={exchangedMerchantShippingCost}
          currencyValue={currencyOfProvider}
          currencySubValue={currencyMarketplace}
          loading={loading}
        />
        <Item
          number={"(3)"}
          label={trans("orders.totalFees")}
          value={totalFee}
          currencyValue={currencyOfProvider}
          loading={loading}
        />
        {renderFee()}
        <Divider className="mg-0 bg-color-orange2" />
        <Item
          number={`(4) = (1) + (2) + (3)`}
          valueClass={"text"}
          labelClass={"text-reg text-500"}
          label={trans("order.totalCash")}
          value={grandTotal}
          currencyValue={currencyOfProvider}
          loading={loading}
        />
      </div>
      <div className="fees-summary">
        <Item
          number={"(5)"}
          label={trans("order.paid")}
          value={totalPaid}
          currencyValue={currencyOfProvider}
          loading={loading}
        ></Item>
        <Item
          number={"(6)"}
          label={trans("order.refund")}
          value={totalRefund}
          currencyValue={currencyOfProvider}
          loading={loading}
        ></Item>
        {details && details.status !== "CANCELLED" ? (
          <Item
            number={"(7) = (4) - (5) + (6)"}
            valueClass={"text-reg text-500 text-red"}
            labelClass={"text-reg text-500"}
            label={
              totalUnpaid && totalUnpaid > 0
                ? trans("order.needPay")
                : trans("order.excessCash")
            }
            value={totalUnpaid ? Math.abs(totalUnpaid) : totalUnpaid}
            currencyValue={currencyOfProvider}
            loading={loading}
          ></Item>
        ) : null}
      </div>
    </div>
  );
};

export default FeeBox;
