import { Button, Col, Collapse, CollapseProps, Form, Row } from 'antd'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { useSearchParams } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { cloneDeep } from 'lodash'
import './style.scss'
import { SelectCommonProps } from '../inputs/SelectCommon'
import { FloatLabel, FloatLabelProps } from '../basic/FloatLabel'

export interface IOption {
  label: string | ReactNode
  value: string | number | undefined
}

interface IFilterItem extends Omit<FloatLabelProps, 'form' | 'type'> {
  type: 'select' | 'text' | 'datetime' | 'date-range' | 'checkbox' | 'list-select' | 'number'
  name: string
  options?: SelectCommonProps['options'] // option of select input type
  defaultValue?: string
  label?: string | string[]
  fieldName?: string[] // for date-range input type
  autoSearch?: boolean // search ngay khi chọn
}

export interface FilterCommonProps {
  firstItems: IFilterItem[]
  secondItems?: IFilterItem[]
}

export const FilterCommon: FC<FilterCommonProps> = ({ firstItems, secondItems = [] }) => {
  const [form] = useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeKey, setActiveKey] = useState<string[]>(['1'])
  const [secondActiveKey, setSecondActiveKey] = useState<string>()
  const [isShowRefreshButton, setIsShowRefreshButton] = useState(false)

  const items = useMemo(() => [...firstItems, ...secondItems], [firstItems, secondItems])

  const handleSearch = () => {
    const values = form.getFieldsValue()
    const cleanedValues: any = {}
    const valueClone = cloneDeep(values)
    items.forEach(({ name, type, fieldName }) => {
      const value = valueClone[name]
      if (!value) return
      if (Array.isArray(value) && value.length < 1) return

      if (type === 'date-range' && value[0] && value[1] && fieldName?.length === 2) {
        cleanedValues[fieldName[0]] = (value[0] as Moment).format('YYYY-MM-DD')
        cleanedValues[fieldName[1]] = (value[1] as Moment).format('YYYY-MM-DD')
        return
      }
      cleanedValues[name] = typeof value === 'string' ? value.trim() : value
    })
    if (Object.keys(cleanedValues).length < 1) {
      setIsShowRefreshButton(false)
    }
    if (Object(cleanedValues).length < 1) {
      setIsShowRefreshButton(false)
    }
    setTimeout(() => {
      setSearchParams(cleanedValues)
    }, 100)
  }

  const initialValueForDateRangeInput = useCallback(
    (fieldName: string[], name: string) => {
      const [startFieldName, endFieldName] = fieldName
      const startValue = searchParams.get(startFieldName)
      const endValue = searchParams.get(endFieldName)

      if (!startValue && !endValue) {
        form.resetFields([name])
      } else {
        form.setFieldValue(name, [moment(startValue), moment(endValue)])
      }
    },
    [form, searchParams]
  )

  const updateFormFromURL = useCallback(() => {
    items.forEach(({ name, type, defaultValue, fieldName, ...item }) => {
      let value: any = searchParams.get(name)

      if (type === 'date-range' && fieldName?.length === 2) {
        initialValueForDateRangeInput(fieldName, name)
        return
      }

      if (type === 'list-select') {
        value = searchParams.getAll(name)
      }

      if (type === 'select' && item.selectProps?.mode === 'multiple') {
        value = searchParams.getAll(name)
      }

      if (value) {
        form.setFieldValue(name, value)
      } else {
        form.setFieldValue(name, undefined)
      }

      if (!value && defaultValue) {
        form.setFieldValue(name, defaultValue)
        searchParams.set(name, defaultValue)
        setSearchParams(searchParams)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValueForDateRangeInput, items, searchParams])

  useEffect(() => {
    let isShow = false
    searchParams.forEach((value, key) => {
      if (key === 'page') return
      if (key === 'pageSize') return
      if (isShow) return

      isShow = !!value?.trim()
    })
    if (isShow) setIsShowRefreshButton(true)
  }, [searchParams])

  useEffect(() => {
    updateFormFromURL()
  }, [updateFormFromURL])

  const resetForm = () => {
    setIsShowRefreshButton(false)
    items.forEach(({ name, defaultValue, fieldName }) => {
      if (defaultValue) {
        // ko reset default field
        return
      }

      searchParams.delete(name)
      if (fieldName) {
        searchParams.delete(fieldName[0])
        searchParams.delete(fieldName[1])
      }
      form.setFieldValue(name, defaultValue)
    })
    setSearchParams(searchParams)
  }

  const onChangeListSelectField = (value: IOption['value'], { name, autoSearch }: IFilterItem) => {
    const values: IOption['value'][] = form.getFieldValue(name) || []
    if (!value) {
      form.setFieldValue(name, undefined)
      return
    }
    const index = values?.findIndex((v: IOption['value']) => v === value)
    if (index === -1) {
      form.setFieldValue(name, [...values, value])

      return
    }

    form.setFieldValue(
      name,
      values.filter((v) => v !== value)
    )

    if (autoSearch) {
      handleSearch()
    }
  }

  const renderListSelectField = (item: IFilterItem) => {
    const { name } = item
    return (
      <>
        <Form.Item name={[name]} noStyle />
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const values: IOption['value'][] = getFieldValue(name) || []

            return (
              <div className="flex gap-12 flex-wrap">
                {item.options?.map((option) => (
                  <span
                    key={option.value}
                    onClick={() => onChangeListSelectField(option.value, item)}
                    className={`${values.includes(option.value) ? 'active' : ''}   status-item`}>
                    {option.label}
                  </span>
                ))}
              </div>
            )
          }}
        </Form.Item>
      </>
    )
  }

  const renderItems = (item: IFilterItem) => {
    const { name, label } = item
    if (item.type === 'select') {
      return (
        <FloatLabel
          form={form}
          label={label}
          name={name}
          type="select"
          selectProps={{
            options: item.options,
            onChange: item.autoSearch ? () => handleSearch() : undefined,
            allowClear: !item.defaultValue,
            ...(item.selectProps ?? {})
          }}
        />
      )
    }

    if (item.type === 'date-range') {
      return <FloatLabel name={name} form={form} type="date-range" label={label} />
    }

    if (item.type === 'number') {
      return (
        <FloatLabel
          name={name}
          form={form}
          type="number"
          label={label}
          inputNumberProps={{ decimalLength: 0, isAllowFormat: false }}
        />
      )
    }

    return <FloatLabel form={form} label={label} name={name} />
  }

  const renderIcon: CollapseProps['expandIcon'] = ({ isActive }) =>
    isActive ? (
      <i className="fa-sharp fa-regular fa-angle-down"></i>
    ) : (
      <i className="fa-sharp fa-regular fa-angle-right"></i>
    )

  const isFirstItemEmpty = useMemo(() => {
    if (!firstItems) return true

    return firstItems.length === 1 && firstItems[0].type === 'list-select' && !firstItems[0].options?.length
  }, [firstItems])

  useEffect(() => {
    if (isFirstItemEmpty) setSecondActiveKey('2')
  }, [isFirstItemEmpty])

  return (
    <div className="filter-form">
      <Collapse
        activeKey={activeKey}
        onChange={(value) => {
          setActiveKey(value as string[])
        }}
        bordered={false}
        className="first-collapse"
        expandIcon={renderIcon}>
        <Collapse.Panel header={<div>Tìm kiếm</div>} key={1}>
          <>
            <Form
              form={form}
              onValuesChange={() => {
                setIsShowRefreshButton(true)
              }}
              onFinish={handleSearch}>
              <Row gutter={[24, 0]}>
                {!isFirstItemEmpty &&
                  firstItems.map((item, index) => (
                    <Col
                      key={item.name.toString()}
                      xs={24}
                      md={item.type === 'list-select' ? 24 : 8}
                      className={index < firstItems.length - 1 ? 'mb-3 md:mb-0' : ''}>
                      {item.type === 'list-select' ? renderListSelectField(item) : renderItems(item)}
                    </Col>
                  ))}

                {/* phần 2 */}
                <Col xs={24}>
                  {!!secondItems.length && (
                    <Collapse
                      activeKey={secondActiveKey}
                      onChange={(value) => {
                        setSecondActiveKey(value[0])
                      }}
                      className={`second-collapse transition-all ${secondActiveKey === '2' ? '!mt-3 md:!mt-4' : ''}`}
                      bordered={false}>
                      <Collapse.Panel header={null} key={'2'}>
                        <Row gutter={[20, 20]} className="!gap-y-3 md:!gap-y-[20px]">
                          {secondItems.map((item) => (
                            <Col key={item.name.toString()} xs={24} md={8}>
                              {renderItems(item)}
                            </Col>
                          ))}
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </Col>

                <Col xs={24} className="mt-3 md:mt-4">
                  <div className="flex items-center justify-between">
                    <span
                      className="text-xs cursor-pointer"
                      onClick={() => setSecondActiveKey(secondActiveKey ? undefined : '2')}>
                      {!isFirstItemEmpty && !!secondItems.length && (
                        <>
                          <i
                            className={`fa-sharp fa-regular ${
                              secondActiveKey ? 'fa-angle-up' : 'fa-angle-down'
                            } mr-1`}></i>{' '}
                          {secondActiveKey ? 'Thu gọn' : 'Mở rộng'}
                        </>
                      )}
                    </span>
                    <div className="flex gap-12 justify-end items-center">
                      {isShowRefreshButton && (
                        <span onClick={resetForm} className="!text-[#707070] !text-xs cursor-pointer">
                          <i className="fa-solid fa-rotate-right mr-1"></i> Làm mới bộ lọc
                        </span>
                      )}

                      <Form.Item>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="font-medium"
                          icon={<i className="fa-regular fa-search" />}>
                          Tìm kiếm
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}
