import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MilestoneInterface from '../../Interface/MilestoneInterface'
import OrderItemInterface from '../../Interface/OrderItemInterface'
import DefaultLayout from '../../Component/Layout/Default'
import Finance from './Finance'
import Heading from './Heading/Heading'
import Information from './Information'
import TabLayout from './TabLayout'
import TrackOrder from './TrackOrder'
import { trans } from '../../resources/localization/Localization'
import SecurityService from '../../Util/SecurityService'
import { PERMISSION_SHIPMENT_HISTORY_VIEW } from '../../Config/permission'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ShipmentsApi } from '../../Api/ShipmentsApi'
import ShipmentDetailInterface from '../../Interface/ShipmentDetailInterface'
import { Row } from 'antd'
import useWindowDimensions from '../../Util/dimention'
import { useProvider } from '../../hooks/fetch/useProvider'
import useShipmentStatus from 'src/hooks/fetch/useShipmentStatus'
import { useMidmile } from 'src/hooks/fetch/useMidmile'
import { ShipmentDetailContext } from './ShipmentDetailContext'

const ShipmentDetail = () => {
  const { code } = useParams()

  const [details, setDetails] = useState<ShipmentDetailInterface>()
  const { providers } = useProvider()
  const [milestones, setMilestones] = useState<Array<MilestoneInterface>>([])

  const [productItems, setProductItems] = useState<Array<OrderItemInterface>>([])
  const [transactions, setTransactions] = useState([])

  const [loadingTransactions, setLoadingTransactions] = useState(false)
  const [loading, setLoading] = useState(false)
  const [expand, setExpand] = useState(true)
  const { statuses, statusNameMapping, statusColorMapping, shipmentStatusObject } = useShipmentStatus(details?.status)
  const { midmileList } = useMidmile()

  const getMilestones = useCallback(async () => {
    try {
      const response = await ShipmentsApi.getMilestone(code!)
      setMilestones(response.data)
    } catch (err) {}
  }, [code])

  const getDetails = useCallback(
    async (isRefreshMilestone = false, isAllowLoading = true) => {
      setLoading(isAllowLoading)
      try {
        if (isRefreshMilestone) {
          getMilestones()
        }
        const response = await ShipmentsApi.getDetails(code!)
        setDetails(response.data)
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    },
    [code, getMilestones]
  )

  const getItems = useCallback(async () => {
    try {
      const response = await ShipmentsApi.getItems(code!)
      setProductItems(response.data)
    } catch (err) {}
  }, [code])

  const getTransactions = useCallback(async () => {
    setLoadingTransactions(true)
    try {
      const response = await ShipmentsApi.getTransactions(code!)
      setTransactions(response.data)
      setLoadingTransactions(false)
    } catch (err) {
      setLoadingTransactions(false)
    }
  }, [code])

  useEffect(() => {
    getDetails()
    getItems()
    getMilestones()
    getTransactions()
  }, [getDetails, getItems, getMilestones, getTransactions])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { width } = useWindowDimensions()

  return (
    <DefaultLayout title={`${trans('shipment.details')} #${code}`}>
      <ShipmentDetailContext.Provider
        value={{ details, shipmentStatusObject, refreshProducts: getItems, refreshDetails: getDetails }}>
        <div className="shipment">
          <Heading
            details={details}
            midmileList={midmileList}
            loading={loading}
            statuses={statuses}
            statusColorMapping={statusColorMapping}
            statusNameMapping={statusNameMapping}
          />

          <Row className="shipment-content mg-t-12 gap-12 flex-nowrap">
            <div className="shipment-content__info flex flex-col flex-basis-80pc flex-wrap justify-start">
              <div
                className={`shipment-content__info-finance lg:flex ${
                  expand && width < 1024 && width > 768 ? 'pd-bt-40' : ''
                } ${!expand ? 'items-center pd-12 h-60' : width < 1024 ? 'pd-12' : 'pd-t-12 pd-bt-40 px-24'}`}>
                <Information
                  details={details}
                  providers={providers}
                  currencyOfProvider={''}
                  expand={expand}
                  loading={loading}
                />

                <Finance expand={expand} loading={loading} />

                <div
                  className={`h-fit ${expand ? 'shipment-expand' : 'shipment-collapse'} ${
                    loading ? 'flex align-items-center' : ''
                  } text-[12px]`}
                  onClick={() => setExpand(!expand)}>
                  {!loading ? (
                    <i className={`mg-r-6 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
                  ) : (
                    <Skeleton width={14} height={14} className="mg-r-6" />
                  )}

                  {!loading ? (
                    expand ? (
                      width < 769 ? (
                        ''
                      ) : (
                        trans('shipments.expand')
                      )
                    ) : width < 769 ? (
                      ''
                    ) : (
                      trans('shipments.collapse')
                    )
                  ) : (
                    <Skeleton width={50} height={20} />
                  )}
                </div>
              </div>

              <div>
                <TabLayout
                  items={productItems ?? []}
                  transactions={transactions}
                  loadingTransactions={loadingTransactions}
                  providers={providers}
                  currencyOfProvider={''}
                  updateDetail={getDetails}
                  fetchMileStone={getMilestones}
                  loading={loading}
                  details={details}
                />
              </div>
            </div>

            {SecurityService.can(PERMISSION_SHIPMENT_HISTORY_VIEW) && (
              <div className="shipment-milestones h-fit-content flex-basis-20pc">
                <h2 className="heading white-nowrap font-size-16 line-h-24">{trans('shipment.milestones')}</h2>

                <TrackOrder
                  milestones={milestones}
                  statusNameMapping={statusNameMapping}
                  details={details}
                  getMilestones={getMilestones}
                  loading={loading}
                />
              </div>
            )}
          </Row>
        </div>
      </ShipmentDetailContext.Provider>
    </DefaultLayout>
  )
}

export default ShipmentDetail
