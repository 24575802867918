import { useCallback, useEffect, useMemo, useState } from 'react'
import { ITransaction } from 'src/Screen/TransactionHistory/type'
import { CategoryApi } from 'src/Api/CategoryApi'
import { TransactionTypeInterface } from 'src/Interface/TransactionInterface'

export type TransactionMappingType = { [key in ITransaction['type']]: string }

export const useTransactionType = () => {
  const [transactionTypes, setTransactionType] = useState<TransactionTypeInterface[]>([])

  const { transactionTypeMappingToName } = useMemo(() => {
    const transactionTypeMappingToName: TransactionMappingType = {}

    transactionTypes.forEach((type) => {
      transactionTypeMappingToName[type.code] = type.name
    })

    return { transactionTypeMappingToName }
  }, [transactionTypes])

  const getTransactionTypeOptions = useCallback(
    () => transactionTypes.map((type) => ({ value: type.code, label: type.name })),
    [transactionTypes]
  )

  useEffect(() => {
    CategoryApi.getTransactionTypes().then((res) => {
      setTransactionType(res.data)
    })
  }, [])

  return { transactionTypeMappingToName, getTransactionTypeOptions }
}
