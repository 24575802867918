export const STANDARD_DATE_FORMAT = 'DD/MM/YYYY'
export const STANDARD_TIME_FORMAT = 'HH:mm'
export const STANDARD_DATE_TIME_FORMAT = `${STANDARD_TIME_FORMAT} ${STANDARD_DATE_FORMAT}`
export const STANDARD_DATE_TIME_FORMAT1 = ` ${STANDARD_DATE_FORMAT} | ${STANDARD_TIME_FORMAT}`
export const TIME_STAMP_FROM = 'timestampFrom'
export const TIME_STAMP_TO = 'timestampTo'
export const CREATED_AT_FROM = 'createdAtFrom'
export const CREATED_AT_TO = 'createdAtTo'
export const SELECT_CONNECTION = 'connection'
export const SELECT_PROFILE = 'username'
export const TAB_PRODUCT = 'TAB_PRODUCT'
export const TAB_INVOICE = 'TAB_INVOICE'
export const TAB_TICKET = 'TAB_TICKET'
export const TAB_TRANSACTION = 'TAB_TRANSACTION'
export const TAB_TRANSPORT = 'TAB_TRANSPORT'
export const TAB_PACKAGES = 'TAB_PACKAGES'
export const TAB_CLAIMS = 'TAB_CLAIMS'
export const USE_TYPE_METHOD_ORDER = 'ORDER'
export const PAYMENT_STATUS_SUCCESS = 'SUCCESS'
export const PAYMENT_STATUS_CANCELLED = 'CANCELLED'
export const PAYMENT_STATUS_FAILURE = 'FAILURE'
export const PAYMENT_STATUS_WAITING_CONFIRM = 'WAITING_CONFIRM'
export const PAYMENT_STATUS_WAITING_PAYMENT = 'WAITING_PAYMENT'
export const PAYMENT_REQUEST_CHANGE = 'order:payment_request:update'
export const PREFERENCE_GENERAL = 'PREFERENCE_GENERAL'
export const PREFERENCE_SHIPMENT = 'PREFERENCE_SHIPMENT'
export const PREFERENCE_BUY = 'PREFERENCE_BUY'
export const PREFERENCE_PAYMENT = 'PREFERENCE_PAYMENT'
export const PREFERENCE_GSA = 'PREFERENCE_GSA'
export const DATA_TABLE_LAYOUT = 'DATA_TABLE_LAYOUT'
export const DATA_TABLE_LIST = 'DATA_TABLE_LIST'


export const FORM_KEY_CUSTOMER = 'customer'
export const FORM_KEY_PROVIDER = 'provider'
export const FORM_KEY_PROVIDER_USER_NAME = 'providerUsername'
export const FORM_KEY_SUPPLIER = 'supplier'
export const SHIPMENT_TRANSACTIONS_REQUEST = 'SHIPMENT_TRANSACTIONS_REQUEST'
export const SHIPMENT_TRANSACTIONS_SUCCESS = 'SHIPMENT_TRANSACTIONS_SUCCESS'
export const SHIPMENT_TRANSACTIONS_FAIL = 'SHIPMENT_TRANSACTIONS_FAIL'
export const NO_BREAD_CRUMB = 'NO_BREAD_CRUMB'
export const SHIPMENT_STATUS_UPDATE = 'SHIPMENT_STATUS_UPDATE'
export const SHIPMENT_PACKAGE_UPDATE = 'SHIPMENT_PACKAGE_UPDATE'
export const EMPTY_INFO = '---'
export const SHIPMENT_PRODUCT_CREATE = 'SHIPMENT_PRODUCT_CREATE'
export const SHIPMENT_DECLARE_VALUE_UPDATE = 'SHIPMENT_DECLARE_VALUE_UPDATE'
export const SHIPMENT_WAYBILL_CREATE = 'SHIPMENT_WAYBILL_CREATE'
export const SHIPMENT_RECEIPT_DELETE = 'SHIPMENT_RECEIPT_DELETE'
export const SHIPMENT_FEE_UPDATE = 'SHIPMENT_FEE_UPDATE'
export const SHIPMENT_UPDATE = 'SHIPMENT_UPDATE'
export const SHIPMENT_DECLARE_VALUE_CREATE = 'SHIPMENT_DECLARE_VALUE_CREATE'
export const SHIPMENT_WAYBILL_DELETE = 'SHIPMENT_WAYBILL_DELETE'
export const SHIPMENT_DECLARE_VALUE_DELETE = 'SHIPMENT_DECLARE_VALUE_DELETE'
export const ACC_GSA_PROFILE = 'ACC_GSA_PROFILE'
export const SHIPMENT_PACKAGE_CREATE = 'SHIPMENT_PACKAGE_CREATE'
export const SHIPMENT_PRODUCT_UPDATE='SHIPMENT_PRODUCT_UPDATE'
export const TYPE_LASTMILE ="LASTMILE"
export const TYPE_M32 ="M32"