import { GetComponentProps } from 'rc-table/lib/interface'
import React from 'react'

interface RowExtendedProps extends GetComponentProps<any> {
	services: any
	index: number
	className: string
	rowClassesByIdx: string
	children: any
}
const RowExtended: React.FC<RowExtendedProps> = (record) => {
  return (
    <>
      <tr className={`${record.rowClassesByIdx} data-row ant-table-row row-items`}>{record.children}</tr>
      <tr className="h-10"></tr>
    </>
  )
}

export default RowExtended

