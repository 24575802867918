import { useEffect, useMemo, useState } from 'react'
import { SaboClientApi } from 'src/Api/SaboClientApi'

export interface IIndustry {
  id: string
  tenant: string
  code: string
  name: string
}

export const useIndustryGroup = () => {
  const [industryGroup, setIndustryGroup] = useState<IIndustry[]>()

  const { industryGroupNameMapping } = useMemo(() => {
    const industryGroupNameMapping: { [key in string]: string } = {}
    industryGroup?.forEach((industry) => {
      industryGroupNameMapping[industry.code] = industry.name
    })
    return { industryGroupNameMapping }
  }, [industryGroup])

  useEffect(() => {
    SaboClientApi.getIndustryGroup()
      .then((response) => {
        setIndustryGroup(response.data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }, [])

  return {
    industryGroup,

    industryGroupNameMapping,
  }
}

export default useIndustryGroup
