import { FC, useEffect, useRef, useState } from 'react'
import { DatePicker, Form, FormInstance, FormItemProps, Input, InputProps } from 'antd'

import './style.scss'
import { SelectCommon, SelectCommonProps } from 'src/Component/inputs/SelectCommon'
import { InputNumberCommon, InputNumberCommonProps } from 'src/Component/inputs/InputNumberCommon'

export interface FloatLabelProps extends FormItemProps {
  form?: FormInstance
  type?: 'text' | 'date-range' | 'select' | 'number' | 'textare'
  inputProps?: InputProps
  selectProps?: SelectCommonProps
  inputNumberProps?: InputNumberCommonProps
}

export const FloatLabel: FC<FloatLabelProps> = ({
  children,
  form,
  label,
  type = 'text',
  inputProps = {},
  selectProps = {},
  inputNumberProps = {},
  ...props
}) => {
  const { name = '' } = props
  const [focus, setFocus] = useState(false)
  const value = Form.useWatch(name, form)
  const inputRef: any = useRef(null)

  const labelClass = focus || !!String(value ?? inputProps.value ?? '') ? 'float' : ''

  const customInputProps: any = {
    size: 'large',
    onBlur: () => setFocus(false),
    onFocus: () => {
      setFocus(true)
    }
  }

  useEffect(() => {
    if (!inputProps.autoFocus) return

    setTimeout(() => {
      inputRef?.current?.focus()
    }, 200)
  }, [inputProps.autoFocus])

  return (
    <div className={`floating-label ${labelClass}`}>
      <Form.Item label={Array.isArray(label) ? [label[0]] : label} {...props}>
        {
          {
            'date-range': (
              <DatePicker.RangePicker {...customInputProps} size="large" className="w-full" placeholder={['', '']} />
            ),
            select: <SelectCommon allowClear {...customInputProps} {...selectProps}></SelectCommon>,
            text: <Input allowClear {...customInputProps} {...inputProps} ref={inputRef} />,
            number: (
              <InputNumberCommon
                ref={inputRef}
                {...customInputProps}
                {...inputNumberProps}
                className={`w-full ${inputNumberProps.className ?? ''}`}
              />
            ),
            textare: (
              <Input.TextArea autoSize className="!min-h-[80px]" {...customInputProps} {...inputProps} ref={inputRef} />
            )
          }[type]
        }
      </Form.Item>

      {Array.isArray(label) && <div className="second-label">{label[1]}</div>}
    </div>
  )
}
