import { AxiosResponse } from 'axios'
import apiClient from '../../Util/ApiClient'
import { AccountBalanceInterface } from 'src/Interface/AccountBalanceInterface'
import { IAdjustBalancePayload, ICreateDepositPayload } from './request'

export class CustomerApi {
  static getAccountBalance = (username: string): Promise<AxiosResponse<AccountBalanceInterface>> => {
    return apiClient.get(`/admin/customers/${username}/account-balance`)
  }

  static adjustBalance = (
    username: string,
    payload: IAdjustBalancePayload
  ): Promise<AxiosResponse<AccountBalanceInterface>> => {
    payload.source = 'sabo'

    return apiClient.post(`/admin/customers/${username}/justify`, payload)
  }

  static createDeposit = (
    username: string,
    payload: ICreateDepositPayload
  ): Promise<AxiosResponse<AccountBalanceInterface>> => {
    payload.source = 'sabo'

    return apiClient.post(`/admin/customers/${username}/deposit`, payload)
  }
}
